import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { Inventory } from "../types";
import { deleteRequest, get, post, put } from "../services/restClient";
import { Endpoint, setEndpoint } from "../services/endpoints";

export interface EditInventory {
    name: string;
    project: string;
    estimatedDeliveryDate: string;
    customerId: string;
    description: string;
}

export interface CreateInventory {
    companyId: string;
    name: string;
    project: string;
    estimatedDeliveryDate: string;
    customerId: string;
    description: string;
}

const postInventory = async (companyId: string, inventory: CreateInventory) => {
    const data = await post<CreateInventory, Inventory>(
        setEndpoint(Endpoint.inventories, { companyId }),
        inventory,
        { requireAuth: true },
    );
    return data;
};

export const useAddInventory = () => {
    const request = postInventory;
    const queryClient = useQueryClient();

    return useMutation({
        mutationFn: async ({ companyId, inventory }: { companyId: string; inventory: CreateInventory }) => {
            return request(companyId, inventory);
        },
        onSuccess: (data, { companyId }) => {
            void queryClient.invalidateQueries({queryKey:['inventories', companyId]});        },
    });
};


const putInventory = async (companyId: string, inventoryId: string, inventory: EditInventory) => {
    const data = await put<EditInventory, Inventory>(
        setEndpoint(Endpoint.inventory, { companyId, inventoryId }),
        inventory,
        {
            requireAuth: true,
        },
    );
    return data;
};

export const useUpdateInventory = () => {
    const request = putInventory;
    const queryClient = useQueryClient();

    return useMutation({
        mutationFn: async ({
            companyId,
            inventoryId,
            inventory,
        }: {
            companyId: string;
            inventoryId: string;
            inventory: EditInventory;
        }) => {
            return request(companyId, inventoryId, inventory);
        },
        onSuccess: (data, { companyId, inventoryId }) => {
            void queryClient.invalidateQueries({queryKey:['inventories', companyId]});
            return data;
        },
    });
};


const getInventoryById = async (companyId: string, inventoryId: string) => {
    const data = await get<Inventory>(setEndpoint(Endpoint.inventory, { companyId, inventoryId }), {
        requireAuth: true,
    });
    return data;
};

export const useGetInventory = (companyId: string, inventoryId: string) => {
    return useQuery<Inventory>({queryKey: ["inventories", inventoryId], queryFn: () =>
        getInventoryById(companyId, inventoryId)},
    );
};

const getCompanyInventories = async (companyId: string) => {
    const data = await get<Inventory[]>(setEndpoint(Endpoint.inventories, { companyId }), {
        requireAuth: true,
    });
    return data;
};

export const useGetCompanyInventories = (companyId: string) => {
    return useQuery({queryKey: ["inventories", companyId], queryFn: () => getCompanyInventories(companyId)});
};

export const useDeleteInventory = () => {
    const request = deleteInventory;
    const queryClient = useQueryClient();

    return useMutation({
        mutationFn: async ({ companyId, inventoryId }: { companyId: string; inventoryId: string }) => {
            return request(companyId, inventoryId);
        },
        onSuccess: (data, { companyId, inventoryId }) => {
            void queryClient.invalidateQueries({queryKey:['inventories', companyId]});
            void queryClient.invalidateQueries({queryKey:['inventory', inventoryId]});
            return data;
        },
    });
};


const deleteInventory = async (companyId: string, inventoryId: string) => {
    const data = await deleteRequest(setEndpoint(Endpoint.inventory, { companyId, inventoryId }), {
        requireAuth: true,
    });
    return data;
};
