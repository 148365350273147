import { Route, Routes, unstable_HistoryRouter as HistoryRouter } from "react-router-dom";
import { MainMenu } from "./pages/MainMenuPage/MainMenu";
import { NewInventory } from "./pages/NewInventoryPage/NewInventory";
import { InventoryPage } from "./pages/InventoryPage/InventoryPage";
import { NewProduct } from "./pages/NewProductPage/NewProduct";
import { Admin } from "./pages/AdminPage/AdminPage";
import { CreateUser } from "./pages/CreateUserPage/CreateUserPage";
import { ForgotPassword } from "./pages/ForgotPasswordPage/ForgotPasswordPage";
import { Account } from "./pages/AccountPage/AccountPage";
import { ResetPassword } from "./pages/ResetPasswordPage/ResetPassword";
import { PersistLogin } from "./components/PersistLogin";
import { Role } from "./types";
import { Permissions } from "./components/Permissions";
import { NotFound } from "./pages/ErrorPages/NotFound";
import { Unauthorized } from "./pages/ErrorPages/Unauthorized";
import { InventoryListPage } from "./pages/InventoryListPage/InventoryListPage";
import { EditProduct } from "./pages/EditProductPage/EditProduct";
import { ManageUsers } from "./pages/ManageUsersPage/ManageUsersPage";
import { EditUser } from "./pages/EditUserPage/EditUser";
import { LoginPage } from "./pages/LoginPage/Login";
import CssBaseline from "@mui/material/CssBaseline";
import { EditInventoryPage } from "./pages/EditInventoryPage/EditInventoryPage";
import { history } from "./utils/history";
import { CreateSubCompanyPage } from "./pages/CreateSubCompanyPage/CreateSubCompanyPage";
import { CreateCompanyPage } from "./pages/CreateCompanyPage/CreateCompanyPage";
import { ManageCompanies } from "./pages/ManageCompaniesPage/ManageCompaniesPage";
import { ManageSubCompaniesPage } from "./pages/ManageSubCompanies/ManageSubCompaniesPage";
import { EditCompanyPage } from "./pages/EditCompanyPage/EditCompanyPage";

export const App = () => {
    return (
        <CssBaseline>
            <HistoryRouter history={history}>
                <Routes>
                    {/* Unprotected routes */}
                    <Route path="/login" element={<LoginPage />} />
                    <Route path="/forgotPassword" element={<ForgotPassword />} />
                    <Route path="/resetPassword/:token" element={<ResetPassword />} />
                    <Route path="/unAuthorized" element={<Unauthorized />} />
                    <Route path="/404" element={<NotFound />} />
                    {/* Protected Routes */}
                    <Route element={<PersistLogin />}>
                        {/* Add Roles as well */}
                        {/* superAdmin + soecoAdmin */}
                        <Route element={<Permissions roles={[Role.superAdmin, Role.soecoAdmin]} />}>
                            <Route path="/manageCompanies" element={<ManageCompanies />} />
                            <Route
                                path="/manageCompanies/createCompany"
                                element={<CreateCompanyPage />}
                            />
                            <Route
                                path="/manageCompanies/:companyId"
                                element={<EditCompanyPage />}
                            />
                        </Route>
                        <Route
                            element={
                                <Permissions
                                    roles={[Role.superAdmin, Role.soecoAdmin, Role.admin]}
                                />
                            }
                        >
                            <Route path="/admin" element={<Admin />} />
                            <Route
                                path="companies/:companyId/createUser"
                                element={<CreateUser />}
                            />
                            <Route
                                path="/companies/:companyId/manageUsers/:employeeId"
                                element={<EditUser />}
                            />
                            <Route
                                path="/companies/:companyId/manageUsers"
                                element={<ManageUsers />}
                            />
                            <Route
                                path="/companies/:companyId/manageCompanies"
                                element={<ManageCompanies />}
                            />
                            <Route
                                path="/companies/:companyId/manageSubCompanies"
                                element={<ManageSubCompaniesPage />}
                            />
                            <Route
                                path="/companies/:companyId/createCompany"
                                element={<CreateCompanyPage />}
                            />
                            <Route
                                path="/companies/:companyId/createSubCompany"
                                element={<CreateSubCompanyPage />}
                            />
                        </Route>
                        {/* maintainer */}
                        <Route path="/" element={<MainMenu />} />
                        <Route path="/account" element={<Account />} />
                        <Route
                            path="/companies/:companyId/inventories/"
                            element={<InventoryListPage />}
                        />
                        <Route
                            path="/companies/:companyId/createInventory"
                            element={<NewInventory />}
                        />
                        <Route
                            path="/companies/:companyId/inventories/:inventoryId"
                            element={<InventoryPage />}
                        />
                        <Route
                            path="/companies/:companyId/inventories/:inventoryId/products/new"
                            element={<NewProduct />}
                        />
                        <Route
                            path="/companies/:companyId/inventories/:inventoryId/products/:productId"
                            element={<EditProduct />}
                        />
                        <Route
                            path="/companies/:companyId/editInventories/:inventoryId"
                            element={<EditInventoryPage />}
                        />
                        {/*<Route path="/inventory/overview/part" element={<OrderPart />} />*/}
                    </Route>
                    {/* Not found pages */}
                    <Route path="*" element={<NotFound />} />
                </Routes>
            </HistoryRouter>
        </CssBaseline>
    );
};
