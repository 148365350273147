const baseUrl = "/api";
export const imagesUrl =
  process.env.NODE_ENV === "production" ? "/images/" : "http://10.0.0.18:8000/images/";
type Params = {
  [key: string]: string;
};

export const setEndpoint = (endpoint: Endpoint, params?: Params) => {
  let url = baseUrl + endpoint;

  if (process.env.NODE_ENV === "development") {
    url = "http://localhost:8000" + url;
  }

  if (params) {
    Object.keys(params).forEach(key => {
      url = url.replace(`:${key}`, params[key]);
    });
  }

  return url;
};

export enum Endpoint {
  login = "/auth/login",
  logout = "/auth/logout",
  accessToken = "/auth/access-token",
  changePassword = "/auth/change-password",
  forgotPassword = "/auth/forgot-password",
  resetPassword = "/auth/reset-password",
  resetPasswordToken = "/auth/reset-password-token",

  companies = "/companies",
  company = "/companies/:companyId",
  subCompany = "/companies/subCompany",
  subCompanies = "/companies/:companyId/subCompanies",


  employees = "/companies/:companyId/employees",
  employee = "/companies/:companyId/employees/:employeeId",

  inventories = "/companies/:companyId/inventories",
  inventory = "/companies/:companyId/inventories/:inventoryId",

  products = "/companies/:companyId/inventories/:inventoryId/products",
  product = "/companies/:companyId/inventories/:inventoryId/products/:productId",
  predictProduct = "/companies/:companyId/inventories/:inventoryId/products/predict",

  manufacturers = "/manufacturers",
  designers = "/designers",
  categories = "/categories",
  productStatuses = "/product-statuses",

  productReport = "/companies/:companyId/inventories/:inventoryId/products/report",
}
