import { Navigate, useNavigate } from "react-router-dom";
import styled from "styled-components";
import { AdminCompanyFilter } from "../../components/AdminCompanyFilter";
import { useParams } from "react-router-dom";
import { EmployeeContext } from "../../context/EmployeeContext";
import { useContext } from "react";
import { isGlobalAdmin } from "../../utils/util";
import { InventoryListActions } from "./InventoryListActions";
import { InventoryList } from "./InventoryList";
import { useGetCompanyInventories } from "../../hooks/inventoryHooks";
import { Loader } from "../../components/Loader";
import { SoecoHeader } from "../../components/SoecoHeader";

export const InventoryListPage = () => {
    const { companyId } = useParams();
    const { employee } = useContext(EmployeeContext);
    const inventoryQuery = useGetCompanyInventories(companyId ?? "");
    const navigate = useNavigate();

    // TODO: It might be good to check if id is valid as well.
    if (!isGlobalAdmin && employee.companyId !== companyId) {
        return <Navigate to="/404" />;
    }

    const onChange = (idToNavigate: string) => {
        navigate(`/companies/${idToNavigate}/inventories`);
    };

    return (
        <SearchInventoryWrapper>
            <SoecoHeader>View Inventories</SoecoHeader>
            {companyId && isGlobalAdmin(employee) && (
                <AdminCompanyFilter currentCompanyId={companyId} onChange={onChange} />
            )}
            {inventoryQuery.isLoading || !inventoryQuery.data ? (
                <Loader />
            ) : (
                <InventoryListWrapper>
                    <InventoryList companyId={companyId ?? ""} inventories={inventoryQuery.data} />
                </InventoryListWrapper>
            )}
            {companyId && <InventoryListActions currentCompanyId={companyId} />}
        </SearchInventoryWrapper>
    );
};

const SearchInventoryWrapper = styled.div`
    display: flex;
    flex-direction: column;
    height: 80vh;
    align-items: center;
    gap: 2rem;
`;

const InventoryListWrapper = styled.div`
    display: flex;
    height: 100%;
    width: 100%;
`;
