import { DialogContentText, DialogActions } from "@mui/material";
import React, { FunctionComponent } from "react";
import { SoecoButton } from "../SoecoButton";
import styled from "styled-components";

interface ErrorModalProps {
    onConfirm: () => void;
    onCancel: () => void;
    message: string;
    disabled?: boolean;
}

export const ErrorModal: FunctionComponent<ErrorModalProps> = props => {
    return (
        <React.Fragment>
            <ErrorModalWrapper>
                <StyledDialogContentText>{props.message}</StyledDialogContentText>
                <StyledDialogActions>
                    <SoecoButton fullWidth size="large" color="secondary" onClick={props.onCancel}>
                        Cancel
                    </SoecoButton>
                    <SoecoButton
                        fullWidth
                        size="large"
                        onClick={props.onConfirm}
                        disabled={props.disabled}
                    >
                        Delete
                    </SoecoButton>
                </StyledDialogActions>
            </ErrorModalWrapper>
        </React.Fragment>
    );
};

const ErrorModalWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    gap: 1rem;
`;

const StyledDialogActions = styled(DialogActions)`
    display: flex;
    width: 100%;
`;

const StyledDialogContentText = styled(DialogContentText)`
    display: flex;
    justify-content: center;
`;
