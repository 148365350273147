import * as React from "react";
import {
    GridToolbarContainer,
    GridToolbarColumnsButton,
    GridToolbarFilterButton,
    GridToolbarExport,
    GridToolbarDensitySelector,
} from "@mui/x-data-grid";
import SaveAlt from "@mui/icons-material/SaveAlt";
import { Button } from "@mui/material";

type Props = {
    handleCreateReport: () => void;
};

export const CustomToolbar = (props: Props) => {
    return (
        <GridToolbarContainer>
            <GridToolbarColumnsButton />
            <GridToolbarFilterButton />
            <GridToolbarDensitySelector />
            <GridToolbarExport />
            <Button size="small" startIcon={<SaveAlt />} onClick={props.handleCreateReport}>
                Excel
            </Button>
        </GridToolbarContainer>
    );
};
