import { useState, useEffect, useContext } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import { EmployeeContext } from "../context/EmployeeContext";
import { useSnackBar } from "../context/SnackbarContext";
import { fetchAndSetToken, hasAccessToken } from "../services/authService";
import { Role } from "../types";
import { LoaderModal } from "./Modals/LoaderModal";
import { prefetchProductCategories } from "../hooks/productCategoryHooks";

export const PersistLogin = () => {
    const [isLoading, setIsLoading] = useState(true);
    const employee = useContext(EmployeeContext);
    const navigate = useNavigate();
    const snackBar = useSnackBar();



    useEffect(() => {
        const verifyRefreshToken = async () => {
            try {
                const token = await fetchAndSetToken();
                employee.setEmployee({
                    companyId: token.companyId,
                    id: token.employeeId,
                    role: token.role as Role,
                });
            } catch (err) {
                if (err instanceof Error) {
                    snackBar.showSnackBar(
                        "Could not fetch refresh-token, Please login again",
                        "error",
                    );
                }
                navigate("/login");
            } finally {
                setIsLoading(false);
            }
        };

        if (!hasAccessToken()) {
            verifyRefreshToken().catch(err => {
                if (err instanceof Error) {
                    snackBar.showSnackBar(
                        "Could not fetch refresh-token, Please login again",
                        "error",
                    );
                }
                navigate("/login");
            });
        } else {
            setIsLoading(false);
        }
    }, []);

    if (hasAccessToken()) {
        void prefetchProductCategories();
    }

    return <>{isLoading ? <LoaderModal message="Loading" /> : <Outlet />}</>;
};
