import { ErrorRecord, Product } from "../../types";

export const validateChanges = (
    changedProduct: Product,
    setErrors: (errors: ErrorRecord<Product>) => void,
    setError: (error: boolean) => void,
    section: string,
) => {
    let valid = true;
    const newErrors: ErrorRecord<Product> = {};
    switch (section) {
        case "images": {
            break;
        }
        case "categories": {
            break;
        }
        case "details": {
            if (!changedProduct.name) {
                newErrors.name = "Name is required";
                valid = false;
            }
            break;
        }
        case "prices": {
            if (changedProduct.netPrice && parseInt(changedProduct.netPrice) < 0) {
                newErrors.netPrice = "Sales price must be greater than -1";
                valid = false;
            }
            if (changedProduct.purchasePrice && parseInt(changedProduct.purchasePrice) < 0) {
                newErrors.purchasePrice = "Purchase price must be greater than -1";
                valid = false;
            }
            if (changedProduct.marketPrice && parseInt(changedProduct.marketPrice) < 0) {
                newErrors.marketPrice = "Market price must be greater than -1";
                valid = false;
            }
            break;
        }
        case "factories": {
            if (changedProduct.quantity && changedProduct.quantity < 1) {
                newErrors.quantity = "Quantity must be greater than 0";
                valid = false;
            }
            break;
        }
        default:
            setError(false);
            setErrors({});
    }
    if (!valid) {
        setErrors(newErrors);
        setError(true);
        return false;
    } else {
        setErrors({});
        setError(false);
        return true;
    }
};
