import { Typography, TypographyProps } from "@mui/material";
import styled from "styled-components";

export const SoecoHeader = (props: TypographyProps) => {
    return (
        <StyledTypography lineHeight={2} variant="h5" color="inherit" noWrap>
            {props.children}
        </StyledTypography>
    );
};

const StyledTypography = styled(Typography)`
    display: flex;
    justify-content: center;
    align-items: center;
`;
