import styled from "styled-components";

export const NewInventoryPageWrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 30px;
`;

export const FormWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
    width: 95%;
`;

export const Form = styled.form`
    display: flex;
    width: 100%;
    flex-direction: column;
    gap: 10px;
`;

export const ActionButtons = styled.div`
    display: flex;
    width: 95%;
    gap: 2rem;
    position: fixed;
    bottom: 2rem;
`;
