
import { useCallback, useEffect, useState } from "react";
import styled from "styled-components";
import { ErrorRecord, Product, ProductCategory } from "../../types";
import { SoecoImageList } from "./SoecoImageList";
import CameraAltIcon from "@mui/icons-material/CameraAlt";
import Cropper, { Area, Point } from "react-easy-crop";
import React from "react";
import { getCroppedImg, readFile } from "../../utils/util";
import { SoecoButton } from "../../components/SoecoButton";
import Div100vh from "react-div-100vh";
import { useGetPredictions } from "../../hooks/productHooks";
import Card from '@mui/material/Card';
import { Loader } from "../../components/Loader";
import { useSnackBar } from "../../context/SnackbarContext";
import { imagesUrl } from "../../services/endpoints";

type Props = {
  images: File[]
  companyId: string,
  inventoryId: string,
  product: Product;
  setProduct: (product: Product) => void;
  setOptions: (product: ProductOptions) => ProductOptions;
  getManufacturerLabel: (idOrLabel: string) => string;
  getDesignerLabel: (idOrLabel: string) => string;
};

export interface ProductOptions {
  manufacturer: string | undefined;
  categories: string[] | undefined;
  designer: string | undefined;
}

export const PredictProduct = (props: Props) => {

  const { data: predictions, isLoading, isError } = useGetPredictions(props.companyId, props.inventoryId, props.product, props.images)
  const snackBar = useSnackBar();

  if (predictions && props.product.predictions?.length === 0 ) {
    const predictionStrings: string[] = []

    for (const pred of predictions) {
      predictionStrings.push(pred.id || '')
    }

    props.setProduct(
      {
        ...props.product,
        predictions: predictionStrings
      }
    )
  }

  const selectPrediction = (prediction: Product) => {

    const options: ProductOptions = {
      manufacturer: prediction.manufacturer,
      designer: prediction.designer,
      categories: prediction.categories,
    }

    const newOptions: ProductOptions = props.setOptions(options)

    const predicts: string[] = []

    if (predictions) {
      for (const prediction of predictions) {
        predicts.push(prediction.id ?? '')
      }
    }

    const newProduct: Product = {
      ...prediction,
      id: props.product.id,
      inventoryId: props.inventoryId,
      companyId: props.companyId,
      imageUrls: props.product.imageUrls,
      quantity: 1,
      manufacturer: newOptions.manufacturer,
      designer: newOptions.designer,
      categories: newOptions.categories,
      predictions: predicts,
      predictionUsed: prediction.id
    }

    props.setProduct(newProduct)
    snackBar.showSnackBar(`Product data loaded ${prediction.name ? `from ${prediction.name}` : ''}!`, "success");
  }

  if (isLoading) {
    return (
      <PredictionWrapper>
        <h1>Fetching product predictions...</h1>
        <Loader />
      </PredictionWrapper>
    )
  }

  if (isError) {
    return (
      <PredictionWrapper>
        <h1>An error occured when getting predictions</h1>
        <h2>Is your device offline?</h2>
      </PredictionWrapper>
    )
  }

  return (
    <>
      <PredictionWrapper>
        <PredictionsContainer>

          {predictions?.map(prediction => {
            return <CardWrapper key={prediction.id} onClick={() => selectPrediction(prediction)}><Card>
              {
                prediction.imageUrls &&
                (prediction.imageUrls[0].includes("img.soeco.se") ? (
                  <img src={prediction.imageUrls[0]} alt="" width="180px" height="auto" />
                ) : (
                  <img src={`${imagesUrl}${prediction.imageUrls[0]}`} alt="" width="180px" height="auto" />
                ))
              }

              <h2>
                {prediction.name}
              </h2>
              {prediction.manufacturer &&
                <p>{props.getManufacturerLabel(prediction.manufacturer)}</p>
              }
              {prediction.designer &&
                <p>{props.getDesignerLabel(prediction.designer)}</p>
              }
            </Card>
            </CardWrapper>
          })}
        </PredictionsContainer>
      </PredictionWrapper>
    </>
  );
};

const CardWrapper = styled.button`
  background-color: transparent;
  border-color: transparent;
`

const PredictionWrapper = styled.div`
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    text-align: center;
`;

const PredictionsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  justify-content: center;
  padding-top: 30px;
`