import { Company, Employee } from "../../types";
import styled from "styled-components";
import { Typography } from "@mui/material";

type Props = {
    employee?: Employee;
    company?: Company;
};

export const EmployeeInfo = (props: Props) => {
    return (
        <EmployeeInfoWrapper>
            <RowWrapper>
                <Typography variant="body1" component="h6">
                    Company:
                </Typography>
                <Typography>{props.company?.name}</Typography>
            </RowWrapper>
            <RowWrapper>
                <Typography variant="body1" component="h6">
                    Email:
                </Typography>
                <Typography>{props.employee?.email}</Typography>
            </RowWrapper>
            <RowWrapper>
                <Typography variant="body1" component="h6">
                    Name:
                </Typography>
                <Typography>{props.employee?.name}</Typography>
            </RowWrapper>
            <RowWrapper>
                <Typography variant="body1" component="h6">
                    Role:
                </Typography>
                <Typography>{props.employee?.role}</Typography>
            </RowWrapper>
        </EmployeeInfoWrapper>
    );
};

const EmployeeInfoWrapper = styled.div`
    display: flex;
    flex-direction: column;
    width: 80%;
    gap: 1rem;
`;

const RowWrapper = styled.div`
    display: flex;
    flex-direction: row;
    h6:first-child {
        max-width: 150px;
        width: 100%;
        color: #757575;
    }
    h6:nth-child(2) {
        display: flex;
        flex-shrink: 1;
        word-break: break-all;
    }
`;
