import React, { useState, createContext } from "react";
import { Inventory } from "../types";

type Props = {
    children?: React.ReactNode;
};

export type InventoryContextState = {
    inventory: Inventory;
    setInventory: (inventory: Inventory) => void;
};

export const InventoryContext = createContext<InventoryContextState>({} as InventoryContextState);

export const InventoryProvider: React.FC<Props> = ({ children }): JSX.Element => {
    const [inventory, setInventory] = useState<Inventory>({} as Inventory);

    return (
        <InventoryContext.Provider value={{ inventory, setInventory }}>
            {children}
        </InventoryContext.Provider>
    );
};
