import { AccessToken, decodedAccessToken } from "../types";
import { get, post } from "./restClient";
import jwtDecode from "jwt-decode";
import { Endpoint, setEndpoint } from "./endpoints";
import { history } from "../utils/history";

let accessToken: AccessToken | undefined;

export const getAccessToken = (): AccessToken | undefined => {
    return accessToken;
};

export const hasAccessToken = (): boolean => {
    return accessToken !== undefined;
};

export const isTokenExpired = (): boolean => {
    if (accessToken === undefined) {
        return true;
    }

    return accessToken.maxAge < Math.trunc(Date.now() / 1000);
};

export const setAccessToken = (token: string): AccessToken => {
    const decodedToken: decodedAccessToken = jwtDecode<decodedAccessToken>(token);
    accessToken = {
        accessToken: token,
        maxAge: decodedToken.exp,
        employeeId: decodedToken.id,
        companyId: decodedToken.companyId,
        role: decodedToken.role,
    };
    return accessToken;
};

export const clearAccessToken = () => {
    accessToken = undefined;
};

export const fetchAndSetToken = async (): Promise<AccessToken> => {
    const response = await get<AccessToken>(setEndpoint(Endpoint.accessToken));
    return setAccessToken(response.accessToken);
};

export const login = async (email: string, password: string): Promise<void> => {
    type RequestBody = {
        email: string;
        password: string;
    };

    type ResponseBody = RequestBody & {
        accessToken: string;
    };
    await post<RequestBody, ResponseBody>(setEndpoint(Endpoint.login), {
        email,
        password,
    }).then(res => {
        // Save accessToken
        setAccessToken(res.accessToken);
    });
};

export const logout = (): void => {
    post(setEndpoint(Endpoint.logout), undefined).finally(() => {
        clearAccessToken();
        history.push("/login");
    });
};
