import { useQuery, useMutation, useQueryClient, queryOptions } from "@tanstack/react-query";
import { Product } from "../types";
import { deleteRequest, get, post, put } from "../services/restClient";
import { Endpoint, setEndpoint } from "../services/endpoints";

const productKeys = {
  all: ["products"] as const,
  lists: () => [...productKeys.all, "list"] as const,
  list: (filters: string) => [...productKeys.lists(), { filters }] as const,
  details: () => [...productKeys.all, "detail"] as const,
  detail: (id: string) => [...productKeys.details(), id] as const,
};

export const useAddProduct = () => {
  const request = postProduct;
  const queryClient = useQueryClient();
  
  return useMutation({
    mutationFn: async ({
      companyId,
      inventoryId,
      product,
      images,
    }: {
      companyId: string;
      inventoryId: string;
      product: Product;
      images: File[];
    }) => {
      const formData = createFormData(product, images);
      return request(companyId, inventoryId, formData);
    },
    onSuccess: (data, { inventoryId }) => {
      void queryClient.invalidateQueries({ queryKey: ['products', inventoryId] });
      return data;
    },
  });
};


const postProduct = async (companyId: string, inventoryId: string, formData: FormData) => {
  const data = await post<FormData, Product>(
    setEndpoint(Endpoint.products, { companyId, inventoryId }),
    formData,
    { requireAuth: true, formData: true },
  );
  return data;
};

const createFormData = (product: Product, images: File[]): FormData => {
  const formData = new FormData();
  formData.append("product", JSON.stringify(product));

  for (const image of images) {
    formData.append("images", image);
  }
  return formData;
};

export const useGetProduct = (companyId: string, inventoryId: string, productId: string) => {
  return useQuery<Product>(
    {
      queryKey: ["product", productId],
      queryFn: () => getProduct(companyId, inventoryId, productId),
      enabled: !!companyId && !!inventoryId && !!productId
    },
  );
};

const getProduct = async (companyId: string, inventoryId: string, productId: string) => {
  const data = await get<Product>(
    setEndpoint(Endpoint.product, { companyId, inventoryId, productId }),
    { requireAuth: true },
  );
  return data;
};

export const useGetProducts = (companyId: string, inventoryId: string) => {
  return useQuery<Product[]>({
    queryKey: ["products", inventoryId], queryFn: () =>
      getProducts(companyId, inventoryId)
  },
  );
};

const getProducts = async (companyId: string, inventoryId: string) => {
  const data = await get<Product[]>(setEndpoint(Endpoint.products, { companyId, inventoryId }), {
    requireAuth: true,
  });
  return data;
};

export const useUpdateProduct = () => {
  const request = updateProduct;
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async ({
      companyId,
      inventoryId,
      productId,
      product,
    }: {
      companyId: string;
      inventoryId: string;
      productId: string;
      product: Product;
    }) => {
      return request(companyId, inventoryId, productId, product);
    },
    onSuccess: (data, { inventoryId, productId }) => {
      void queryClient.invalidateQueries({ queryKey: ['products', inventoryId] });
      void queryClient.invalidateQueries({ queryKey: ['product', productId] });
    },
  });
};

const updateProduct = async (
  companyId: string,
  inventoryId: string,
  productId: string,
  product: Product,
) => {
  const data = await put<Product, Product>(
    setEndpoint(Endpoint.product, { companyId, inventoryId, productId }),
    product,
    { requireAuth: true },
  );
  return data;
};

export const useDeleteProduct = () => {
  const request = deleteProduct;
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async ({
      companyId,
      inventoryId,
      productId,
    }: {
      companyId: string;
      inventoryId: string;
      productId: string;
    }) => {
      return request(companyId, inventoryId, productId);
    },
    onSuccess: (data, { inventoryId }) => {
      void queryClient.invalidateQueries({ queryKey: ['products', inventoryId] });
      return data;
    },
  });
};


const deleteProduct = async (companyId: string, inventoryId: string, productId: string) => {
  const data = await deleteRequest(
    setEndpoint(Endpoint.product, { companyId, inventoryId, productId }),
    { requireAuth: true },
  );
  return data;
};

export const createProductReport = async (companyId: string, inventoryId: string) => {
  const data = await get<ArrayBuffer>(
    setEndpoint(Endpoint.productReport, { companyId, inventoryId }),
    {
      requireAuth: true,
      excelData: true,
    },
  );
  return data;
};

export const useGetPredictions = (companyId: string, inventoryId: string, product: Product, images: File[]) => {
  const formData = createFormData(product, images);
  return useQuery<Product[]>(
    {
      queryKey: ["predictions", product.id],
      queryFn: () => getPredictions(companyId, inventoryId, formData),
      enabled: !!companyId && !!inventoryId && !!formData
    },
  );
};

export const getPredictions = async (companyId: string, inventoryId: string, formData: FormData) => {
  const data = await post<FormData, Product[]>(
    setEndpoint(Endpoint.predictProduct, { companyId, inventoryId }),
    formData,
    { requireAuth: true, formData: true },
  );
  return data;
};
