import * as React from "react";
import Chip from "@mui/material/Chip";
import Paper from "@mui/material/Paper";
import { Product, ProductCategory } from "../../types";
import styled from "styled-components";
import { useGetProductCategories } from "../../hooks/productCategoryHooks";

type Props = {
    changedProduct: Product;
    setChangedProduct: (product: Product) => void;
};

export const EditProductCategory = (props: Props) => {
    const { data: productCategories } = useGetProductCategories();

    const handleProductCategoryChange = (data: ProductCategory) => {
        // Remove if already in list
        if (props.changedProduct.categories?.includes(data.id)) {
            props.setChangedProduct({
                ...props.changedProduct,
                categories: props.changedProduct.categories.filter(
                    category => category !== data.id,
                ),
            });
            // Add if not in list
        } else {
            props.setChangedProduct({
                ...props.changedProduct,
                categories: [...(props.changedProduct.categories ?? []), data.id],
            });
        }
    };

    return (
        <Paper
            sx={{
                display: "flex",
                justifyContent: "center",
                flexWrap: "wrap",
                listStyle: "none",
                p: 0.5,
                m: 0,
            }}
            component="ul"
        >
            {productCategories?.map(data => {
                return (
                    <ListItem key={data.id}>
                        <Chip
                            clickable
                            onClick={() => {
                                handleProductCategoryChange(data);
                            }}
                            color={
                                props.changedProduct.categories?.includes(data.id)
                                    ? "primary"
                                    : "default"
                            }
                            label={data.name}
                        />
                    </ListItem>
                );
            })}
        </Paper>
    );
};

const ListItem = styled.li`
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    justify-content: space-between;
    margin: 0.5rem;
`;
