import { FormControl, MenuItem, Select, SelectChangeEvent, TextField } from "@mui/material";
import { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { SoecoButton } from "../../components/SoecoButton";
import { EmployeeContext } from "../../context/EmployeeContext";
import { isGlobalAdmin, isValidEmail } from "../../utils/util";
import { useGetCompany } from "../../hooks/companyHooks";
import {
    EditEmployee,
    useDeleteEmployee,
    useUpdateEmployee,
    useGetEmployee,
} from "../../hooks/employeeHooks";
import { ErrorRecord } from "../../types";
import DeleteIcon from "@mui/icons-material/Delete";
import styled from "styled-components";
import { SoecoHeader } from "../../components/SoecoHeader";
import { useModal } from "../../hooks/useModal";
import { Modal } from "../../components/Modals/Modal";
import { ErrorModal } from "../../components/Modals/ErrorModal";
import { useSnackBar } from "../../context/SnackbarContext";

type EditEmployeeForm = {
    name: string;
    email: string;
    role: string;
};

export const EditUser = () => {
    const { companyId, employeeId } = useParams();
    const { data: company } = useGetCompany(companyId ?? "");
    const { data: employeeData } = useGetEmployee(companyId ?? "", employeeId ?? "");
    const { employee } = useContext(EmployeeContext);
    const updateMutation = useUpdateEmployee();
    const deleteMutation = useDeleteEmployee();
    const navigate = useNavigate();
    const [form, setForm] = useState<EditEmployeeForm>({
        name: "",
        email: "",
        role: "",
    });

    const [error, setError] = useState<boolean>(false);
    const [errors, setErrors] = useState<ErrorRecord<EditEmployeeForm>>({});
    const [disableButton, setDisableButton] = useState<boolean>(false);

    useEffect(() => {
        if (employeeData) {
            setForm({
                name: employeeData.name ?? "",
                email: employeeData.email ?? "",
                role: employeeData.role ?? "",
            });
        }
    }, [employeeData]);

    const handleChangeSelect = (e: SelectChangeEvent<string>) => {
        setForm({ ...form, role: e.target.value });
        setErrors({});
        setError(false);
    };

    const transformFormData = (form: EditEmployeeForm): EditEmployee => ({
        name: form.name,
        email: form.email.toLowerCase(),
        role: form.role,
    });

    const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        setForm({ ...form, [e.target.name]: e.target.value });
        setDisableButton(false);
        setError(false);
    };

    const isValidForm = () => {
        let valid = true;
        const newErrors: ErrorRecord<EditEmployeeForm> = {};
        if (!isValidEmail(form.email)) {
            valid = false;
            newErrors.email = "Please enter a valid email address";
        }
        if (form.name.length < 1) {
            valid = false;
            newErrors.name = "name cannot be empty";
        }
        if (!valid) {
            setErrors(newErrors);
            setError(true);
            setDisableButton(true);
            return false;
        } else {
            setErrors({});
            setError(false);
            setDisableButton(false);
            return true;
        }
    };

    const snackBar = useSnackBar();

    const handleSubmit = () => {
        if (isValidForm()) {
            if (!employeeId) {
                return;
            }
            updateMutation.mutate(
                {
                    companyId: employee.companyId,
                    employeeId: employeeId,
                    employee: transformFormData(form),
                },
                {
                    onError: error => {
                        if (error instanceof Error) {
                            snackBar.showSnackBar(error.message, "error");
                            setDisableButton(true);
                        }
                    },
                    onSuccess: () => {
                        snackBar.showSnackBar("Employee updated successfully", "success");
                        navigate(-1);
                    },
                },
            );
        }
    };

    const handleDeleteEmployee = () => {
        if (!companyId || !employeeId) {
            return;
        }
        deleteMutation.mutate(
            { companyId, employeeId },
            {
                onError: error => {
                    if (error instanceof Error) {
                        snackBar.showSnackBar(error.message, "error");
                        setDisableButton(true);
                    }
                },
                onSuccess: () => {
                    snackBar.showSnackBar("Employee deleted", "success");
                    navigate(-1);
                },
            },
        );
    };

    const { isShown, toggle } = useModal();

    const onConfirm = () => {
        toggle();
        handleDeleteEmployee();
    };
    const onCancel = () => toggle();

    return (
        <EditUserPageWrapper>
            <EditUserWrapper>
                <Modal
                    isShown={isShown}
                    hide={toggle}
                    headerText="Delete the user?"
                    modalContent={
                        <ErrorModal
                            onConfirm={onConfirm}
                            onCancel={onCancel}
                            message="You will not be able to recover it"
                        />
                    }
                />
                <SoecoHeader>Edit User</SoecoHeader>
                <Form>
                    {company && (
                        <TextField
                            label="Company"
                            disabled={true}
                            variant="outlined"
                            defaultValue={company?.name}
                        />
                    )}

                    {employeeData && isGlobalAdmin(employee) ? (
                        <Select label="Role" value={form.role} onChange={handleChangeSelect}>
                            <MenuItem value={"maintainer"}>Maintainer</MenuItem>
                            <MenuItem value={"admin"}>Admin</MenuItem>
                            <MenuItem value={"soecoAdmin"}>Soeco Admin</MenuItem>
                        </Select>
                    ) : (
                        employeeData && (
                            <Select label="Role" value={form.role} onChange={handleChangeSelect}>
                                <MenuItem value={"maintainer"}>Maintainer</MenuItem>
                                <MenuItem value={"admin"}>Admin</MenuItem>
                            </Select>
                        )
                    )}
                    <TextField
                        name="email"
                        autoComplete="off"
                        error={error && errors.email ? true : false}
                        value={form.email}
                        label="E-mail"
                        variant="outlined"
                        helperText={error && errors.email}
                        onChange={handleChange}
                    />
                    <TextField
                        name="name"
                        autoComplete="off"
                        error={error && errors.name ? true : false}
                        helperText={error && errors.name}
                        label="Name"
                        variant="outlined"
                        value={form.name}
                        onChange={handleChange}
                    />
                </Form>
            </EditUserWrapper>
            <ActionButtons>
                <SoecoButton color="secondary" onClick={toggle}>
                    <DeleteIcon fontSize="large" />
                </SoecoButton>
                <SoecoButton fullWidth size="large" color="secondary" onClick={() => navigate(-1)}>
                    Cancel
                </SoecoButton>
                <SoecoButton
                    fullWidth
                    size="large"
                    disabled={updateMutation.isPending || disableButton}
                    color="primary"
                    onClick={handleSubmit}
                >
                    Update
                </SoecoButton>
            </ActionButtons>
        </EditUserPageWrapper>
    );
};

const EditUserPageWrapper = styled.div`
    display: flex;
    flex-direction: column;
    height: 80vh;
    align-items: center;
    gap: 2rem;
`;

const EditUserWrapper = styled.div`
    display: flex;
    flex-direction: column;
    width: 95%;
    height: 70%;
    align-content: center;
    gap: 2rem;
    overflow: scroll;
`;

const Form = styled(FormControl)`
    display: flex;
    flex-direction: column;
    align-content: center;
    width: 95%;
    margin: 0 auto;
    gap: 20px;
`;

const ActionButtons = styled.div`
    display: flex;
    width: 90%;
    gap: 2rem;
    position: fixed;
    bottom: 2rem;
`;
