import { Link } from "react-router-dom";
import LogoutRoundedIcon from "@mui/icons-material/LogoutRounded";
import { logout } from "../services/authService";
import styled from "styled-components";
import { useTheme } from "@mui/material/styles";

const LogoutIconWrapper = styled.div`
    font-size: 40px !important;
`;

export const Logout = () => {
    const theme = useTheme();

    const handleLogout = () => {
        logout();
    };

    return (
        <Link to="/login" onClick={handleLogout}>
            <LogoutIconWrapper>
                <LogoutRoundedIcon htmlColor={theme.palette.common.black} fontSize="inherit" />
            </LogoutIconWrapper>
        </Link>
    );
};
