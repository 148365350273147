import { useContext } from "react";
import { useLocation, Navigate, Outlet } from "react-router-dom";
import { EmployeeContext } from "../context/EmployeeContext";
import { useGetEmployee } from "../hooks/employeeHooks";
import { hasAccessToken } from "../services/authService";
import { Role } from "../types";
import { Loader } from "./Loader";

type Props = {
    roles: Role[];
};

export const Permissions = (props: Props) => {
    const location = useLocation();

    const { employee } = useContext(EmployeeContext);
    const employeeQuery = useGetEmployee(employee.companyId, employee.id);

    return (
        <>
            {employeeQuery.isLoading || employeeQuery.data == undefined ? (
                <>
                    <Loader />
                </>
            ) : props.roles.indexOf(employeeQuery.data.role) > -1 ? (
                <Outlet />
            ) : hasAccessToken() ? (
                <Navigate to="/unauthorized" state={{ from: location }} replace />
            ) : (
                <Navigate to="/login" state={{ from: location }} replace />
            )}
        </>
    );
};
