import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { SoecoButton } from "../../components/SoecoButton";

export const ManageCompaniesActions = () => {
    const navigate = useNavigate();
    const handleCreateCompany = () => {
        navigate(`/manageCompanies/createCompany`);
    };

    return (
        <ActionButtons>
            <SoecoButton
                fullWidth
                size="large"
                color="secondary"
                onClick={() => navigate("/admin")}
            >
                Back
            </SoecoButton>
            <SoecoButton
                fullWidth
                size="large"
                color="primary"
                onClick={() => handleCreateCompany()}
            >
                Create Company
            </SoecoButton>
        </ActionButtons>
    );
};

const ActionButtons = styled.div`
    display: flex;
    width: 95%;
    gap: 2rem;
    position: fixed;
    bottom: 2rem;
`;
