import ManageAccountsIcon from "@mui/icons-material/ManageAccounts";
import StoreIcon from "@mui/icons-material/Store";
import AddBusinessIcon from "@mui/icons-material/AddBusiness";
import { Link } from "react-router-dom";
import { SoecoButton } from "../../components/SoecoButton";
import styled from "styled-components";
import { EmployeeContext } from "../../context/EmployeeContext";
import { CompanyContext } from "../../context/CompanyContext";
import { useContext } from "react";

export const AdminMenu = () => {
    const { employee } = useContext(EmployeeContext);
    const { company } = useContext(CompanyContext);

    return (
        <MenuWrapper>
            <MenuButton to={`/companies/${employee.companyId}/manageUsers`}>
                <ManageAccountsIcon />
                <span>Manage users</span>
            </MenuButton>
            <MenuButton to={`/companies/${employee.companyId}/manageCompanies`}>
                <StoreIcon />
                <span>Manage Companies</span>
            </MenuButton>
            <MenuButton to={`/companies/${employee.companyId}/manageSubCompanies`}>
                <AddBusinessIcon />
                <span>Manage Sub-Companies</span>
            </MenuButton>

            <ButtonWrapper>
                <SoecoButton color="secondary" link="/">
                    Back
                </SoecoButton>
            </ButtonWrapper>
        </MenuWrapper>
    );
};

const MenuWrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    height: 100%;
    padding-top: 30px;
`;

const MenuButton = styled(Link)`
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    svg {
        color: rgb(63, 174, 42);
        font-size: 150px;
    }
    text-decoration: none;
    color: inherit;
`;

const ButtonWrapper = styled.div`
    margin-top: 20px;
`;
