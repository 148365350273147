import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { SoecoButton } from "../../components/SoecoButton";

type Props = {
    currentCompanyId: string;
};

export const ManageSubCompaniesActions = (props: Props) => {
    const navigate = useNavigate();
    const handleCreateCompany = (idToNavigate: string) => {
        navigate(`/companies/${idToNavigate}/createSubCompany`);
    };

    return (
        <ActionButtons>
            <SoecoButton
                fullWidth
                size="large"
                color="secondary"
                onClick={() => navigate("/admin")}
            >
                Back
            </SoecoButton>
            <SoecoButton
                fullWidth
                size="large"
                color="primary"
                onClick={() => handleCreateCompany(props.currentCompanyId)}
            >
                Create Company
            </SoecoButton>
        </ActionButtons>
    );
};

const ActionButtons = styled.div`
    display: flex;
    width: 95%;
    gap: 2rem;
    position: fixed;
    bottom: 2rem;
    
`;
