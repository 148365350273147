import {
    FormControl,
    FormHelperText,
    IconButton,
    InputAdornment,
    MenuItem,
    Select,
    SelectChangeEvent,
    TextField,
} from "@mui/material";
import { useContext, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { SoecoButton } from "../../components/SoecoButton";
import { EmployeeContext } from "../../context/EmployeeContext";
import { isGlobalAdmin, isValidEmail, isValidPassword } from "../../utils/util";
import { CreateSubCompany, useCreateSubCompany, useGetCompany } from "../../hooks/companyHooks";
import { ErrorRecord } from "../../types";
import styled from "styled-components";
import { SoecoHeader } from "../../components/SoecoHeader";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { useSnackBar } from "../../context/SnackbarContext";
import Div100vh from "react-div-100vh";
import { Navigate } from "react-router-dom";

export const CreateSubCompanyPage = () => {
    const { companyId } = useParams();
    const { data: company } = useGetCompany(companyId ?? "");
    const mutation = useCreateSubCompany();

    if (!isGlobalAdmin || !companyId) {
        return <Navigate to="/404" />;
    }

    const navigate = useNavigate();
    const snackBar = useSnackBar();

    const [form, setForm] = useState<CreateSubCompany>({
        name: "",
        superCompany: companyId
    });

    const [error, setError] = useState<boolean>(false);
    const [errors, setErrors] = useState<ErrorRecord<CreateSubCompany>>({});
    const [disableButton, setDisableButton] = useState<boolean>(false);

    const transformFormData = (form: CreateSubCompany): CreateSubCompany => ({
        name: form.name,
        superCompany: companyId
    });

    const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        setForm({ ...form, [e.target.name]: e.target.value });
        setDisableButton(false);
        setError(false);
    };

    const isValidForm = () => {
        let valid = true;
        const newErrors: ErrorRecord<CreateSubCompany> = {};
        if (form.name.length < 1) {
            valid = false;
            newErrors.name = "Name cannot be empty";
        }
        if (!valid) {
            setErrors(newErrors);
            setError(true);
            return false;
        } else {
            setErrors({});
            setError(false);
            return true;
        }
    };

    const handleSubmit = () => {
        if (isValidForm()) {
            mutation.mutate(
                { subCompany: form },
                {
                    onError: error => {
                        if (error instanceof Error) {
                            snackBar.showSnackBar(error.message, "error");
                            setDisableButton(true);
                        }
                    },
                    onSuccess: () => {
                        snackBar.showSnackBar("Company created successfully", "success");
                        navigate(-1);                        
                    },
                },
            );
        }
    };

    return (
        <CreateCompanyPageWrapper>
            <SoecoHeader>Create Sub-Company</SoecoHeader>
            <CreateCompanyWrapper>
                <Form>
                    {company && (
                        <TextField
                            label="Super-Company"
                            disabled={true}
                            variant="outlined"
                            defaultValue={company?.name}
                        />
                    )}
                    <TextField
                        name="name"
                        label="Name"
                        autoComplete="off"
                        error={error && errors.name ? true : false}
                        helperText={error && errors.name}
                        variant="outlined"
                        value={form.name}
                        onChange={handleChange}
                    />
                </Form>
            </CreateCompanyWrapper>
            <ActionButtons>
                <SoecoButton fullWidth size="large" color="secondary" onClick={() => navigate(-1)}>
                    Back
                </SoecoButton>
                <SoecoButton
                    fullWidth
                    size="large"
                    disabled={mutation.isPending || disableButton}
                    color="primary"
                    className="btnLoginCreate"
                    onClick={handleSubmit}
                >
                    Create
                </SoecoButton>
            </ActionButtons>
        </CreateCompanyPageWrapper>
    );
};

const CreateCompanyPageWrapper = styled(Div100vh)`
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 2rem;
`;

const CreateCompanyWrapper = styled.div`
    display: flex;
    flex-direction: column;
    width: 95%;
    height: 70%;
    align-content: center;
    gap: 2rem;
    overflow: scroll;
    padding-top: 10px;
`;

const Form = styled(FormControl)`
    display: flex;
    flex-direction: column;
    align-content: center;
    width: 95%;
    gap: 1rem;
`;

const ActionButtons = styled.div`
    display: flex;
    width: 90%;
    gap: 2rem;
    position: fixed;
    bottom: 2rem;
`;
