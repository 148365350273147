import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { Employee, UpdatePasswordForm } from "../types";
import { deleteRequest, get, post, put } from "../services/restClient";
import { Endpoint, setEndpoint } from "../services/endpoints";

export interface CreateEmployee {
    name: string;
    email: string;
    password: string;
    role: string;
}

export interface EditEmployee {
    name: string;
    email: string;
    role: string;
}

const getEmployees = async (companyId: string) => {
    const data = await get<Employee[]>(setEndpoint(Endpoint.employees, { companyId }), {
        requireAuth: true,
    });
    return data;
};

export const useGetEmployees = (companyId: string) => {
    return useQuery({queryKey: ["employees", companyId], queryFn: () => getEmployees(companyId)});
};

const postEmployee = async (companyId: string, employee: CreateEmployee) => {
    const data = await post<CreateEmployee, Employee>(
        setEndpoint(Endpoint.employees, { companyId }),
        employee,
        { requireAuth: true },
    );
    return data;
};

export const useAddEmployee = () => {
    const request = postEmployee;
    const queryClient = useQueryClient();

    return useMutation({
        mutationFn: async ({ companyId, employee }: { companyId: string; employee: CreateEmployee }) => {
            return request(companyId, employee);
        },
        onSuccess: (data, { companyId }) => {
            // Using void to indicate that the promise returned by invalidateQueries is intentionally not awaited
            void queryClient.invalidateQueries({queryKey: ['employees', companyId]});
        },
    });
};


export const useUpdateEmployee = () => {
    const request = putEmployee;
    const queryClient = useQueryClient();

    return useMutation({
        mutationFn: async ({
            companyId,
            employeeId,
            employee,
        }: {
            companyId: string;
            employeeId: string;
            employee: EditEmployee;
        }) => {
            return request(companyId, employeeId, employee);
        },
        onSuccess: (data, { companyId, employeeId }) => {
            // Using void to indicate that the promises returned by invalidateQueries are intentionally not awaited
            void queryClient.invalidateQueries({queryKey: ['employees', companyId]});
            void queryClient.invalidateQueries({queryKey: ['employee', employeeId]});
            return data;
        },
    });
};


const putEmployee = async (companyId: string, employeeId: string, employee: EditEmployee) => {
    const data = await put<EditEmployee, Employee>(
        setEndpoint(Endpoint.employee, { companyId, employeeId }),
        employee,
        { requireAuth: true },
    );
    return data;
};

export const useUpdatePassword = () => {
    const request = putPassword;

    return useMutation({
        mutationFn: async ({ currentPassword, newPassword }: { currentPassword: string; newPassword: string }) => {
            return request(currentPassword, newPassword);
        },
    });
};

const putPassword = async (currentPassword: string, newPassword: string) => {
    const data = await put<UpdatePasswordForm, undefined>(
        setEndpoint(Endpoint.changePassword),
        {
            currentPassword: currentPassword,
            newPassword: newPassword,
        },
        { requireAuth: true },
    );
    return data;
};

const deleteEmployee = async (companyId: string, employeeId: string) => {
    const data = await deleteRequest(setEndpoint(Endpoint.employee, { companyId, employeeId }), {
        requireAuth: true,
    });
    return data;
};

export const useDeleteEmployee = () => {
    const request = deleteEmployee;
    const queryClient = useQueryClient();

    return useMutation({
        mutationFn: async ({ companyId, employeeId }: { companyId: string; employeeId: string }) => {
            return request(companyId, employeeId);
        },
        onSuccess: (data, { companyId, employeeId }) => {
            void queryClient.invalidateQueries({queryKey:['employees', companyId]});
            void queryClient.invalidateQueries({queryKey:['employee', employeeId]});
            return data; 
        },
    });
};


const getEmployeeById = async (companyId: string, employeeId: string) => {
    const data = await get<Employee>(setEndpoint(Endpoint.employee, { companyId, employeeId }), {
        requireAuth: true,
    });
    return data;
};

export const useGetEmployee = (companyId: string, employeeId: string) => {
    return useQuery({queryKey: ["employee", employeeId], queryFn: () => getEmployeeById(companyId, employeeId)});
};
