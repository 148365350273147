import React, { FunctionComponent, useEffect, useState } from "react";
import { useGetDevices } from "../hooks/printerHooks";
import { Device } from "zebra-browser-print-wrapper/lib/types";
import { Radio, FormControlLabel } from "@mui/material";
import { SoecoButton } from "./SoecoButton";
import styled from "styled-components";

interface DeviceListProps {
    selectedDevice: Device | null;
    setSelectedDevice: (device: Device) => void;
}

export const DeviceList: FunctionComponent<DeviceListProps> = props => {
    const {
        data: devices,
        isLoading: devicesLoading,
        error: devicesError,
        isRefetching,
        refetch,
    } = useGetDevices();

    const handleRefresh = () => {
        void refetch();
    };

    const handleSelectDevice = (device: Device) => {
        props.setSelectedDevice(device);
    };

    useEffect(() => {
        if (devices && devices.length > 0 ) {
            props.setSelectedDevice(devices[0]);
        }
    }, [devices]);


    return (
        <DevicesWrapper>
            <DevicesContainer>
                <h3>Select device:</h3>
                {devicesLoading || isRefetching ? (
                    <div>Loading...</div>
                ) : devicesError ? (
                    <>
                        <div>{devicesError.name}</div>
                        <div>{devicesError.message}</div>
                    </>
                ) : (
                    <>
                        {devices !== undefined && devices.map(device => (
                            <FormControlLabel
                                key={device.name}
                                control={<Radio />}
                                label={device.name}
                                value={device.name}
                                checked={props.selectedDevice === device}
                                onChange={() => handleSelectDevice(device)}
                            />
                        ))}
                        <RefreshButtonContainer>
                            <SoecoButton onClick={() => handleRefresh()}>Refresh</SoecoButton>
                        </RefreshButtonContainer>
                    </>
                )}
            </DevicesContainer>
        </DevicesWrapper>
    );
    
};

const DevicesContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: start;
    gap: 5px;
    padding-bottom: 20px;
`;

const DevicesWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
`;

const RefreshButtonContainer = styled.div`
    padding-top: 20px;
`;