import { Button, ButtonProps, IconButton, IconButtonProps } from "@mui/material";
import { Link } from "react-router-dom";
import styled from "styled-components";

type Props = {
    link?: string;
    icon?: boolean;
} & ButtonProps &
    IconButtonProps;

export const SoecoButton = (props: Props) => {
    return (
        <>
            {props.link ? (
                <ButtonLink to={props.link}>
                    <StyledButton
                        className={props.className}
                        variant={"contained"}
                        color={props.color ? props.color : "primary"}
                        onClick={props.onClick}
                        sx={{ color: "white" }}
                        fullWidth={props.fullWidth}
                        {...props}
                    >
                        {props.children}
                    </StyledButton>
                </ButtonLink>
            ) : props.icon ? (
                <StyledIconButton
                    className={props.className}
                    color={props.color ? props.color : "primary"}
                    onClick={props.onClick}
                    disabled={props.disabled}
                >
                    {props.children}
                </StyledIconButton>
            ) : (
                <StyledButton
                    className={props.className}
                    variant={"contained"}
                    disabled={props.disabled}
                    color={props.color ? props.color : "primary"}
                    onClick={props.onClick}
                    sx={{ color: "white" }}
                    fullWidth={props.fullWidth}
                    size={props.size}
                    form={props.form}
                    type={props.type}
                    {...props}
                >
                    {props.children}
                </StyledButton>
            )}
        </>
    );
};

const ButtonLink = styled(Link)`
    text-decoration: none;
`;

const StyledIconButton = styled(IconButton)`
    text-decoration: none;
`;

const StyledButton = styled(Button)`
    color: white;
`;
