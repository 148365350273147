import { ErrorRecord, Product } from "../../types";

export const validateNewProduct = (
  product: Product,
  images: File[],
  setErrors: (errors: ErrorRecord<Product>) => void,
  setError: (error: boolean) => void,
  step: number,
  setStep: (step: number) => void,
  completed: { [k: number]: boolean },
  setCompleted: (completed: { [k: number]: boolean }) => void,
) => {
  let valid = true;
  const newErrors: ErrorRecord<Product> = {};
  switch (step) {
    case 0: {
      if (images.length == 0) {
        valid = false;
        newErrors.imageUrls = "Please upload an image";
      }
      break;
    }
    case 1: {
      break;
    }
    case 2: {
      setCompleted({ ...completed, [step]: true });
      break;
    }
    case 3: {
      if (!product.name) {
        newErrors.name = "Please enter a name";
        valid = false;
      }
      break;
    }
    case 4: {
      if (product.netPrice && parseInt(product.netPrice) < 0) {
        newErrors.netPrice = "Sales price must be greater than -1";
        valid = false;
      }
      if (product.purchasePrice && parseInt(product.purchasePrice) < 0) {
        newErrors.purchasePrice = "Please enter a valid price";
        valid = false;
      }
      if (product.marketPrice && parseInt(product.marketPrice) < 0) {
        newErrors.marketPrice = "Please enter a valid price";
        valid = false;
      }
      break;
    }
    case 5: {
      //const quantity = filterInt(product.quantity);
      if (product.quantity < 0) {
        newErrors.quantity = "Please enter a valid quantity";
        valid = false;
      }
      break;
    }
    default:
      setError(false);
      setErrors({});
      setStep(step + 1);
  }
  if (!valid) {
    setErrors(newErrors);
    setError(true);
    setCompleted({ ...completed, [step]: false });
  } else {
    setErrors({});
    setError(false);
    setCompleted({ ...completed, [step]: true });
    setStep(step + 1);
  }
};
