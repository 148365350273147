import styled from "styled-components";
import { MainMenuHeader } from "./MenuHeader";
import { MenuIcons } from "./MenuIcons";
import { useGetEmployee } from "../../hooks/employeeHooks";
import { useContext } from "react";
import { EmployeeContext } from "../../context/EmployeeContext";
import { useGetCompany } from "../../hooks/companyHooks";
import Div100vh from "react-div-100vh";

export const MainMenu = () => {
    const { employee } = useContext(EmployeeContext);
    const employeeQuery = useGetEmployee(employee.companyId, employee.id);
    const companyQuery = useGetCompany(employee.companyId);
    return (
        <MainMenuWrapper>
            <PageHeaderWrapper>
                <MainMenuHeader employee={employeeQuery?.data} company={companyQuery?.data} />
            </PageHeaderWrapper>
            <MenuIcons />
        </MainMenuWrapper>
    );
};

const MainMenuWrapper = styled(Div100vh)`
    display: flex;
    flex-direction: column;
`;

const PageHeaderWrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-left: 15px;
    margin-right: 15px;
    margin-top: 15px;
`;
