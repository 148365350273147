import styled from "styled-components";
import { OptionsObject, Product } from "../../types";
import CameraAltIcon from "@mui/icons-material/CameraAlt";
import CategoryIcon from "@mui/icons-material/Category";
import DescriptionIcon from "@mui/icons-material/Description";
import PaidIcon from "@mui/icons-material/Paid";
import FactoryIcon from "@mui/icons-material/Factory";
import {
    CardActions,
    Chip,
    DialogContent,
    ImageList,
    ImageListItem,
    Typography,
} from "@mui/material";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import { SoecoButton } from "../../components/SoecoButton";
import Dialog from "@mui/material/Dialog";
import { useState } from "react";
import EditIcon from "@mui/icons-material/Edit";
import { useGetProductCategories } from "../../hooks/productCategoryHooks";

type Props = {
    product: Product;
    manufacturer: OptionsObject | null;
    designer: OptionsObject | null;
    status: OptionsObject | null;
    imageUrls: string[];
    step: number;
    setStep: (step: number) => void;
};

export const ProductOverview = (props: Props) => {
    const [open, setOpen] = useState(false);
    const [imageModalUrl, setImageModalUrl] = useState("");

    const handleShowModal = (imageUrl: string) => {
        setImageModalUrl(imageUrl);
        setOpen(true);
    };

    const { data: productCategories } = useGetProductCategories();

    return (
        <ProductOverviewWrapper>
            {open ? (
                <Dialog open={open} onClose={() => setOpen(!open)}>
                    <DialogContent>
                        <img onClick={() => setOpen(false)} src={imageModalUrl} alt="product" />
                    </DialogContent>
                </Dialog>
            ) : null}
            {/* CameraForm */}
            <CardWrapper>
                <Card variant="outlined">
                    <StyledCardActions>
                        <IconActionsWrapper>
                            <CameraAltIcon color="primary" fontSize="inherit" />
                            <SoecoButton icon={true} onClick={() => props.setStep(props.step - 5)}>
                                <EditIcon fontSize="large" />
                            </SoecoButton>
                        </IconActionsWrapper>
                    </StyledCardActions>
                    <CardContent>
                        <ImageListWrapper>
                            <ImageList cols={3}>
                                {props.imageUrls.map(imageUrl => {
                                    return (
                                        <ImageListItem key={imageUrl}>
                                            <img
                                                onClick={() => handleShowModal(imageUrl)}
                                                src={imageUrl}
                                                alt="image"
                                            />
                                        </ImageListItem>
                                    );
                                })}
                            </ImageList>
                        </ImageListWrapper>
                    </CardContent>
                </Card>
            </CardWrapper>
            {/* ProductCategoryForm */}
            <CardWrapper>
                <Card variant="outlined">
                    <StyledCardActions>
                        <IconActionsWrapper>
                            <CategoryIcon color="primary" fontSize="large" />
                            <SoecoButton icon={true} onClick={() => props.setStep(props.step - 4)}>
                                <EditIcon fontSize="large" />
                            </SoecoButton>
                        </IconActionsWrapper>
                    </StyledCardActions>
                    <CardContent>
                        {/* Map categories to name from productCategories */}
                        {productCategories ? (
                            productCategories.map(data => {
                                if (
                                    props.product.categories &&
                                    props.product.categories.includes(data.id)
                                ) {
                                    return <Chip key={data.id} label={data.name} />;
                                }
                            })
                        ) : (
                            <></>
                        )}
                    </CardContent>
                </Card>
            </CardWrapper>
            {/* DetailsForm */}
            <CardWrapper>
                <Card variant="outlined">
                    <StyledCardActions>
                        <IconActionsWrapper>
                            <DescriptionIcon color="primary" fontSize="large" />
                            <SoecoButton icon={true} onClick={() => props.setStep(props.step - 3)}>
                                <EditIcon fontSize="large" />
                            </SoecoButton>
                        </IconActionsWrapper>
                    </StyledCardActions>
                    <CardContent>
                        <RowWrapper>
                            <Typography variant="body1" component="h6">
                                Name:
                            </Typography>
                            {props.product.name ? (
                                <Typography>{props.product.name}</Typography>
                            ) : (
                                <></>
                            )}
                        </RowWrapper>

                        <RowWrapper>
                            <Typography variant="body1" component="h6">
                                Manufacturer:
                            </Typography>
                            {props.manufacturer ? (
                                <Typography variant="body1" component="h6">
                                    {props.manufacturer?.label}
                                </Typography>
                            ) : (
                                <></>
                            )}
                        </RowWrapper>
                        <RowWrapper>
                            <Typography variant="body1" component="h6">
                                Designer:
                            </Typography>
                            {props.designer ? (
                                <Typography variant="body1" component="h6">
                                    {props.designer?.label}
                                </Typography>
                            ) : (
                                <></>
                            )}
                        </RowWrapper>
                        <RowWrapper>
                            <Typography variant="body1" component="h6">
                                Color:
                            </Typography>
                            {props.product.color ? (
                                <Typography variant="body1" component="h6">
                                    {props.product.color}
                                </Typography>
                            ) : (
                                <></>
                            )}
                        </RowWrapper>
                        <RowWrapper>
                            <Typography variant="body1" component="h6">
                                Measurements:
                            </Typography>
                            {props.product.measurements ? (
                                <Typography variant="body1" component="h6">
                                    {props.product.measurements}
                                </Typography>
                            ) : (
                                <></>
                            )}
                        </RowWrapper>
                        <RowWrapper>
                            <Typography variant="body1" component="h6">
                                Status:
                            </Typography>
                            {props.status ? (
                                <Typography variant="body1" component="h6">
                                    {props.status?.label}
                                </Typography>
                            ) : (
                                <></>
                            )}
                        </RowWrapper>
                        <RowWrapper>
                            <Typography variant="body1" component="h6">
                                Comment:
                            </Typography>
                            {props.product.comment ? (
                                <Typography variant="body1" component="h6">
                                    {props.product.comment}
                                </Typography>
                            ) : (
                                <></>
                            )}
                        </RowWrapper>
                    </CardContent>
                </Card>
            </CardWrapper>
            {/* PriceForm */}
            <CardWrapper>
                <Card variant="outlined">
                    <StyledCardActions>
                        <IconActionsWrapper>
                            <PaidIcon color="primary" fontSize="large" />
                            <SoecoButton icon={true} onClick={() => props.setStep(props.step - 2)}>
                                <EditIcon fontSize="large" />
                            </SoecoButton>
                        </IconActionsWrapper>
                    </StyledCardActions>
                    <CardContent>
                        <RowWrapper>
                            <Typography variant="body1" component="h6">
                                Sales Price:
                            </Typography>
                            {props.product.netPrice ? (
                                <Typography>{props.product.netPrice}</Typography>
                            ) : (
                                <></>
                            )}
                        </RowWrapper>
                        <RowWrapper>
                            <Typography variant="body1" component="h6">
                                Purchase Price:
                            </Typography>
                            {props.product.purchasePrice ? (
                                <Typography variant="body1" component="h6">
                                    {props.product.purchasePrice}
                                </Typography>
                            ) : (
                                <></>
                            )}
                        </RowWrapper>
                        <RowWrapper>
                            <Typography variant="body1" component="h6">
                                Market Price:
                            </Typography>
                            {props.product.marketPrice ? (
                                <Typography variant="body1" component="h6">
                                    {props.product.marketPrice}
                                </Typography>
                            ) : (
                                <></>
                            )}
                        </RowWrapper>
                    </CardContent>
                </Card>
            </CardWrapper>
            {/* QuantityLocationForm */}
            <CardWrapper>
                <Card variant="outlined">
                    <StyledCardActions>
                        <IconActionsWrapper>
                            <FactoryIcon color="primary" fontSize="inherit" />
                            <SoecoButton icon={true} onClick={() => props.setStep(props.step - 1)}>
                                <EditIcon fontSize="large" />
                            </SoecoButton>
                        </IconActionsWrapper>
                    </StyledCardActions>
                    <CardContent>
                        <RowWrapper>
                            <Typography variant="body1" component="h6">
                                Building:
                            </Typography>
                            {props.product.warehousePlacement ? (
                                <Typography>{props.product.warehousePlacement}</Typography>
                            ) : (
                                <></>
                            )}
                        </RowWrapper>
                        <RowWrapper>
                            <Typography variant="body1" component="h6">
                                Room:
                            </Typography>
                            {props.product.roomPlacement ? (
                                <Typography variant="body1" component="h6">
                                    {props.product.roomPlacement}
                                </Typography>
                            ) : (
                                <></>
                            )}
                        </RowWrapper>
                        <RowWrapper>
                            <Typography variant="body1" component="h6">
                                Quantity:
                            </Typography>
                            {props.product.quantity ? (
                                <Typography variant="body1" component="h6">
                                    {props.product.quantity}
                                </Typography>
                            ) : (
                                <></>
                            )}
                        </RowWrapper>
                    </CardContent>
                </Card>
            </CardWrapper>
        </ProductOverviewWrapper>
    );
};

const ProductOverviewWrapper = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: center;
    gap: 1rem;
`;

const CardWrapper = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
`;

const ImageListWrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
`;

const RowWrapper = styled.div`
    display: flex;
    flex-direction: row;
    h6:first-child {
        max-width: 150px;
        width: 100%;
        color: #757575;
    }
    h6:nth-child(2) {
        display: flex;
        flex-shrink: 1;
        word-break: break-all;
    }
`;

const IconActionsWrapper = styled.div`
    display: flex;
    justify-content: space-between;
    margin-right: 0.5rem;
    align-items: center;
    width: 100%;
    font-size: 2.5rem;
`;

const StyledCardActions = styled(CardActions)`
    display: flex;
    width: 100%;
    font-size: 3rem;
    background-color: #ebebeb;
`;
