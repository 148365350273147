import { Area } from "react-easy-crop";
import { Designer, Employee, Manufacturer, OptionsArray, ProductStatus } from "../types";

export const isGlobalAdmin = (employee: Employee) => {
    if (employee.role === "soecoAdmin" || employee.role === "superAdmin") {
        return true;
    }
    return false;
};

export const isAdmin = (employee: Employee) => {
    if (
        employee.role === "admin" ||
        employee.role === "soecoAdmin" ||
        employee.role === "superAdmin"
    ) {
        return true;
    }
    return false;
};

export const getRolePriority = (role: string) => {
    switch (role) {
        case "superAdmin":
            return 0;
        case "soecoAdmin":
            return 1;
        case "admin":
            return 2;
        case "maintainer":
            return 3;
        default:
            return 4;
    }
};

// Checks if the given string is a valid email by following pattern,
// anystring@anystring.anystring
export const isValidEmail = (email: string) => {
    const regex = /\S+@\S+\.\S+/;
    return regex.test(email);
};

export const isValidPassword = (password: string) => {
    const strongPasswordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})/;
    return strongPasswordRegex.test(password);
};

export const createReadOnlySortedOptions = (
    array: Manufacturer[] | Designer[] | ProductStatus[],
) => {
    const a = array
        .map(item => {
            return { label: item.name, id: item.id };
        })
        .sort((a, b) => a.label.localeCompare(b.label));
    const b: OptionsArray = a;
    return b;
};

export const createReadOnlyOptions = (array: Manufacturer[] | Designer[] | ProductStatus[]) => {
    const a = array.map(item => {
        return { label: item.name, id: item.id };
    });
    const b: OptionsArray = a;
    return b;
};

export const createImage = (url: string) =>
    new Promise<HTMLImageElement>((resolve, reject) => {
        const image = new Image();
        image.addEventListener("load", () => resolve(image));
        image.addEventListener("error", error => reject(error));
        image.src = url;
    });

export function getRadianAngle(degreeValue: number) {
    return (degreeValue * Math.PI) / 180;
}

/**
 * Returns the new bounding area of a rotated rectangle.
 */
export function rotateSize(width: number, height: number, rotation: number) {
    const rotRad = getRadianAngle(rotation);

    return {
        width: Math.abs(Math.cos(rotRad) * width) + Math.abs(Math.sin(rotRad) * height),
        height: Math.abs(Math.sin(rotRad) * width) + Math.abs(Math.cos(rotRad) * height),
    };
}

export function readFile(file: Blob, callback: (result: string) => void) {
    const reader = new FileReader();
    reader.onloadend = () => callback(reader.result as string);
    reader.readAsDataURL(file);
}

export async function getCroppedImg(
    imageSrc: string,
    pixelCrop: Area,
    rotation = 0,
    flip = { horizontal: false, vertical: false },
) {
    const image = await createImage(imageSrc);
    const canvas = document.createElement("canvas");
    const ctx = canvas.getContext("2d");

    if (!ctx) {
        return "";
    }

    const rotRad = getRadianAngle(rotation);

    // calculate bounding box of the rotated image
    const { width: bBoxWidth, height: bBoxHeight } = rotateSize(
        image.width,
        image.height,
        rotation,
    );

    // set canvas size to match the bounding box
    canvas.width = bBoxWidth;
    canvas.height = bBoxHeight;

    // translate canvas context to a central location to allow rotating and flipping around the center
    ctx.translate(bBoxWidth / 2, bBoxHeight / 2);
    ctx.rotate(rotRad);
    ctx.scale(flip.horizontal ? -1 : 1, flip.vertical ? -1 : 1);
    ctx.translate(-image.width / 2, -image.height / 2);

    // draw rotated image
    ctx.drawImage(image, 0, 0);

    // croppedAreaPixels values are bounding box relative
    // extract the cropped image using these values
    const data = ctx.getImageData(pixelCrop.x, pixelCrop.y, pixelCrop.width, pixelCrop.height);

    // set canvas width to final desired crop size - this will clear existing context
    canvas.width = pixelCrop.width;
    canvas.height = pixelCrop.height;

    // paste generated rotate image at the top left corner
    ctx.putImageData(data, 0, 0);

    // As Base64 string
    // return canvas.toDataURL('image/jpeg');

    // As a blob
    return new Promise<string>((resolve, reject) => {
        canvas.toBlob(file => {
            if (!file) {
                reject(new Error("Canvas is empty"));
                return "";
            }
            resolve(URL.createObjectURL(file));
        }, "image/jpeg");
    });
}

export async function getRotatedImage(imageSrc: string, rotation = 0) {
    const image = await createImage(imageSrc);
    const canvas = document.createElement("canvas");
    const ctx = canvas.getContext("2d");
    if (!ctx) {
        return null;
    }

    const orientationChanged =
        rotation === 90 || rotation === -90 || rotation === 270 || rotation === -270;
    if (orientationChanged) {
        canvas.width = image.height;
        canvas.height = image.width;
    } else {
        canvas.width = image.width;
        canvas.height = image.height;
    }

    ctx.translate(canvas.width / 2, canvas.height / 2);
    ctx.rotate((rotation * Math.PI) / 180);
    ctx.drawImage(image, -image.width / 2, -image.height / 2);

    return new Promise((resolve, reject) => {
        canvas.toBlob(file => {
            if (!file) {
                reject(new Error("Canvas is empty"));
                return;
            }
            resolve(URL.createObjectURL(file));
        }, "image/png");
    });
}
