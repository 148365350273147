import styled from "styled-components";
import { ErrorRecord, OptionsArray, OptionsObject, Product } from "../../types";
import CameraAltIcon from "@mui/icons-material/CameraAlt";
import CategoryIcon from "@mui/icons-material/Category";
import DescriptionIcon from "@mui/icons-material/Description";
import PaidIcon from "@mui/icons-material/Paid";
import FactoryIcon from "@mui/icons-material/Factory";
import DeleteIcon from "@mui/icons-material/Delete";
import {
    CardActions,
    Chip,
    DialogActions,
    ImageList,
    ImageListItem,
    Typography,
} from "@mui/material";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import { SoecoButton } from "../../components/SoecoButton";
import Dialog from "@mui/material/Dialog";
import { useEffect, useState } from "react";
import EditIcon from "@mui/icons-material/Edit";
import { useGetProductCategories } from "../../hooks/productCategoryHooks";
import { useDeleteProduct, useGetProduct, useUpdateProduct } from "../../hooks/productHooks";
import { useNavigate, useParams } from "react-router-dom";
import { useGetDesigners } from "../../hooks/designerHooks";
import { useGetManufacturers } from "../../hooks/manufacturerHooks";
import { useGetProductStatuses } from "../../hooks/productStatusHooks";
import { ExistingImageList } from "./ExistingImageList";
import { EditProductCategory } from "./EditProductCategory";
import { EditDetails } from "./EditDetails";
import { validateChanges } from "./validateChanges";
import { EditPrices } from "./EditPrices";
import { EditFactory } from "./EditFactory";
import { DialogTitle } from "@material-ui/core";
import { imagesUrl } from "../../services/endpoints";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { useModal } from "../../hooks/useModal";
import { Modal } from "../../components/Modals/Modal";
import { ErrorModal } from "../../components/Modals/ErrorModal";
import { useSnackBar } from "../../context/SnackbarContext";
import { createReadOnlyOptions, createReadOnlySortedOptions } from "../../utils/util";

export const EditProduct = () => {
    const { companyId, inventoryId, productId } = useParams();
    const navigate = useNavigate();
    const updateMutation = useUpdateProduct();
    const deleteMutation = useDeleteProduct();

    // Show a modal to edit the product categories, details, prices, etc.
    const [modal, setModal] = useState<{
        images: boolean;
        categories: boolean;
        details: boolean;
        prices: boolean;
        factories: boolean;
        delete: boolean;
    }>({
        images: false,
        categories: false,
        details: false,
        prices: false,
        factories: false,
        delete: false,
    });

    const handleModal = (key: keyof typeof modal) => {
        setModal({
            ...modal,
            [key]: !modal[key],
        });
    };

    const { data: productData } = useGetProduct(
        companyId ?? "",
        inventoryId ?? "",
        productId ?? "",
    );

    const { data: manufacturersData } = useGetManufacturers();
    const { data: designersData } = useGetDesigners();
    const { data: statusesData } = useGetProductStatuses();
    const { data: productCategories } = useGetProductCategories();

    const [manufacturers, setManufacturers] = useState<OptionsArray>([]);
    const [designers, setDesigners] = useState<OptionsArray>([]);
    const [productStatuses, setProductStatuses] = useState<OptionsArray>([]);

    const [manufacturer, setManufacturer] = useState<OptionsObject | null>(null);
    const [designer, setDesigner] = useState<OptionsObject | null>(null);
    const [productStatus, setProductStatus] = useState<OptionsObject | null>(null);

    const [newManufacturer, setNewManufacturer] = useState<OptionsObject | null>(null);
    const [newDesigner, setNewDesigner] = useState<OptionsObject | null>(null);
    const [newProductStatus, setNewProductStatus] = useState<OptionsObject | null>(null);

    const [product, setProduct] = useState<Product>({
        companyId: companyId ?? "",
        inventoryId: inventoryId ?? "",
        name: "",
        categories: [],
        imageUrls: [],
        netPrice: "",
        purchasePrice: "",
        marketPrice: "",
        color: "",
        measurements: "",
        comment: "",
        manufacturer: "",
        designer: "",
        status: "",
        warehousePlacement: "",
        roomPlacement: "",
        quantity: 1,
    });

    const [changedProduct, setChangedProduct] = useState<Product>({} as Product);

    useEffect(() => {
        if (productData) {
            const product = productData;
            product.netPrice == null && (product.netPrice = "");
            product.purchasePrice == null && (product.purchasePrice = "");
            product.marketPrice == null && (product.marketPrice = "");
            setProduct(product);
            setChangedProduct(productData);
        }
    }, [productData]);

    useEffect(() => {
        if (manufacturersData) {
            setManufacturers(createReadOnlySortedOptions(manufacturersData));
            createReadOnlySortedOptions(manufacturersData).forEach(item => {
                if (item.id === product.manufacturer) {
                    setManufacturer(item);
                    setNewManufacturer(item);
                }
            });
        }
    }, [product.manufacturer, manufacturersData]);
    useEffect(() => {
        if (designersData) {
            const a = createReadOnlySortedOptions(designersData);
            setDesigners(a);
            a.forEach(item => {
                if (item.id === product.designer) {
                    setDesigner(item);
                    setNewDesigner(item);
                }
            });
        }
    }, [product.designer, designersData]);
    useEffect(() => {
        if (statusesData) {
            setProductStatuses(createReadOnlyOptions(statusesData));
            createReadOnlyOptions(statusesData).forEach(item => {
                if (item.id === product.status) {
                    setProductStatus(item);
                    setNewProductStatus(item);
                }
            });
        }
    }, [product.status, statusesData]);

    const handleModalSave = (key: keyof typeof modal) => {
        if (!validateChanges(changedProduct, setErrors, setError, key)) {
            return;
        }
        key === "categories"
            ? setProduct({ ...product, categories: changedProduct.categories })
            : key === "details"
            ? setProduct({
                  ...product,
                  name: changedProduct.name,
                  manufacturer: changedProduct.manufacturer,
                  designer: changedProduct.designer,
                  color: changedProduct.color,
                  measurements: changedProduct.measurements,
                  comment: changedProduct.comment,
                  status: changedProduct.status,
                  warehousePlacement: changedProduct.warehousePlacement,
                  roomPlacement: changedProduct.roomPlacement,
                  quantity: changedProduct.quantity,
              })
            : key === "prices"
            ? setProduct({
                  ...product,
                  netPrice: changedProduct.netPrice,
                  purchasePrice: changedProduct.purchasePrice,
                  marketPrice: changedProduct.marketPrice,
              })
            : key === "images"
            ? setProduct({ ...product, imageUrls: changedProduct.imageUrls })
            : key === "factories"
            ? setProduct({
                  ...product,
                  warehousePlacement: changedProduct.warehousePlacement,
                  roomPlacement: changedProduct.roomPlacement,
                  quantity: changedProduct.quantity,
              })
            : null;
        setIsChanged(true);
        handleModal(key);
    };

    const handleModalCancel = (key: keyof typeof modal) => {
        setChangedProduct(product);
        setNewManufacturer(manufacturer);
        setNewDesigner(designer);
        setNewProductStatus(productStatus);
        handleModal(key);
    };

    const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        setChangedProduct({ ...changedProduct, [e.target.name]: e.target.value });
    };

    const handleSelectChange = (
        e: React.SyntheticEvent<Element, Event>,
        value: OptionsObject | null,
        targetField: string,
    ) => {
        value && setChangedProduct({ ...changedProduct, [targetField]: value.id });
        value && targetField === "manufacturer" && setNewManufacturer(value);
        value && targetField === "designer" && setNewDesigner(value);
        value && targetField === "status" && setNewProductStatus(value);
    };

    const [error, setError] = useState<boolean>(false);
    const [errors, setErrors] = useState<ErrorRecord<Product>>({});
    const [isChanged, setIsChanged] = useState<boolean>(false);

    const snackBar = useSnackBar();

    const { isShown, toggle } = useModal();
    const onConfirm = () => {
        handleDeleteProduct();
    };
    const onCancel = () => toggle();

    const handleSubmit = (e: React.FormEvent<Element>) => {
        e.preventDefault();

        if (!companyId || !inventoryId || !productId) {
            // This cannot happen unless routes has been invalidated
            return;
        }
        updateMutation.mutate(
            { companyId, inventoryId, productId, product },
            {
                onError: error => {
                    if (error instanceof Error) {
                        snackBar.showSnackBar(error.message, "error");
                    }
                },
                onSuccess: () => {
                    snackBar.showSnackBar("Product updated", "success");
                    navigate(-1);
                },
            },
        );
    };

    const handleDeleteProduct = () => {
        if (!companyId || !inventoryId || !productId) {
            // This cannot happen unless routes has been invalidated
            return;
        }
        deleteMutation.mutate(
            { companyId, inventoryId, productId },
            {
                onError: error => {
                    if (error instanceof Error) {
                        snackBar.showSnackBar(error.message, "error");
                    }
                },
                onSuccess: () => {
                    toggle();
                    snackBar.showSnackBar("Product deleted", "success");
                    navigate(-1);
                },
            },
        );
    };

    return (
        <EditProductPageWrapper>
            <EditProductWrapper>
                <Modal
                    isShown={isShown}
                    hide={toggle}
                    headerText="Delete the product?"
                    modalContent={
                        <ErrorModal
                            onConfirm={onConfirm}
                            onCancel={onCancel}
                            message="You will not be able to recover it"
                        />
                    }
                />
                <Dialog fullScreen open={modal.images}>
                    <DialogTitle>
                        <DialogTitleWrapper>
                            <CameraAltIcon color="primary" fontSize="inherit" />
                        </DialogTitleWrapper>
                    </DialogTitle>
                    <EditWrapper>
                        <ExistingImageList />
                    </EditWrapper>
                    <ActionButtons>
                        <DialogActions>
                            <SoecoButton
                                fullWidth
                                color="secondary"
                                onClick={() => handleModalCancel("images")}
                            >
                                Cancel
                            </SoecoButton>
                        </DialogActions>
                    </ActionButtons>
                </Dialog>
                <Dialog fullScreen open={modal.categories}>
                    <DialogTitleWrapper>
                        <CategoryIcon color="primary" fontSize="inherit" />
                    </DialogTitleWrapper>
                    <EditProductCategory
                        changedProduct={changedProduct}
                        setChangedProduct={setChangedProduct}
                    />
                    <DialogActions>
                        <SoecoButton
                            fullWidth
                            color="secondary"
                            onClick={() => handleModalCancel("categories")}
                        >
                            Cancel
                        </SoecoButton>
                        <SoecoButton fullWidth onClick={() => handleModalSave("categories")}>
                            Save
                        </SoecoButton>
                    </DialogActions>
                </Dialog>
                <Dialog fullScreen open={modal.details}>
                    <DialogTitleWrapper>
                        <DescriptionIcon color="primary" fontSize="inherit" />
                    </DialogTitleWrapper>
                    <EditDetails
                        changedProduct={changedProduct}
                        manufacturers={manufacturers}
                        manufacturer={newManufacturer}
                        designers={designers}
                        designer={newDesigner}
                        productStatuses={productStatuses}
                        productStatus={newProductStatus}
                        handleChange={handleChange}
                        handleSelectChange={handleSelectChange}
                        error={error}
                        errors={errors}
                    />
                    <DialogActions>
                        <SoecoButton
                            fullWidth
                            color="secondary"
                            onClick={() => handleModalCancel("details")}
                        >
                            Cancel
                        </SoecoButton>
                        <SoecoButton fullWidth onClick={() => handleModalSave("details")}>
                            Save
                        </SoecoButton>
                    </DialogActions>
                </Dialog>
                <Dialog fullScreen open={modal.prices}>
                    <DialogTitleWrapper>
                        <PaidIcon color="primary" fontSize="inherit" />
                    </DialogTitleWrapper>
                    <EditPrices
                        changedProduct={changedProduct}
                        handleChange={handleChange}
                        error={error}
                        errors={errors}
                    />
                    <DialogActions>
                        <SoecoButton
                            fullWidth
                            color="secondary"
                            onClick={() => handleModalCancel("prices")}
                        >
                            Cancel
                        </SoecoButton>
                        <SoecoButton fullWidth onClick={() => handleModalSave("prices")}>
                            Save
                        </SoecoButton>
                    </DialogActions>
                </Dialog>
                <Dialog fullScreen open={modal.factories}>
                    <DialogTitleWrapper>
                        <FactoryIcon color="primary" fontSize="inherit" />
                    </DialogTitleWrapper>
                    <EditFactory
                        changedProduct={changedProduct}
                        handleChange={handleChange}
                        error={error}
                        errors={errors}
                    />
                    <DialogActions>
                        <SoecoButton
                            fullWidth
                            color="secondary"
                            onClick={() => handleModalCancel("factories")}
                        >
                            Cancel
                        </SoecoButton>
                        <SoecoButton fullWidth onClick={() => handleModalSave("factories")}>
                            Save
                        </SoecoButton>
                    </DialogActions>
                </Dialog>
                {/* CameraForm */}
                <EditWrapper>
                    <ProductOverviewWrapper>
                        <CardWrapper>
                            <Card variant="outlined">
                                <StyledCardActions>
                                    <IconActionsWrapper>
                                        <CameraAltIcon color="primary" fontSize="inherit" />
                                        <SoecoButton
                                            icon={true}
                                            onClick={() => handleModal("images")}
                                        >
                                            <EditIcon fontSize="large" />
                                        </SoecoButton>
                                    </IconActionsWrapper>
                                </StyledCardActions>
                                <CardContent>
                                    <ImageListWrapper>
                                        <ImageList cols={3}>
                                            {product?.imageUrls ? (
                                                product.imageUrls.map(imageUrl => {
                                                    return (
                                                        <ImageListItem key={imageUrl}>
                                                            <img
                                                                src={imagesUrl + imageUrl}
                                                                alt="image"
                                                            />
                                                        </ImageListItem>
                                                    );
                                                })
                                            ) : (
                                                <></>
                                            )}
                                        </ImageList>
                                    </ImageListWrapper>
                                </CardContent>
                            </Card>
                        </CardWrapper>
                        {/* ProductCategoryForm */}
                        <CardWrapper>
                            <Card variant="outlined">
                                <StyledCardActions>
                                    <IconActionsWrapper>
                                        <CategoryIcon color="primary" fontSize="large" />
                                        <SoecoButton
                                            icon={true}
                                            onClick={() => handleModal("categories")}
                                        >
                                            <EditIcon fontSize="large" />
                                        </SoecoButton>
                                    </IconActionsWrapper>
                                </StyledCardActions>
                                <CardContent>
                                    {/* Map categories to name from productCategories */}
                                    {productCategories ? (
                                        productCategories.map(data => {
                                            if (
                                                product.categories &&
                                                product.categories.includes(data.id)
                                            ) {
                                                return <Chip key={data.id} label={data.name} />;
                                            }
                                        })
                                    ) : (
                                        <></>
                                    )}
                                </CardContent>
                            </Card>
                        </CardWrapper>
                        {/* DetailsForm */}
                        <CardWrapper>
                            <Card variant="outlined">
                                <StyledCardActions>
                                    <IconActionsWrapper>
                                        <DescriptionIcon color="primary" fontSize="large" />
                                        <SoecoButton
                                            icon={true}
                                            onClick={() => handleModal("details")}
                                        >
                                            <EditIcon fontSize="large" />
                                        </SoecoButton>
                                    </IconActionsWrapper>
                                </StyledCardActions>
                                <CardContent>
                                    <RowWrapper>
                                        <Typography variant="body1" component="h6">
                                            Name:
                                        </Typography>
                                        {product.name ? (
                                            <Typography>{product.name}</Typography>
                                        ) : (
                                            <></>
                                        )}
                                    </RowWrapper>

                                    <RowWrapper>
                                        <Typography variant="body1" component="h6">
                                            Manufacturer:
                                        </Typography>
                                        {product.manufacturer ? (
                                            <Typography variant="body1" component="h6">
                                                {manufacturer?.label}
                                            </Typography>
                                        ) : (
                                            <></>
                                        )}
                                    </RowWrapper>
                                    <RowWrapper>
                                        <Typography variant="body1" component="h6">
                                            Designer:
                                        </Typography>
                                        {product.designer ? (
                                            <Typography variant="body1" component="h6">
                                                {designer?.label}
                                            </Typography>
                                        ) : (
                                            <></>
                                        )}
                                    </RowWrapper>
                                    <RowWrapper>
                                        <Typography variant="body1" component="h6">
                                            Color:
                                        </Typography>
                                        {product.color ? (
                                            <Typography variant="body1" component="h6">
                                                {product.color}
                                            </Typography>
                                        ) : (
                                            <></>
                                        )}
                                    </RowWrapper>
                                    <RowWrapper>
                                        <Typography variant="body1" component="h6">
                                            Measurements:
                                        </Typography>
                                        {product.measurements ? (
                                            <Typography variant="body1" component="h6">
                                                {product.measurements}
                                            </Typography>
                                        ) : (
                                            <></>
                                        )}
                                    </RowWrapper>
                                    <RowWrapper>
                                        <Typography variant="body1" component="h6">
                                            Status:
                                        </Typography>
                                        {product.status ? (
                                            <Typography variant="body1" component="h6">
                                                {productStatus?.label}
                                            </Typography>
                                        ) : (
                                            <></>
                                        )}
                                    </RowWrapper>
                                    <RowWrapper>
                                        <Typography variant="body1" component="h6">
                                            Comment:
                                        </Typography>
                                        {product.comment ? (
                                            <Typography variant="body1" component="h6">
                                                {product.comment}
                                            </Typography>
                                        ) : (
                                            <></>
                                        )}
                                    </RowWrapper>
                                </CardContent>
                            </Card>
                        </CardWrapper>
                        {/* PriceForm */}
                        <CardWrapper>
                            <Card variant="outlined">
                                <StyledCardActions>
                                    <IconActionsWrapper>
                                        <PaidIcon color="primary" fontSize="large" />
                                        <SoecoButton
                                            icon={true}
                                            onClick={() => handleModal("prices")}
                                        >
                                            <EditIcon fontSize="large" />
                                        </SoecoButton>
                                    </IconActionsWrapper>
                                </StyledCardActions>
                                <CardContent>
                                    <RowWrapper>
                                        <Typography variant="body1" component="h6">
                                            Sales Price:
                                        </Typography>
                                        {product.netPrice ? (
                                            <Typography>{product.netPrice}</Typography>
                                        ) : (
                                            <></>
                                        )}
                                    </RowWrapper>
                                    <RowWrapper>
                                        <Typography variant="body1" component="h6">
                                            Purchase Price:
                                        </Typography>
                                        {product.purchasePrice ? (
                                            <Typography variant="body1" component="h6">
                                                {product.purchasePrice}
                                            </Typography>
                                        ) : (
                                            <></>
                                        )}
                                    </RowWrapper>
                                    <RowWrapper>
                                        <Typography variant="body1" component="h6">
                                            Market Price:
                                        </Typography>
                                        {product.marketPrice ? (
                                            <Typography variant="body1" component="h6">
                                                {product.marketPrice}
                                            </Typography>
                                        ) : (
                                            <></>
                                        )}
                                    </RowWrapper>
                                </CardContent>
                            </Card>
                        </CardWrapper>
                        {/* QuantityLocationForm */}
                        <CardWrapper>
                            <Card variant="outlined">
                                <StyledCardActions>
                                    <IconActionsWrapper>
                                        <FactoryIcon color="primary" fontSize="inherit" />
                                        <SoecoButton
                                            icon={true}
                                            onClick={() => handleModal("factories")}
                                        >
                                            <EditIcon fontSize="large" />
                                        </SoecoButton>
                                    </IconActionsWrapper>
                                </StyledCardActions>
                                <CardContent>
                                    <RowWrapper>
                                        <Typography variant="body1" component="h6">
                                            Building:
                                        </Typography>
                                        {product.warehousePlacement ? (
                                            <Typography>{product.warehousePlacement}</Typography>
                                        ) : (
                                            <></>
                                        )}
                                    </RowWrapper>
                                    <RowWrapper>
                                        <Typography variant="body1" component="h6">
                                            Room:
                                        </Typography>
                                        {product.roomPlacement ? (
                                            <Typography variant="body1" component="h6">
                                                {product.roomPlacement}
                                            </Typography>
                                        ) : (
                                            <></>
                                        )}
                                    </RowWrapper>
                                    <RowWrapper>
                                        <Typography variant="body1" component="h6">
                                            Quantity:
                                        </Typography>
                                        {product.quantity ? (
                                            <Typography variant="body1" component="h6">
                                                {product.quantity}
                                            </Typography>
                                        ) : (
                                            <></>
                                        )}
                                    </RowWrapper>
                                </CardContent>
                            </Card>
                        </CardWrapper>
                    </ProductOverviewWrapper>
                </EditWrapper>
                <ActionButtons>
                    <SoecoButton color="secondary" onClick={toggle}>
                        <DeleteIcon fontSize="large" />
                    </SoecoButton>
                    <SoecoButton
                        fullWidth
                        size="large"
                        color="secondary"
                        onClick={() => {
                            navigate(-1);
                        }}
                    >
                        Cancel
                    </SoecoButton>
                    <SoecoButton
                        fullWidth
                        size="large"
                        color="primary"
                        onClick={() => {
                            navigate(
                                `/companies/${product.companyId}/inventories/${product.inventoryId}/products/new`,
                                { state: { productId: product.id } },
                            );
                        }}
                    >
                        <ContentCopyIcon fontSize="large" />
                    </SoecoButton>
                    <SoecoButton
                        disabled={!isChanged || updateMutation.isPending}
                        fullWidth
                        size="large"
                        type="submit"
                        color="primary"
                        onClick={(e: React.FormEvent<Element>) => handleSubmit(e)}
                    >
                        Save
                    </SoecoButton>
                </ActionButtons>
            </EditProductWrapper>
        </EditProductPageWrapper>
    );
};

const EditProductPageWrapper = styled.div`
    display: flex;
    flex-direction: column;
    height: 80vh;
    justify-content: center;
    align-items: center;
    gap: 20px;
`;

const EditProductWrapper = styled.div`
    display: flex;
    flex-direction: column;
    width: 95%;
    height: 100%;
    justify-content: space-between;
    gap: 2rem;
`;

const EditWrapper = styled.div`
    display: flex;
    flex-direction: column;
    heigth: 100%;
    max-heigth: 80%;
    overflow: scroll;
`;

export const ActionButtons = styled.div`
    display: flex;
    width: 95%;
    gap: 2rem;
    position: fixed;
    bottom: 2rem;
`;

const ProductOverviewWrapper = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: center;
    gap: 1rem;
`;

const CardWrapper = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
`;

const ImageListWrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
`;

const RowWrapper = styled.div`
    display: flex;
    flex-direction: row;
    h6:first-child {
        max-width: 150px;
        width: 100%;
        color: #757575;
    }
    h6:nth-child(2) {
        display: flex;
        flex-shrink: 1;
        word-break: break-all;
    }
`;

const IconActionsWrapper = styled.div`
    display: flex;
    justify-content: space-between;
    margin-right: 0.5rem;
    align-items: center;
    width: 100%;
    font-size: 2.5rem;
`;

const DialogTitleWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    margin-top: 1rem;
    margin-bottom: 1rem;
    font-size: 2.5rem;
`;

const StyledCardActions = styled(CardActions)`
    display: flex;
    width: 100%;
    font-size: 3rem;
    background-color: #ebebeb;
`;
