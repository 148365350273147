import { FormControl, TextField } from "@mui/material";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { SoecoButton } from "../../components/SoecoButton";
import { useDeleteCompany, useUpdateCompany, useGetCompany } from "../../hooks/companyHooks";
import { ErrorRecord } from "../../types";
import DeleteIcon from "@mui/icons-material/Delete";
import styled from "styled-components";
import { SoecoHeader } from "../../components/SoecoHeader";
import { useModal } from "../../hooks/useModal";
import { Modal } from "../../components/Modals/Modal";
import { ErrorModal } from "../../components/Modals/ErrorModal";
import { useSnackBar } from "../../context/SnackbarContext";

type EditCompanyForm = {
    name: string;
};

export const EditCompanyPage = () => {
    const { companyId } = useParams();
    const { data: companyData } = useGetCompany(companyId ?? "");
    const updateMutation = useUpdateCompany();
    const deleteMutation = useDeleteCompany();
    const navigate = useNavigate();
    const [form, setForm] = useState<EditCompanyForm>({
        name: "",
    });

    const [error, setError] = useState<boolean>(false);
    const [errors, setErrors] = useState<ErrorRecord<EditCompanyForm>>({});
    const [disableButton, setDisableButton] = useState<boolean>(false);

    useEffect(() => {
        if (companyData) {
            setForm({
                name: companyData.name ?? "",
            });
        }
    }, [companyData]);

    const transformFormData = (form: EditCompanyForm) => ({
        name: form.name,
    });

    const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        setForm(prevForm => ({ ...prevForm, [e.target.name]: e.target.value }));
        setDisableButton(false);
        setError(false);
    };

    const isValidForm = () => {
        let valid = true;
        const newErrors: ErrorRecord<EditCompanyForm> = {};
        if (form.name.length < 1) {
            valid = false;
            newErrors.name = "name cannot be empty";
        }
        if (!valid) {
            setErrors(newErrors);
            setError(true);
            setDisableButton(true);
            return false;
        } else {
            setErrors({});
            setError(false);
            setDisableButton(false);
            return true;
        }
    };

    const snackBar = useSnackBar();

    const handleSubmit = () => {
        if (isValidForm()) {
            if (!companyId) {
                return;
            }
            updateMutation.mutate(
                {
                    companyId: companyId,
                    company: transformFormData(form),
                },
                {
                    onError: error => {
                        if (error instanceof Error) {
                            snackBar.showSnackBar(error.message, "error");
                            setDisableButton(true);
                        }
                    },
                    onSuccess: () => {
                        snackBar.showSnackBar("Company updated successfully", "success");
                        navigate(-1);
                    },
                },
            );
        }
    };

    const handleDeleteCompany = () => {
        if (!companyId) {
            return;
        }
        deleteMutation.mutate(
            { companyId },
            {
                onError: error => {
                    if (error instanceof Error) {
                        snackBar.showSnackBar(error.message, "error");
                        setDisableButton(true);
                    }
                },
                onSuccess: () => {
                    snackBar.showSnackBar("Company deleted", "success");
                    navigate(-1);
                },
            },
        );
    };

    const { isShown, toggle } = useModal();

    const onConfirm = () => {
        toggle();
        handleDeleteCompany();
    };
    const onCancel = () => toggle();

    return (
        <EditCompanyPageWrapper>
            <EditCompanyWrapper>
                <Modal
                    isShown={isShown}
                    hide={toggle}
                    headerText="Delete the company?"
                    modalContent={
                        <ErrorModal
                            onConfirm={onConfirm}
                            onCancel={onCancel}
                            message="You will not be able to recover it"
                        />
                    }
                />
                <SoecoHeader>Edit Company</SoecoHeader>
                <Form>
                    <TextField
                        name="name"
                        autoComplete="off"
                        error={error && errors.name ? true : false}
                        helperText={error && errors.name}
                        label="Name"
                        variant="outlined"
                        value={form.name}
                        onChange={handleChange}
                    />
                </Form>
            </EditCompanyWrapper>
            <ActionButtons>
                <SoecoButton color="secondary" onClick={toggle}>
                    <DeleteIcon fontSize="large" />
                </SoecoButton>
                <SoecoButton fullWidth size="large" color="secondary" onClick={() => navigate(-1)}>
                    Cancel
                </SoecoButton>
                <SoecoButton
                    fullWidth
                    size="large"
                    disabled={updateMutation.isPending || disableButton}
                    color="primary"
                    onClick={handleSubmit}
                >
                    Update
                </SoecoButton>
            </ActionButtons>
        </EditCompanyPageWrapper>
    );
};

const EditCompanyPageWrapper = styled.div`
    display: flex;
    flex-direction: column;
    height: 80vh;
    align-items: center;
    gap: 2rem;
`;

const EditCompanyWrapper = styled.div`
    display: flex;
    flex-direction: column;
    width: 95%;
    height: 70%;
    align-content: center;
    gap: 2rem;
`;

const Form = styled(FormControl)`
    display: flex;
    flex-direction: column;
    align-content: center;
    width: 95%;
    margin: 0 auto;
    gap: 20px;
`;

const ActionButtons = styled.div`
    display: flex;
    width: 90%;
    gap: 2rem;
    position: fixed;
    bottom: 2rem;
`;
