import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { Company } from "../types";
import { Endpoint, setEndpoint } from "../services/endpoints";
import { deleteRequest, get, post, put } from "../services/restClient";

export interface CreateCompany {
    name: string;
}

export interface EditCompany {
    name: string;
}

export interface CreateSubCompany {
    name: string;
    superCompany: string;
}


const getCompanyById = async (companyId: string) => {
    const data = await get<Company>(setEndpoint(Endpoint.company, { companyId }), {
        requireAuth: true,
    });
    return data;
};

export function useGetCompany(companyId: string) {
    return useQuery({ queryKey: ["companies", companyId], queryFn: () => getCompanyById(companyId) });
}
const getCompanies = async () => {
    const data = await get<Company[]>(setEndpoint(Endpoint.companies), { requireAuth: true });
    return data;
};

export const useGetCompanies = () => {
    return useQuery({ queryKey: ["companies"], queryFn: () => getCompanies() });
};

const postCompany = async (company: CreateCompany) => {
    const data = await post<CreateCompany, Company>(setEndpoint(Endpoint.companies), company, {
        requireAuth: true,
    });
    return data;
};

export const useAddCompany = () => {
    const request = postCompany;
    const queryClient = useQueryClient();

    return useMutation({
        mutationFn: async ({ company }: { company: CreateCompany }) => {
            const result = await request(company);
            return result;
        },
        onSuccess: () => {
            return queryClient.invalidateQueries({
                queryKey: ['companies'],
            });
        },
    }
    );
};


export const useUpdateCompany = () => {
    const request = putCompany;
    const queryClient = useQueryClient();

    return useMutation({
        mutationFn: async ({ companyId, company }: { companyId: string; company: EditCompany }) => {
            return request(companyId, company);
        },
        onSuccess: (data, { companyId }) => {
            void queryClient.invalidateQueries({ queryKey: ['companies'] });
            void queryClient.invalidateQueries({ queryKey: ['company', companyId] });
        },
    });
};

const putCompany = async (companyId: string, company: EditCompany) => {
    const data = await put<EditCompany, Company>(
        setEndpoint(Endpoint.company, { companyId }),
        company,
        { requireAuth: true },
    );
    return data;
};

export const useDeleteCompany = () => {
    const request = deleteCompany;
    const queryClient = useQueryClient();

    return useMutation({
        mutationFn: async ({ companyId }: { companyId: string }) => {
            return await request(companyId);
        },
        onSuccess: (data, { companyId }) => {
            void queryClient.invalidateQueries({ queryKey: ["companies"] });
            void queryClient.cancelQueries({ queryKey: ["company", companyId] });
        },
    });
};

const deleteCompany = async (companyId: string) => {
    const data = await deleteRequest(setEndpoint(Endpoint.company, { companyId }), {
        requireAuth: true,
    });
    return data;
};

const getSubCompanies = async (companyId: string) => {
    const data = await get<Company[]>(setEndpoint(Endpoint.subCompanies, { companyId }), {
        requireAuth: true,
    });
    return data;
};

export const useGetSubCompanies = (companyId: string) => {
    return useQuery({ queryKey: ["subCompanies", companyId], queryFn: () => getSubCompanies(companyId) });
};

const createSubCompany = async (subCompany: CreateSubCompany) => {

    const superCompanyId: string = subCompany.superCompany

    const data = await post<CreateSubCompany, Company>(
        setEndpoint(Endpoint.subCompany, { superCompanyId }),
        subCompany,
        { requireAuth: true }
    );
    return data;
};

export const useCreateSubCompany = () => {
    const request = createSubCompany;
    const queryClient = useQueryClient();

    return useMutation(
        {
            mutationFn: async ({ subCompany }: { subCompany: CreateSubCompany }) => {
                const result = await request(subCompany);
                return result;
            },
            onSuccess: () => {
                return queryClient.invalidateQueries({queryKey: ["subCompanies"]});
            },
        }
    );
}
