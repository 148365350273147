import { useQuery } from "@tanstack/react-query";
import { ProductStatus } from "../types";
import { get } from "../services/restClient";
import { Endpoint, setEndpoint } from "../services/endpoints";

const getProductStatuses = async () => {
    const data = await get<ProductStatus[]>(setEndpoint(Endpoint.productStatuses), {
        requireAuth: true,
    });
    return data;
};

export const useGetProductStatuses = () => {
    return useQuery({queryKey: ["productStatus"], queryFn: () => getProductStatuses()});
};
