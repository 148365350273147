import ImageList from "@mui/material/ImageList";
import ImageListItem from "@mui/material/ImageListItem";
import styled from "styled-components";
import { useParams } from "react-router-dom";
import { useGetProduct } from "../../hooks/productHooks";
import { Loader } from "../../components/Loader";
import { imagesUrl } from "../../services/endpoints";

export const ExistingImageList = () => {
    const { companyId, inventoryId, productId } = useParams();
    const productQuery = useGetProduct(companyId ?? "", inventoryId ?? "", productId ?? "");

    return productQuery.isLoading || !productQuery.data ? (
        <Loader />
    ) : productQuery.isError ? (
        <div>Error</div>
    ) : (
        <ImageListWrapper>
            <ImageList cols={2}>
                {productQuery.data.imageUrls ? (
                    productQuery.data.imageUrls.map(imageUrl => {
                        return (
                            <ImageListItem key={imageUrl}>
                                <img src={imagesUrl + imageUrl} alt="image" />
                            </ImageListItem>
                        );
                    })
                ) : (
                    <p>No existing images</p>
                )}
            </ImageList>
        </ImageListWrapper>
    );
};

const ImageListWrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-left: 15px;
    margin-right: 15px;
    margin-top: 15px;
`;
