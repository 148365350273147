import { Avatar, AvatarProps } from "@mui/material";
import { useState, useEffect } from "react";

type Props = {
    src: string;
    fallbackSrc: string;
} & AvatarProps;

export const AvatarWithFallback = (props: Props) => {
    const [src, setSrc] = useState(props.src);
    const [loaded, setLoaded] = useState(false);

    useEffect(() => {
        const img = new Image();
        img.src = src;
        img.onload = () => {
            setLoaded(true);
        };
        img.onerror = () => {
            setSrc(props.fallbackSrc);
        };
    }, [src]);

    return <Avatar alt="No " src={src} />;
};
