import {
    FormControl,
    FormHelperText,
    IconButton,
    InputAdornment,
    MenuItem,
    Select,
    SelectChangeEvent,
    TextField,
} from "@mui/material";
import { useContext, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { SoecoButton } from "../../components/SoecoButton";
import { EmployeeContext } from "../../context/EmployeeContext";
import { isGlobalAdmin, isValidEmail, isValidPassword } from "../../utils/util";
import { useGetCompany } from "../../hooks/companyHooks";
import { useAddEmployee, CreateEmployee } from "../../hooks/employeeHooks";
import { ErrorRecord } from "../../types";
import styled from "styled-components";
import { SoecoHeader } from "../../components/SoecoHeader";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { useSnackBar } from "../../context/SnackbarContext";
import Div100vh from "react-div-100vh";

type CreateEmployeeForm = {
    name: string;
    email: string;
    password: string;
    confirmPassword: string;
    role: string;
};

export const CreateUser = () => {
    const { companyId } = useParams();
    const { data: company } = useGetCompany(companyId ?? "");
    const { employee } = useContext(EmployeeContext);
    const mutation = useAddEmployee();
    const navigate = useNavigate();
    const snackBar = useSnackBar();

    const [form, setForm] = useState<CreateEmployeeForm>({
        name: "",
        email: "",
        password: "",
        confirmPassword: "",
        role: "",
    });

    const [error, setError] = useState<boolean>(false);
    const [errors, setErrors] = useState<ErrorRecord<CreateEmployeeForm>>({});
    const [disableButton, setDisableButton] = useState<boolean>(false);

    const [showPassword, setShowPassword] = useState<{ current: boolean; confirm: boolean }>({
        current: false,
        confirm: false,
    });

    const handleChangeSelect = (e: SelectChangeEvent<string>) => {
        setForm({ ...form, role: e.target.value });
        setErrors({});
        setError(false);
    };

    const handleClickShowPassword = (target: string) => {
        target === "current"
            ? setShowPassword({ ...showPassword, current: !showPassword.current })
            : setShowPassword({ ...showPassword, confirm: !showPassword.confirm });
    };

    const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
        event.preventDefault();
    };

    const transformFormData = (form: CreateEmployeeForm): CreateEmployee => ({
        name: form.name,
        email: form.email.toLowerCase(),
        password: form.password,
        role: form.role,
    });

    const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        setForm({ ...form, [e.target.name]: e.target.value });
        setDisableButton(false);
        setError(false);
    };

    const isValidForm = () => {
        let valid = true;
        const newErrors: ErrorRecord<CreateEmployeeForm> = {};
        if (form.role === "") {
            newErrors.role = "Role cannot be empty";
            valid = false;
        }
        if (!isValidEmail(form.email)) {
            valid = false;
            newErrors.email = "Enter a valid email address";
        }
        if (form.name.length < 1) {
            valid = false;
            newErrors.name = "Name cannot be empty";
        }
        if (!isValidPassword(form.password)) {
            valid = false;
            newErrors.password =
                "Password must be at least 8 characters long, contain at least one uppercase letter, one lowercase letter, one number and one special character";
        }
        if (form.password !== form.confirmPassword) {
            valid = false;
            newErrors.confirmPassword = "Passwords don't match";
        }
        if (!valid) {
            setErrors(newErrors);
            setError(true);
            return false;
        } else {
            setErrors({});
            setError(false);
            return true;
        }
    };

    const handleSubmit = () => {
        if (isValidForm()) {
            mutation.mutate(
                { companyId: company?.id ?? "", employee: transformFormData(form) },
                {
                    onError: error => {
                        if (error instanceof Error) {
                            snackBar.showSnackBar(error.message, "error");
                            setDisableButton(true);
                        }
                    },
                    onSuccess: () => {
                        snackBar.showSnackBar("User created successfully", "success");
                        navigate(-1);
                    },
                },
            );
        }
    };

    return (
        <CreateUserPageWrapper>
            <SoecoHeader>Create User</SoecoHeader>
            <CreateUserWrapper>
                <Form>
                    {company && (
                        <TextField
                            disabled={true}
                            variant="outlined"
                            defaultValue={company?.name}
                        />
                    )}
                    {company && isGlobalAdmin(employee) && company.id === employee.companyId ? (
                        <Select label="Role" value={form.role} onChange={handleChangeSelect}>
                            <MenuItem value={"maintainer"}>Maintainer</MenuItem>
                            <MenuItem value={"admin"}>Admin</MenuItem>
                            <MenuItem value={"soecoAdmin"}>Soeco Admin</MenuItem>
                        </Select>
                    ) : (
                        <Select label="Role" value={form.role} onChange={handleChangeSelect}>
                            <MenuItem value={"maintainer"}>Maintainer</MenuItem>
                            <MenuItem value={"admin"}>Admin</MenuItem>
                        </Select>
                    )}
                    {errors.role ? (
                        <FormHelperText error={error && errors.role ? true : false}>
                            {errors.role}
                        </FormHelperText>
                    ) : null}
                    <TextField
                        name="email"
                        autoComplete="off"
                        error={error && errors.email ? true : false}
                        value={form.email}
                        label="E-mail"
                        variant="outlined"
                        helperText={error && errors.email}
                        onChange={handleChange}
                    />

                    <TextField
                        name="name"
                        label="Name"
                        autoComplete="off"
                        error={error && errors.name ? true : false}
                        helperText={error && errors.name}
                        variant="outlined"
                        value={form.name}
                        onChange={handleChange}
                    />

                    <TextField
                        name="password"
                        autoComplete="off"
                        error={error && errors.password ? true : false}
                        helperText={error && errors.password}
                        label="Password"
                        type={showPassword.current ? "text" : "password"}
                        variant="outlined"
                        value={form.password}
                        onChange={handleChange}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <IconButton
                                        name="current"
                                        aria-label="toggle password visibility"
                                        onClick={() => handleClickShowPassword("current")}
                                        onMouseDown={handleMouseDownPassword}
                                        edge="end"
                                    >
                                        {showPassword.current ? <VisibilityOff /> : <Visibility />}
                                    </IconButton>
                                </InputAdornment>
                            ),
                        }}
                    />
                    <TextField
                        name="confirmPassword"
                        autoComplete="off"
                        error={error && errors.confirmPassword ? true : false}
                        helperText={error && errors.confirmPassword}
                        label="Confirm password"
                        type={showPassword.confirm ? "text" : "password"}
                        variant="outlined"
                        value={form.confirmPassword}
                        onChange={handleChange}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <IconButton
                                        name="confirm"
                                        aria-label="toggle password visibility"
                                        onClick={() => handleClickShowPassword("confirm")}
                                        onMouseDown={handleMouseDownPassword}
                                        edge="end"
                                    >
                                        {showPassword.confirm ? <VisibilityOff /> : <Visibility />}
                                    </IconButton>
                                </InputAdornment>
                            ),
                        }}
                    />
                </Form>
            </CreateUserWrapper>
            <ActionButtons>
                <SoecoButton fullWidth size="large" color="secondary" onClick={() => navigate(-1)}>
                    Back
                </SoecoButton>
                <SoecoButton
                    fullWidth
                    size="large"
                    disabled={mutation.isPending || disableButton}
                    color="primary"
                    className="btnLoginCreate"
                    onClick={handleSubmit}
                >
                    Create
                </SoecoButton>
            </ActionButtons>
        </CreateUserPageWrapper>
    );
};

const CreateUserPageWrapper = styled(Div100vh)`
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 2rem;
`;

const CreateUserWrapper = styled.div`
    display: flex;
    flex-direction: column;
    width: 95%;
    height: 70%;
    align-content: center;
    gap: 2rem;
    overflow: scroll;
`;

const Form = styled(FormControl)`
    display: flex;
    flex-direction: column;
    align-content: center;
    width: 95%;
    gap: 1rem;
`;

const ActionButtons = styled.div`
    display: flex;
    width: 90%;
    gap: 2rem;
    position: fixed;
    bottom: 2rem;
`;
