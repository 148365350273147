import { qrcode, drawingSVG } from "bwip-js";

export const generateQrCodeAsSvg = (url: string) => {
    try {
        const options = {
            bcid: "qrcode",
            text: `${url}`,
            scale: 6,
            includetext: true,
        };

        const svg: string = qrcode(options, drawingSVG());
        return svg;
    } catch (e) {
        console.log(e);
        return undefined;
    }
};

export const generateQrCodeAsZpl = (url: string, productName: string | undefined): string => {
    // Max height in need of fine adjustment. 22.5mm is incorrect by probably 0.1 to 0.4mm
    // Alternatively the mmToDots function rounding function can be tweaked
    // Current setup can print 12 readable QR codes before a manual feed is required
    const maxHeightMM = 22.5;
    const maxHeightDots = mmToDots(maxHeightMM);
    const adjustY = mmToDots(3);

    const centerX = 40;
    const centerY = 0 + adjustY;

    const scale = 3;

    const titleX = 220;
    const titleY = 10 + adjustY;
    const gbX = 220;
    const gbY = 50 + adjustY;
    const productNameX = 220;
    const productNameY = 62 + adjustY;
    
    const zplTitle = `
^CF0,40
^FO${titleX},${titleY}^FDSoeco^FS
^FO${gbX},${gbY}^GB250,3,3^FS
^CF0,20
${productName ? `^FO${productNameX},${productNameY}^FD${productName}^FS` : ''}`;

    const zplQR = `^XA
${zplTitle}
^FO${centerX},${centerY}
^BQN,2,${scale}
^FDMM,A${url.toUpperCase()}^FS
^LL${maxHeightDots}
^XZ`;
    console.log(zplQR);
    return zplQR;
};

function mmToDots(mm: number) {
    const inches = mm / 25.4;
    const dpi = 203;

    const dots = inches * dpi;

    return Math.floor(dots);
}