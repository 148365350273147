import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import { useState } from "react";
import { isGlobalAdmin } from "../utils/util";
import { useGetCompanies } from "../hooks/companyHooks";

export const AdminCompanyFilter = ({
    currentCompanyId,
    onChange,
}: {
    currentCompanyId: string;
    onChange: (newCompanyId: string) => void;
}) => {
    const { data: companies } = useGetCompanies();
    const [selected, setSelected] = useState(currentCompanyId);

    const onChangeSelected = (newId: string) => {
        setSelected(newId);
        onChange(newId);
    };

    if (!isGlobalAdmin) {
        return null;
    }

    return (
        <FormControl fullWidth>
            <InputLabel id="filter-company">Acting Company</InputLabel>
            {companies && (
                <Select
                    labelId="filter-company"
                    label="Acting Company"
                    value={selected}
                    onChange={event => onChangeSelected(event.target.value)}
                >
                    {companies?.map(company => (
                        <MenuItem key={company.id} value={company.id}>
                            {company.name}
                        </MenuItem>
                    ))}
                </Select>
            )}
        </FormControl>
    );
};
