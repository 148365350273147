import { App } from "./App";
import { theme } from "./theme";
import { ThemeProvider } from "@mui/material/styles";
import { QueryClientProvider } from "@tanstack/react-query";
import { queryClient } from "./hooks/queryClient";
import { EmployeeProvider } from "./context/EmployeeContext";
import { InventoryProvider } from "./context/InventoryContext";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import { SnackBarProvider } from "./context/SnackbarContext";
import { createRoot } from "react-dom/client";
import { register } from "./serviceWorkerRegistration";

const container = document.getElementById("root");
const root = createRoot(container as Element);

register();

root.render(
    <QueryClientProvider client={queryClient}>
        <ReactQueryDevtools />
        <ThemeProvider theme={theme}>
            <EmployeeProvider>
                <InventoryProvider>
                    <SnackBarProvider>
                        <App />
                    </SnackBarProvider>
                </InventoryProvider>
            </EmployeeProvider>
        </ThemeProvider>
    </QueryClientProvider>,
);
