import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import AddBoxRoundedIcon from "@mui/icons-material/AddBoxRounded";
import ManageSearchRoundedIcon from "@mui/icons-material/ManageSearchRounded";
import AdminPanelSettingsRoundedIcon from "@mui/icons-material/AdminPanelSettingsRounded";
import { Typography } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { useContext } from "react";
import { EmployeeContext } from "../../context/EmployeeContext";
import { isAdmin } from "../../utils/util";
import { SoecoButton } from "../../components/SoecoButton";

export const MenuIcons = () => {
    const theme = useTheme();
    const { employee } = useContext(EmployeeContext);
    const navigate = useNavigate();
    return (
        <MenuWrapper>
            {isAdmin(employee) ? (
                <>
                    <SoecoButton icon onClick={() => navigate("/admin")}>
                        <IconWrapper>
                            <AdminPanelSettingsRoundedIcon
                                fontSize="inherit"
                                style={{ color: theme.palette.secondary.main }}
                            />
                        </IconWrapper>
                    </SoecoButton>
                    <Typography variant="h6">Admin</Typography>
                </>
            ) : (
                <></>
            )}
            <SoecoButton
                icon
                onClick={() => navigate(`/companies/${employee.companyId}/createInventory`)}
            >
                <IconWrapper>
                    <AddBoxRoundedIcon
                        fontSize="inherit"
                        style={{ color: theme.palette.primary.main }}
                    />
                </IconWrapper>
            </SoecoButton>
            <Typography variant="h6">Create Inventory</Typography>
            <SoecoButton
                icon
                onClick={() => navigate(`/companies/${employee.companyId}/inventories/`)}
            >
                <IconWrapper>
                    <ManageSearchRoundedIcon
                        fontSize="inherit"
                        style={{ color: theme.palette.primary.main }}
                    />
                </IconWrapper>
            </SoecoButton>
            <Typography variant="h6">Inventory List</Typography>
        </MenuWrapper>
    );
};

const MenuWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
`;

const IconWrapper = styled.div`
    display: flex;
    flex-direction: column;
    font-size: 130px;
    @media (max-width: 400px) {
        font-size: 100px;
    }
`;
