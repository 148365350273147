import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import CameraAltIcon from "@mui/icons-material/CameraAlt";
import CategoryIcon from "@mui/icons-material/Category";
import DescriptionIcon from "@mui/icons-material/Description";
import PaidIcon from "@mui/icons-material/Paid";
import FactoryIcon from "@mui/icons-material/Factory";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import SearchIcon from '@mui/icons-material/Search';
import WarningIcon from "@mui/icons-material/Warning";
import styled from "styled-components";
import { useTheme } from "@mui/material/styles";

type Props = {
  step: number;
  completed: { [k: number]: boolean };
  setCompleted: (completed: { [k: number]: boolean }) => void;
  error: boolean;
};

export const SoecoStepper = (props: Props) => {
  const theme = useTheme();

  const steps = [
    "CameraForm",
    "PredictProduct",
    "ProductCategoryForm",
    "DetailsForm",
    "PriceForm",
    "QuantityLocationForm",
  ];

  const stepError = <WarningIcon color="error" fontSize="large" />;
  const stepComplete = <CheckCircleIcon color="success" fontSize="large" />;

  const stepsIconActive = [
    <CameraAltIcon color="info" fontSize="large" />,
    <SearchIcon color="info" fontSize="large" />,
    <CategoryIcon color="info" fontSize="large" />,
    <DescriptionIcon color="info" fontSize="large" />,
    <PaidIcon color="info" fontSize="large" />,
    <FactoryIcon color="info" fontSize="large" />,
  ];

  const stepsIconInactive = [
    <CameraAltIcon htmlColor={theme.palette.grey[700]} fontSize="large" />,
    <SearchIcon htmlColor={theme.palette.grey[700]} fontSize="large" />,
    <CategoryIcon htmlColor={theme.palette.grey[700]} fontSize="large" />,
    <DescriptionIcon htmlColor={theme.palette.grey[700]} fontSize="large" />,
    <PaidIcon htmlColor={theme.palette.grey[700]} fontSize="large" />,
    <FactoryIcon htmlColor={theme.palette.grey[700]} fontSize="large" />,
  ];

  return (
    <StepperWrapper>
      <Stepper activeStep={props.step} alternativeLabel>
        {steps.map((label, index) =>
          props.error && index === props.step ? (
            <Step key={label}>
              <StepLabel error icon={stepError}></StepLabel>
            </Step>
          ) : (
            <Step key={label} completed={props.completed[index]}>
              <StepLabel
                icon={
                  props.completed[index]
                    ? stepComplete
                    : index === props.step
                      ? stepsIconActive[index]
                      : stepsIconInactive[index]
                }
              />
            </Step>
          ),
        )}
      </Stepper>
    </StepperWrapper>
  );
};

const StepperWrapper = styled.div`
    margin-top: 1rem;
`;
