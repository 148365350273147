import React, { useState, createContext } from "react";
import { Employee } from "../types";

type Props = {
    children?: React.ReactNode;
};

export type EmployeeContextState = {
    employee: Employee;
    setEmployee: (employee: Employee) => void;
};

export const EmployeeContext = createContext<EmployeeContextState>({} as EmployeeContextState);

export const EmployeeProvider: React.FC<Props> = ({ children }): JSX.Element => {
    const [employee, setEmployee] = useState<Employee>({} as Employee);

    return (
        <EmployeeContext.Provider value={{ employee, setEmployee }}>
            {children}
        </EmployeeContext.Provider>
    );
};
