import { Navigate, useNavigate } from "react-router-dom";
import styled from "styled-components";
import { useParams } from "react-router-dom";
import { isGlobalAdmin } from "../../utils/util";
import { ManageUsersActions } from "./ManageUsersActions";
import { ManageUsersList } from "./ManageUsersList";
import { useGetEmployees } from "../../hooks/employeeHooks";
import { AdminCompanyFilter } from "../../components/AdminCompanyFilter";
import { SoecoHeader } from "../../components/SoecoHeader";
import { useContext } from "react";
import { EmployeeContext } from "../../context/EmployeeContext";

export const ManageUsers = () => {
    const { employee } = useContext(EmployeeContext);
    const { companyId } = useParams();
    const { data: employees } = useGetEmployees(companyId ?? "");
    const navigate = useNavigate();

    // TODO: It might be good to check if id is valid as well.
    if (!isGlobalAdmin) {
        return <Navigate to="/404" />;
    }

    const onChange = (idToNavigate: string) => {
        navigate(`/companies/${idToNavigate}/manageUsers`);
    };

    return (
        <ManageUsersPageWrapper>
            <SoecoHeader>Manage Users</SoecoHeader>
            {companyId && isGlobalAdmin(employee) && (
                <AdminCompanyFilter currentCompanyId={companyId} onChange={onChange} />
            )}
            <ManageUsersListWrapper>
                <ManageUsersList employee={employee} employees={employees ?? []} />
            </ManageUsersListWrapper>
            {companyId && <ManageUsersActions currentCompanyId={companyId} />}
        </ManageUsersPageWrapper>
    );
};

const ManageUsersPageWrapper = styled.div`
    display: flex;
    flex-direction: column;
    height: 80vh;
    align-items: center;
    gap: 2rem;
`;

const ManageUsersListWrapper = styled.div`
    display: flex;
    height: 100%;
    width: 100%;
`;
