import { useQuery, useQueryClient } from "@tanstack/react-query";
import ZebraBrowserPrintWrapper from 'zebra-browser-print-wrapper';
import { Device } from 'zebra-browser-print-wrapper/lib/types';

interface DeviceStatus {
  isReadyToPrint: boolean;
  errors: string;
}

const browserPrint = new ZebraBrowserPrintWrapper();

const getDevices = async () => {
  const devices: Device[] = await browserPrint.getAvailablePrinters() as Device[] || [];
  return devices;
};

export const useGetDevices = () => {
  return useQuery({queryKey: ["printDevices"], queryFn: () => getDevices()});
}

const getDeviceStatus = async () => {
  const status: DeviceStatus = await browserPrint.checkPrinterStatus() as DeviceStatus;
  return status;
};

export const useGetDeviceStatus = () => {
  return useQuery({queryKey: ["deviceStatus"], queryFn: () => getDeviceStatus()});
}

export const printLabels = async (zpl: string, quantity: number, device: Device) => {

  browserPrint.setPrinter(device);

  for (let i = 0; i < quantity; i++) {
    await browserPrint.print(zpl);
  }
}

export const usePrintLabels = (zpl: string, quantity: number, device: Device) => {
  return useQuery({queryKey: ["printLabel"], queryFn: () => printLabels(zpl, quantity, device)});
}

export const setPrinter = (device: Device) => {
  browserPrint.setPrinter(device);
}