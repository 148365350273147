import { AlertColor } from "@mui/material";

export enum Role {
    superAdmin = "superAdmin",
    soecoAdmin = "soecoAdmin",
    admin = "admin",
    maintainer = "maintainer",
}

export type Employee = {
    companyId: string;
    id: string;
    email?: string;
    name?: string;
    role: Role;
};

export type Company = {
    id: string;
    name: string;
    superCompany?: string;
};

export type Inventory = {
    companyId: string;
    id: string;
    name?: string;
    project?: string;
    estimatedDeliveryDate?: string;
    customerId?: string;
    description?: string;
};

export type Product = {
    companyId: string;
    inventoryId: string;
    id?: string;
    name?: string;
    categories?: string[];
    netPrice?: string;
    purchasePrice?: string;
    marketPrice?: string;
    imageUrls?: string[];
    color?: string;
    measurements?: string;
    comment?: string;
    manufacturer?: string;
    designer?: string;
    status?: string;
    warehousePlacement?: string;
    roomPlacement?: string;
    quantity: number;
    predictions?: string[];
    predictionUsed?: string; 
};

export type decodedAccessToken = {
    companyId: string;
    id: string;
    name: string;
    email: string;
    role: string;
    iat: number;
    exp: number;
};

export type AccessToken = {
    accessToken: string;
    maxAge: number; // Need to keep track of max time to live
    employeeId: string;
    companyId: string;
    role: string;
};

export type Params = {
    companyId: string;
    inventoryId: string;
    id: string;
};

export type ProductCategory = {
    id: string;
    soecoId: string;
    name: string;
};

export type Designer = {
    id: string;
    soecoId: string;
    name: string;
};

export type Manufacturer = {
    id: string;
    soecoId: string;
    name: string;
};

export type ProductStatus = {
    id: string;
    name: string;
};

export type OptionsArray = ReadonlyArray<OptionsObject>;
export type OptionsObject = { label: string; id: string };

export type ErrorRecord<T> = Partial<Record<keyof T, string>>;

export type CustomSnackbarOptions = {
    open?: boolean;
    message?: string;
    // variant: "success" | "error" | "warning" | "info" | "default";
    severity?: AlertColor | undefined;
    onClose?: () => void;
};

export type ResetPasswordForm = {
    newPassword: string;
    confirmPassword: string;
};

export type LoginForm = {
    email: string;
    password: string;
};

export type UpdatePasswordForm = {
    currentPassword: string;
    newPassword: string;
};
