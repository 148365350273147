import styled from "styled-components";
import { AdminMenu } from "./AdminMenu";

export const Admin = () => {
    return (
        <AdminPageWrapper>
            <AdminMenu />
        </AdminPageWrapper>
    );
};

const AdminPageWrapper = styled.div`
    display: flex;
    flex-direction: column;
    height: 80vh;
    justify-content: center;
    align-items: center;
`;
