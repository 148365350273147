import { useContext, useState } from "react";
import TextField from "@mui/material/TextField";
import { SoecoButton } from "../../components/SoecoButton";
import { Loader } from "../../components/Loader";
import { ActionButtons, Form, FormWrapper, NewInventoryPageWrapper } from "./NewInventory.styled";
import { EmployeeContext } from "../../context/EmployeeContext";
import { useNavigate, useParams } from "react-router-dom";
import { useGetCompany } from "../../hooks/companyHooks";
import { CreateInventory, useAddInventory } from "../../hooks/inventoryHooks";
import { SoecoHeader } from "../../components/SoecoHeader";
import { ErrorRecord } from "../../types";
import { useSnackBar } from "../../context/SnackbarContext";

export const NewInventory = () => {
    const { companyId } = useParams();
    const mutation = useAddInventory();
    const { employee } = useContext(EmployeeContext);
    const companyQuery = useGetCompany(companyId ?? employee.companyId);
    const [error, setError] = useState<boolean>(false);
    const [errors, setErrors] = useState<ErrorRecord<CreateInventory>>({});
    const [disableButton, setDisableButton] = useState<boolean>(false);
    const [form, setForm] = useState<CreateInventory>({
        companyId: companyId ?? "",
        name: "",
        project: "",
        estimatedDeliveryDate: "",
        customerId: "",
        description: "",
    });

    const snackBar = useSnackBar();
    const navigate = useNavigate();

    const isValidForm = () => {
        let valid = true;
        const newErrors: ErrorRecord<CreateInventory> = {};
        if (form.name.length < 1) {
            valid = false;
            newErrors.name = "Name cannot be empty";
        }
        if (
            form.estimatedDeliveryDate.match(/^\d{4}-(0[1-9]|1[0-2])-(0[1-9]|[12][0-9]|3[01])$/) ==
            null
        ) {
            valid = false;
            newErrors.estimatedDeliveryDate = "Date must be written as YYYY-MM-DD";
        }
        if (!valid) {
            setErrors(newErrors);
            setError(true);
            setDisableButton(true);
            return false;
        } else {
            setErrors({});
            setError(false);
            return true;
        }
    };

    const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        setForm({ ...form, [e.target.name]: e.target.value });
        setDisableButton(false);
        setError(false);
    };

    const handleSubmit = () => {
        if (isValidForm()) {
            if (!companyId) {
                return;
            }
            mutation.mutate(
                { companyId: employee.companyId, inventory: form },
                {
                    onError: error => {
                        if (error instanceof Error) {
                            snackBar.showSnackBar(error.message, "error");
                            setDisableButton(true);
                        }
                    },
                    onSuccess: data => {
                        snackBar.showSnackBar("Inventory Created", "success");
                        navigate(`/companies/${employee.companyId}/inventories/${data.id}`);
                    },
                },
            );
        }
    };

    return (
        <NewInventoryPageWrapper>
            {companyQuery.isLoading || companyQuery.data == undefined ? (
                <>
                    <Loader />
                </>
            ) : companyQuery.isError ? (
                <>
                    <p>Error</p>
                </>
            ) : (
                <FormWrapper>
                    <SoecoHeader>{companyQuery.data.name}</SoecoHeader>
                    <Form noValidate autoComplete="off">
                        <TextField
                            label="Inventory name"
                            name="name"
                            onChange={handleChange}
                            variant="outlined"
                            required
                            error={error && errors.name != undefined}
                            helperText={error && errors.name}
                        />
                        <TextField
                            label="Project"
                            name="project"
                            onChange={handleChange}
                            variant="outlined"
                        />
                        <TextField
                            label="Customer"
                            name="customerId"
                            onChange={handleChange}
                            variant="outlined"
                        />
                        <TextField
                            label="Estimated delivery date"
                            name="estimatedDeliveryDate"
                            onChange={handleChange}
                            variant="outlined"
                            required
                            error={error && errors.estimatedDeliveryDate != undefined}
                            helperText={error && errors.estimatedDeliveryDate}
                        />
                        <TextField
                            label="Description"
                            name="description"
                            onChange={handleChange}
                            rows={4}
                            multiline
                        />
                    </Form>
                </FormWrapper>
            )}
            <ActionButtons>
                <SoecoButton fullWidth size="large" color="secondary" onClick={() => navigate(-1)}>
                    Cancel
                </SoecoButton>
                <SoecoButton
                    fullWidth
                    size="large"
                    type="submit"
                    color="primary"
                    onClick={handleSubmit}
                    disabled={mutation.isPending || disableButton}
                >
                    Create
                </SoecoButton>
            </ActionButtons>
        </NewInventoryPageWrapper>
    );
};
