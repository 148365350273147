import { useQuery } from "@tanstack/react-query";
import { Designer } from "../types";
import { get } from "../services/restClient";
import { Endpoint, setEndpoint } from "../services/endpoints";

const getDesigners = async () => {
    const data = await get<Designer[]>(setEndpoint(Endpoint.designers), { requireAuth: true });
    return data;
};

export const useGetDesigners = () => {
    return useQuery({queryKey: ["designers"], queryFn: () => getDesigners()});
};
