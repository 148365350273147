import { useCallback, useEffect, useState } from "react";
import styled from "styled-components";
import { ErrorRecord, Product } from "../../types";
import { SoecoImageList } from "./SoecoImageList";
import CameraAltIcon from "@mui/icons-material/CameraAlt";
import Cropper, { Area, Point } from "react-easy-crop";
import React from "react";
import { getCroppedImg, readFile } from "../../utils/util";
import { SoecoButton } from "../../components/SoecoButton";
import Div100vh from "react-div-100vh";

type Props = {
  images: File[];
  setImages: (images: File[]) => void;
  imageUrls: string[];
  setImageUrls: (imageUrls: string[]) => void;
  error: boolean;
  errors: ErrorRecord<Product>;
};

export const CameraForm = (props: Props) => {
  const [imageSrc, setImageSrc] = React.useState("");
  const [crop, setCrop] = useState<Point>({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const rotation = 0;
  const [croppedAreaPixels, setCroppedAreaPixels] = useState({} as Area);
  const [croppedImage, setCroppedImage] = useState("");

  useEffect(() => {
    if (props.images == null || props.images.length < 0) return;
    const newImageUrls: string[] = [];
    Array.from(props.images).forEach((image: File) => {
      newImageUrls.push(URL.createObjectURL(image));
    });
    props.setImageUrls(newImageUrls);
  }, [props.images]);

  useEffect(() => {
    // iPhone images from an iPhone 14 Pro Max is still very large even with compression
    // What is the image format that is used by the iPhone?
    // Is smaller versions of the image created in the backend?
    const img = new Image();
    img.src = croppedImage;
    img.onload = () => {
      URL.revokeObjectURL(img.src);
      const canvas = document.createElement("canvas");
      canvas.width = img.width;
      canvas.height = img.height;
      const ctx = canvas.getContext("2d");
      ctx && ctx.drawImage(img, 0, 0);
      canvas.toBlob(
        blob => {
          props.setImages([...props.images, blob as File]);
        },
        "image/jpeg",
        0.7,
      );
    };
    setZoom(1);
    setCrop({ x: 0, y: 0 });
    setImageSrc("");
  }, [croppedImage]);

  const onCropComplete = useCallback((croppedArea: Area, croppedAreaPixels: Area) => {
    setCroppedAreaPixels(croppedAreaPixels);
  }, []);

  const showCroppedImage = useCallback(() => {
    getCroppedImg(imageSrc, croppedAreaPixels, rotation)
      .then(croppedImage => {
        setCroppedImage(croppedImage);
      })
      .catch(e => {
        console.error(e);
      });
  }, [imageSrc, croppedAreaPixels, rotation]);

  const onClose = useCallback(() => {
    setCroppedImage("");
    setImageSrc("");
  }, []);

  const onFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files.length > 0) {
      const file: File = e.target.files[0];
      readFile(file, (result: string) => {
        setImageSrc(result);
      });
    }
  };

  return (
    <CameraInputWrapper>
      {imageSrc != "" ? (
        <>
          <CropContainerWrapper>
            <Cropper
              image={imageSrc}
              crop={crop}
              zoom={zoom}
              aspect={4 / 3}
              onCropChange={setCrop}
              onCropComplete={onCropComplete}
              onZoomChange={setZoom}
            />
          </CropContainerWrapper>
          <ButtonWrapper>
            <SoecoButton fullWidth size="large" color="secondary" onClick={onClose}>
              Cancel
            </SoecoButton>
            <SoecoButton fullWidth size="large" onClick={showCroppedImage}>
              Save
            </SoecoButton>
          </ButtonWrapper>
        </>
      ) : (
        <>
          <StyledLabel htmlFor="upload-image-button">
            Upload Image
            <CameraAltIcon color="primary" fontSize="large" />
          </StyledLabel>
          <StyledInput
            type="file"
            id="upload-image-button"
            name="image"
            accept="image/*"
            capture="environment"
            onChange={onFileChange}
          />

          {props.error ? <p>{props.errors.imageUrls}</p> : null}
          <SoecoImageList
            images={props.images}
            setImages={props.setImages}
            imageUrls={props.imageUrls}
            setImageUrls={props.setImageUrls}
            error={props.error}
            errors={props.errors}
          />
        </>
      )}
    </CameraInputWrapper>
  );
};

const CameraInputWrapper = styled(Div100vh)`
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
`;

const ButtonWrapper = styled.div`
    position: absolute;
    display: flex;
    width: 95%;
    gap: 1rem;
    bottom: 0;
    z-index: 1;
`;

const StyledInput = styled.input`
    display: none;
`;

const StyledLabel = styled.label`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 1rem;
`;

const CropContainerWrapper = styled.div`
    display: flex;
`;
