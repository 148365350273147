import { useQuery } from "@tanstack/react-query";
import { Manufacturer } from "../types";
import { get } from "../services/restClient";
import { Endpoint, setEndpoint } from "../services/endpoints";

const getManufacturers = async () => {
    const data = await get<Manufacturer[]>(setEndpoint(Endpoint.manufacturers), {
        requireAuth: true,
    });
    return data;
};

export const useGetManufacturers = () => {
    return useQuery({queryKey: ["manufacturers"], queryFn: () => getManufacturers()});
};
