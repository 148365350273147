import { useEffect, useState } from "react";
import Div100vh from "react-div-100vh";
import { useNavigate, useParams } from "react-router-dom";
import styled from "styled-components";
import { v4 as uuidv4 } from 'uuid';
import { SoecoButton } from "../../components/SoecoButton";
import { useSnackBar } from "../../context/SnackbarContext";
import { useGetDesigners } from "../../hooks/designerHooks";
import { useGetManufacturers } from "../../hooks/manufacturerHooks";
import { useAddProduct, useGetProduct } from "../../hooks/productHooks";
import { useGetProductStatuses } from "../../hooks/productStatusHooks";
import { queryClient } from "../../hooks/queryClient";
import { useAppLocation } from "../../hooks/useAppLocation";
import { imagesUrl } from "../../services/endpoints";
import { ErrorRecord, OptionsArray, OptionsObject, Product, ProductCategory } from "../../types";
import { createReadOnlyOptions, createReadOnlySortedOptions } from "../../utils/util";
import { CameraForm } from "./CameraForm";
import { DetailsForm } from "./DetailsForm";
import { FactoryForm } from "./FactoryForm";
import { PriceForm } from "./PriceForm";
import { ProductCategoryForm } from "./ProductCategoryForm";
import { ProductOverview } from "./ProductOverview";
import { SoecoStepper } from "./SoecoStepper";
import { validateNewProduct } from "./validateNewProduct";
import { PredictProduct, ProductOptions } from './PredictProduct'
import { useGetProductCategories } from "../../hooks/productCategoryHooks";

export const NewProduct = () => {
  const { companyId, inventoryId } = useParams();
  const mutation = useAddProduct();
  const navigate = useNavigate();
  const { state } = useAppLocation();
  const snackBar = useSnackBar();

  const productQuery = useGetProduct(companyId ?? "", inventoryId ?? "", state?.productId ?? "");
  const { data: manufacturers } = useGetManufacturers();
  const { data: designers } = useGetDesigners();
  const { data: statuses } = useGetProductStatuses();
  const { data: productCategories } = useGetProductCategories();

  const [step, setStep] = useState(0);
  const [error, setError] = useState<boolean>(false);
  const [errors, setErrors] = useState<ErrorRecord<Product>>({});

  const [completed, setCompleted] = useState<{
    [k: number]: boolean;
  }>({});

  const [images, setImages] = useState<File[]>([]);
  const [imageUrls, setImageUrls] = useState<string[]>([]);

  const [manufacturer, setManufacturer] = useState<OptionsObject | null>(null);
  const [designer, setDesigner] = useState<OptionsObject | null>(null);
  const [status, setStatus] = useState<OptionsObject | null>(null);

  const [manufacturersOptions, setManufacturersOptions] = useState<OptionsArray>([]);
  const [designersOptions, setDesignersOptions] = useState<OptionsArray>([]);
  const [statusesOptions, setStatusesOptions] = useState<OptionsArray>([]);

  const [product, setProduct] = useState<Product>({
    companyId: companyId ?? "",
    inventoryId: inventoryId ?? "",
    name: "",
    id: uuidv4(),
    categories: [],
    imageUrls: [],
    netPrice: "",
    purchasePrice: "",
    marketPrice: "",
    color: "",
    measurements: "",
    comment: "",
    manufacturer: "",
    designer: "",
    status: "",
    warehousePlacement: "",
    roomPlacement: "",
    quantity: 1,
    predictions: [],
    predictionUsed: ""
  });

  useEffect(() => {
    if (!productQuery.isError && productQuery.data) {
      setProduct({
        companyId: productQuery.data.companyId,
        inventoryId: productQuery.data.inventoryId,
        name: productQuery.data.name ?? "",
        id: uuidv4(),
        categories: productQuery.data.categories,
        imageUrls: [],
        netPrice: productQuery.data.netPrice ?? "",
        purchasePrice: productQuery.data.purchasePrice ?? "",
        marketPrice: productQuery.data.marketPrice ?? "",
        color: productQuery.data.color ?? "",
        measurements: productQuery.data.measurements ?? "",
        comment: productQuery.data.comment ?? "",
        manufacturer: productQuery.data.manufacturer ?? "",
        designer: productQuery.data.designer ?? "",
        status: productQuery.data.status ?? "",
        warehousePlacement: "",
        roomPlacement: "",
        quantity: 1,
        predictions: [],
        predictionUsed: ""
      });
      // Fetch existing images and create new image urls
      productQuery.data.imageUrls?.forEach(url => {
        fetch(imagesUrl + url)
          .then(res => res.blob())
          .then(blob => {
            const file = new File([blob], url, { type: blob.type });
            setImages(prev => [...prev, file]);
            setImageUrls(prev => [...prev, URL.createObjectURL(file)]);
          })
          .catch(error => {
            if (error instanceof Error) {
              snackBar.showSnackBar(error.message, "error");
            }
          });
        setStep(6);
      });
    }
  }, [productQuery.data]);

  // Create readonly options for manufacturers, designers and statuses
  useEffect(() => {
    if (manufacturers) {
      const options = createReadOnlySortedOptions(manufacturers);
      setManufacturersOptions(options);
    }
    if (designers) {
      const options = createReadOnlySortedOptions(designers);
      setDesignersOptions(options);
    }
    if (statuses) {
      const options = createReadOnlyOptions(statuses);
      setStatusesOptions(options);
    }
  }, [manufacturers, designers, statuses]);

  // Map existing manufacturer, designer and status to form
  useEffect(() => {
    if (!productQuery.isError && productQuery.data) {
      if (productQuery.data.manufacturer) {
        const manufacturer = manufacturersOptions.find(m => m.id === product.manufacturer);
        handleSelectChange(manufacturer ?? null, "manufacturer");
      }
      if (productQuery.data.manufacturer) {
        const designer = designersOptions.find(d => d.id === product.designer);
        handleSelectChange(designer ?? null, "designer");
      }
      if (productQuery.data.manufacturer) {
        const status = statusesOptions.find(s => s.id === product.status);
        handleSelectChange(status ?? null, "status");
      }
    }
  }, [productQuery.data, manufacturersOptions, designersOptions, statusesOptions]);

  useEffect(() => {
    setError(false);
    setErrors({});
    setCompleted({ ...completed, [step]: false });
  }, [images, imageUrls, product, step]);

  const getManufacturerLabel = (idOrLabel: string) => {
    const manufacturer = manufacturersOptions.find(m => m.label === idOrLabel || m.id === idOrLabel);
    return manufacturer?.label || ""
  }

  const getDesignerLabel = (idOrLabel: string) => {
    const designer = designersOptions.find(d => d.label === idOrLabel || d.id === idOrLabel);
    return designer?.label || ""
  }

  const setOptions = (newProduct: ProductOptions) => {
    const newOptions = {
      ...newProduct
    }

    if (newProduct.manufacturer) {
      const manufacturer = manufacturersOptions.find(m => m.label === newProduct.manufacturer || m.id === newProduct.manufacturer);
      updateOptions(manufacturer ?? null, "manufacturer");
      newOptions.manufacturer = manufacturer?.id
    }
    if (newProduct.designer) {
      const designer = designersOptions.find(d => d.label === newProduct.designer || d.id === newProduct.designer);
      updateOptions(designer ?? null, "designer");
      newOptions.designer = designer?.id
    }

    //Replace category names with id's
    const newCategories: string[] = []
    if (newProduct.categories && newProduct.categories.length > 0) {

      for (const category of newProduct.categories) {
        const pc = productCategories?.find(c => c.id === category || c.name === category || c.soecoId === category)
        if (pc) {
          newCategories.push(pc.id)
        }
      }
      newOptions.categories = newCategories
    }
    return newOptions
  }

  const updateOptions = (value: OptionsObject | null, targetField: string) => {
    value && targetField === "manufacturer" && setManufacturer(value);
    value && targetField === "designer" && setDesigner(value);
  };


  const validateStep = (e: React.FormEvent<Element>) => {
    e.preventDefault();
    validateNewProduct(
      product,
      images,
      setErrors,
      setError,
      step,
      setStep,
      completed,
      setCompleted,
    );
  };

  const handleSubmit = (e: React.FormEvent<Element>) => {
    e.preventDefault();
    if (!companyId || !inventoryId) {
      // This cannot happen unless routes has been invalidated
      return;
    }

    mutation.mutate(
      { companyId, inventoryId, product, images },
      {
        onError: error => {
          if (error instanceof Error) {
            snackBar.showSnackBar(error.message, "error");
          }
          return;
        },
        onSuccess: () => {
          snackBar.showSnackBar("Product created", "success");
          navigate(`/companies/${companyId}/inventories/${inventoryId}`);
          return;
        }
      },
    );

    if (!navigator.onLine) {

      // Optimistically update to the new value
      const newProductList: Product[] = [...queryClient.getQueryData(['products', inventoryId]) as Product[], product] as Product[];
      queryClient.setQueryData(['products', inventoryId], newProductList)

      snackBar.showSnackBar(
        "No internet: Product will be added when online",
        "success",
      );
      navigate(`/companies/${companyId}/inventories/${inventoryId}`);
    }
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setProduct({ ...product, [e.target.name]: e.target.value });
  };

  const handleSelectChange = (value: OptionsObject | null, targetField: string) => {
    value && setProduct({ ...product, [targetField]: value.id });
    value && targetField === "manufacturer" && setManufacturer(value);
    value && targetField === "designer" && setDesigner(value);
    value && targetField === "status" && setStatus(value);
  };

  const handleProductCategoryChange = (data: ProductCategory) => {
    // Remove if already in list
    if (product.categories?.includes(data.id)) {
      setProduct({
        ...product,
        categories: product.categories.filter(category => category !== data.id),
      });
      // Add if not in list
    } else {
      setProduct({
        ...product,
        categories: [...(product.categories ?? []), data.id],
      });
    }
  };

  return (
    <NewProductPageWrapper>
      <NewProductWrapper>
        {step !== 6 ? (
          <SoecoStepper
            step={step}
            completed={completed}
            setCompleted={setCompleted}
            error={error}
          />
        ) : null}

        <FormWrapper>
          {step == 0 ? (
            <CameraForm
              images={images}
              setImages={setImages}
              imageUrls={imageUrls}
              setImageUrls={setImageUrls}
              error={error}
              errors={errors}
            />
          ) : step == 1 ? (
            <PredictProduct
              images={images}
              companyId={companyId ?? ''}
              inventoryId={inventoryId ?? ''}
              product={product}
              setProduct={setProduct}
              setOptions={setOptions}
              getManufacturerLabel={getManufacturerLabel}
              getDesignerLabel={getDesignerLabel}
            />
          ) : step == 2 ? (
            <ProductCategoryForm
            product={product} 
            setProduct={setProduct} 
            handleProductCategoryChange={handleProductCategoryChange} 
            productCategories={productCategories || []} />
          ) : step == 3 ? (
            <DetailsForm
              product={product}
              manufacturer={manufacturer}
              designer={designer}
              status={status}
              manufacturersOptions={manufacturersOptions}
              designersOptions={designersOptions}
              statusesOptions={statusesOptions}
              handleChange={handleChange}
              handleSelectChange={handleSelectChange}
              error={error}
              errors={errors}
            />
          ) : step == 4 ? (
            <PriceForm
              product={product}
              handleChange={handleChange}
              error={error}
              errors={errors}
            />
          ) : step == 5 ? (
            <FactoryForm
              product={product}
              handleChange={handleChange}
              error={error}
              errors={errors}
            />
          ) : step == 6 ? (
            <ProductOverview
              product={product}
              manufacturer={manufacturer}
              designer={designer}
              status={status}
              imageUrls={imageUrls}
              step={step}
              setStep={setStep}
            />
          ) : null}
        </FormWrapper>

        <ActionButtons>
          {step == 0 ? (
            <SoecoButton
              fullWidth
              size="large"
              color="secondary"
              onClick={() => {
                navigate(-1);
              }}
            >
              Cancel
            </SoecoButton>
          ) : (
            <SoecoButton
              fullWidth
              size="large"
              color="secondary"
              onClick={() => {
                setStep(step - 1);
              }}
            >
              Back
            </SoecoButton>
          )}
          {/* <CircularProgressWithLabel value={step * 25} /> */}
          {step == 6 ? (
            <SoecoButton
              fullWidth
              size="large"
              type="submit"
              color="primary"
              onClick={(e: React.FormEvent<Element>) => handleSubmit(e)}
              disabled={error || mutation.isPending}
            >
              Save
            </SoecoButton>
          ) : (
            <SoecoButton
              form="partial-submit-form"
              type="submit"
              fullWidth
              size="large"
              disabled={error || mutation.isPending}
              onClick={validateStep}
              onSubmit={validateStep}
            >
              Next
            </SoecoButton>
          )}
        </ActionButtons>
      </NewProductWrapper>
    </NewProductPageWrapper>
  );
};

const NewProductPageWrapper = styled(Div100vh)`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
`;

const NewProductWrapper = styled.div`
    display: flex;
    flex-direction: column;
    width: 95%;
    height: 100%;
    justify-content: space-between;
    gap: 2rem;
`;

const FormWrapper = styled.div`
    display: flex;
    flex-direction: column;
    height: 100%;
    max-height: 80%;
    overflow: scroll;
`;

export const ActionButtons = styled.div`
    display: flex;
    width: 95%;
    gap: 2rem;
    bottom: 0;
`;
