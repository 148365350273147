import { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import { logout } from "../../services/authService";
import { SoecoButton } from "../../components/SoecoButton";
import { EmployeeInfo } from "./EmployeeInfo";
import { IconButton, InputAdornment, TextField } from "@mui/material";
import { useGetEmployee, useUpdatePassword } from "../../hooks/employeeHooks";
import { EmployeeContext } from "../../context/EmployeeContext";
import { useGetCompany } from "../../hooks/companyHooks";
import { useSnackBar } from "../../context/SnackbarContext";
import { ErrorRecord, UpdatePasswordForm } from "../../types";
import { isValidPassword } from "../../utils/util";
import styled from "styled-components";
import Div100vh from "react-div-100vh";
import { Visibility, VisibilityOff } from "@mui/icons-material";

export const Account = () => {
    const [form, setForm] = useState<UpdatePasswordForm>({
        currentPassword: "",
        newPassword: "",
    });

    const [error, setError] = useState<boolean>(false);
    const [errors, setErrors] = useState<ErrorRecord<UpdatePasswordForm>>({});
    const navigate = useNavigate();

    const { employee } = useContext(EmployeeContext);
    const employeeQuery = useGetEmployee(employee.companyId, employee.id);
    const companyQuery = useGetCompany(employee.companyId);
    const mutation = useUpdatePassword();
    const snackBar = useSnackBar();

    const [showPassword, setShowPassword] = useState<{ current: boolean; new: boolean }>({
        current: false,
        new: false,
    });

    const isValidForm = () => {
        let valid = true;
        const newErrors: ErrorRecord<UpdatePasswordForm> = {};

        if (form.currentPassword === form.newPassword) {
            valid = false;
            newErrors.newPassword = "New password must be different from current password";
        }
        if (!isValidPassword(form.newPassword)) {
            valid = false;
            newErrors.newPassword =
                "Password must be at least 8 characters long, contain at least one uppercase letter, one lowercase letter, one number and one special character";
        }
        if (!valid) {
            setErrors(newErrors);
            setError(true);
            return false;
        } else {
            setErrors({});
            setError(false);
            return true;
        }
    };

    const handleClickShowPassword = (target: string) => {
        target === "current"
            ? setShowPassword({ ...showPassword, current: !showPassword.current })
            : setShowPassword({ ...showPassword, new: !showPassword.new });
    };

    const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
        event.preventDefault();
    };

    const handleSubmit = (event: React.FormEvent) => {
        event.preventDefault();

        if (!isValidForm()) {
            return;
        }

        mutation.mutate(
            {
                newPassword: form.newPassword,
                currentPassword: form.currentPassword,
            },
            {
                onSuccess: () => {
                    snackBar.showSnackBar("Password updated successfully", "success");
                    logout();
                },
                onError: error => {
                    if (error instanceof Error) {
                        setErrors({ currentPassword: error.message });
                        setError(true);
                    }
                },
            },
        );
    };

    const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        setForm({ ...form, [e.target.name]: e.target.value });
        setError(false);
        setErrors({});
    };

    return (
        <AccountPageWrapper>
            <EmployeeInfo employee={employeeQuery?.data} company={companyQuery?.data} />
            <Form noValidate autoComplete="off">
                <FormFields>
                    <TextField
                        label="Current Password"
                        name="currentPassword"
                        type={showPassword.current ? "text" : "password"}
                        variant="outlined"
                        onChange={handleChange}
                        error={!!errors.currentPassword}
                        helperText={errors.currentPassword}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <IconButton
                                        name="current"
                                        aria-label="toggle password visibility"
                                        onClick={() => handleClickShowPassword("current")}
                                        onMouseDown={handleMouseDownPassword}
                                        edge="end"
                                    >
                                        {showPassword.current ? <VisibilityOff /> : <Visibility />}
                                    </IconButton>
                                </InputAdornment>
                            ),
                        }}
                    />
                    <TextField
                        label="New Password"
                        name="newPassword"
                        type={showPassword.new ? "text" : "password"}
                        variant="outlined"
                        onChange={handleChange}
                        error={!!errors.newPassword}
                        helperText={errors.newPassword}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <IconButton
                                        aria-label="toggle password visibility"
                                        onClick={() => handleClickShowPassword("new")}
                                        onMouseDown={handleMouseDownPassword}
                                        edge="end"
                                    >
                                        {showPassword.new ? <VisibilityOff /> : <Visibility />}
                                    </IconButton>
                                </InputAdornment>
                            ),
                        }}
                    />
                </FormFields>
                <ActionButtons>
                    <SoecoButton
                        type="button"
                        fullWidth
                        color="secondary"
                        onClick={() => navigate(-1)}
                    >
                        Back
                    </SoecoButton>
                    <SoecoButton
                        fullWidth
                        color="primary"
                        type="submit"
                        onClick={handleSubmit}
                        disabled={error}
                    >
                        Submit
                    </SoecoButton>
                </ActionButtons>
            </Form>
        </AccountPageWrapper>
    );
};

const AccountPageWrapper = styled(Div100vh)`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 1rem;
`;

const Form = styled.form`
    display: flex;
    flex-direction: column;
    width: 80%;
    justify-content: center;
    aling-content: center;
    gap: 10px;
`;

const FormFields = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 12px;
`;

const ActionButtons = styled.div`
    display: flex;
    justify-content: space-between;
    gap: 10px;
`;
