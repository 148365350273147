import { DialogContentText, DialogActions, Snackbar } from "@mui/material";
import React, { FunctionComponent, useState } from "react";
import { SoecoButton } from "../SoecoButton";
import PrintIcon from "@mui/icons-material/Print";
import styled from "styled-components";
//import { generateGS1QRCode } from "../../utils/qrCode";
import { QrCode } from "../QRCode";
import { generateQrCodeAsZpl } from "../../services/qrCode";
import { DeviceList } from "../PrintDevicesList";
import { Device } from "zebra-browser-print-wrapper/lib/types";
import {
    printLabels,
    useGetDeviceStatus,
    setPrinter,
} from "../../hooks/printerHooks";
import { TextField } from "@mui/material";
import { Product } from "../../types";
import { useSnackBar } from "../../context/SnackbarContext";

interface PrintModalProps {
    onConfirm: () => void;
    onCancel: () => void;
    disabled?: boolean;
    printUrl: string;
    product: Product;
}

export const PrintModal: FunctionComponent<PrintModalProps> = props => {
    const label: string = generateQrCodeAsZpl(props.printUrl, props.product.name);

    const [selectedDevice, setSelectedDevice] = useState<Device | null>(null);
    const [quantity, setQuantity] = useState<number>(props.product.quantity);

    const snackBar = useSnackBar();

    const isIphone = /iPhone/.test(navigator.userAgent)

    //Removing statuses for now, seems they are useless half the time
    // const { data: status, isLoading: statusLoading, refetch: statusRefetch } = useGetDeviceStatus();

    const selectDevice = (device: Device) => {
        setSelectedDevice(device);
        setPrinter(device);
        // void statusRefetch();
    };

    const handlePrintClick = () => {
        try {
            if (selectedDevice && quantity > 0) {
                void printLabels(label, quantity, selectedDevice);
                snackBar.showSnackBar('Print started using PrintConnect', 'success')
            }
        } catch (error) {
            console.error("Error printing QR:", error);
            snackBar.showSnackBar('Print error', 'error')

        }
    };

    const handleQuantityChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setQuantity(parseInt(event.target.value));
    };

    const handleBackClick = () => {
        props.onCancel();
    };

    const handleIPhonePrintClick = () => {
        printMobi()
    }

    function formatFileName(str: string): string {
        str = str.toLowerCase();
        str = str.replace(/\s+/g, '-');
        str = str.replace(/[^a-z0-9-]/g, '');

        return str;
    }

    const download = () => {

        const blob = new Blob([label], { type: 'text/plain' });

        const url = window.URL.createObjectURL(blob);

        const fileName = formatFileName(props.product.name as string) || "product-label"

        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `${fileName}.zpl`);

        document.body.appendChild(link);
        link.click();

        window.URL.revokeObjectURL(url);
        document.body.removeChild(link);

        snackBar.showSnackBar('Label downloading!', "success");
    };

    const handleDownloadClick = () => {
        download()
    }

    const printMobi = () => {
        let convertedLabel = btoa(label)
        convertedLabel = convertedLabel.replace(/=/g,'_')
        window.location.href= 'arrowhead://x-callback-url/zplcode?code='+convertedLabel
        snackBar.showSnackBar('Print started using MobiPrint', 'success')
    }

    return (
        <React.Fragment>
            <PrintModalWrapper>
                {!isIphone && <HelpText>Android device detected. Make sure PrintConnect is installed and running.</HelpText>}
                {isIphone && <HelpText>iPhone device detected. Make sure MobiPrint is installed and running.</HelpText>}
                
                {!isIphone && <DeviceList selectedDevice={selectedDevice} setSelectedDevice={selectDevice} />}
                
                {/* {status?.errors && selectedDevice && (
                    <p>Printer {status.errors}</p>
                )} */}

                {!isIphone && <TextField
                    type="number"
                    value={quantity}
                    onChange={handleQuantityChange}
                    inputProps={{ min: 1 }}
                />}
                <StyledActionsContainer>
                    <StyledDialogActions>
                        <SoecoButton
                            fullWidth
                            size="medium"
                            color="secondary"
                            onClick={() => handleBackClick()}
                        >
                            Back
                        </SoecoButton>
                        <SoecoButton
                            fullWidth
                            size="medium"
                            color="primary"
                            onClick={() => handleDownloadClick()}
                        >
                            Download
                        </SoecoButton>
                    </StyledDialogActions>
                    <StyledDialogActions>
                        <SoecoButton
                            disabled={isIphone ? false : !selectedDevice}
                            fullWidth
                            size="medium"
                            color="primary"
                            startIcon={<PrintIcon />}
                            onClick={() => isIphone ? handleIPhonePrintClick() : handlePrintClick()}
                        >
                            Print
                        </SoecoButton>
                    </StyledDialogActions>
                </StyledActionsContainer>
            </PrintModalWrapper>
        </React.Fragment>
    );
};

const PrintModalWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    gap: 1rem;
`;

const StyledActionsContainer = styled.div`
    width: 100%;
`

const StyledDialogActions = styled(DialogActions)`
    display: flex;
    width: 100%;
`;

const HelpText = styled.p`
    font-weight: 200;
    font-style: italic;
`