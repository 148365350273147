import { Navigate, useNavigate } from "react-router-dom";
import styled from "styled-components";
import { isGlobalAdmin } from "../../utils/util";
import { useParams } from "react-router-dom";
import { ManageSubCompaniesActions } from "./ManageSubCompaniesActions";
import { ManageSubCompaniesList } from "./ManageSubCompaniesList";
import { useGetCompanies, useGetSubCompanies } from "../../hooks/companyHooks";
import { AdminCompanyFilter } from "../../components/AdminCompanyFilter";
import { SoecoHeader } from "../../components/SoecoHeader";
import { useContext } from "react";
import { EmployeeContext } from "../../context/EmployeeContext";

export const ManageSubCompaniesPage = () => {
    const { employee } = useContext(EmployeeContext);
    const navigate = useNavigate();
    const { companyId } = useParams();
    
    if (!isGlobalAdmin || !companyId) {
        return <Navigate to="/404" />;
    }

    const { data: companies } = useGetSubCompanies(companyId)

    const onChange = (idToNavigate: string) => {
        navigate(`/companies/${idToNavigate}/manageSubCompanies`);
    };

    return (
        <ManageCompaniesPageWrapper>
            <SoecoHeader>Manage Sub-Companies</SoecoHeader>
            {isGlobalAdmin(employee) && (
                <AdminCompanyFilter currentCompanyId={companyId} onChange={onChange} />
            )}
            <ManageCompaniesListWrapper>
                <ManageSubCompaniesList companies={companies ?? []} />
            </ManageCompaniesListWrapper>
            {companyId && <ManageSubCompaniesActions currentCompanyId={companyId} />}
        </ManageCompaniesPageWrapper>
    );
};

const ManageCompaniesPageWrapper = styled.div`
    display: flex;
    flex-direction: column;
    height: 80vh;
    align-items: center;
    gap: 2rem;
`;

const ManageCompaniesListWrapper = styled.div`
    display: flex;
    height: 100%;
    width: 100%;
`;
