import LogoPNG from "../../assets/soecologoeng.png";
import styled from "styled-components";
import TextField from "@mui/material/TextField";
import { Link } from "react-router-dom";
import { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import { setAccessToken } from "../../services/authService";
import { SoecoButton } from "../../components/SoecoButton";
import { post } from "../../services/restClient";
import { EmployeeContext } from "../../context/EmployeeContext";
import { LoginForm, Role } from "../../types";
import { Endpoint, setEndpoint } from "../../services/endpoints";
import { useSnackBar } from "../../context/SnackbarContext";
import { FormControl } from "@mui/material";

export const LoginPage = () => {
    const [loginForm, setLoginForm] = useState<LoginForm>({
        email: "",
        password: "",
    });
    const employee = useContext(EmployeeContext);
    const navigate = useNavigate();
    const snackBar = useSnackBar();

    const handleLogin = (e: React.FormEvent<HTMLButtonElement>) => {
        e.preventDefault();
        type RequestBody = {
            email: string;
            password: string;
        };

        type ResponseBody = RequestBody & {
            accessToken: string;
        };
        post<RequestBody, ResponseBody>(setEndpoint(Endpoint.login), {
            email: loginForm.email.toLowerCase(),
            password: loginForm.password,
        })
            .then(res => {
                const token = setAccessToken(res.accessToken);
                employee.setEmployee({
                    companyId: token.companyId,
                    id: token.employeeId,
                    role: token.role as Role,
                });
                navigate("/");
            })
            .catch(error => {
                if (error instanceof Error) {
                    snackBar.showSnackBar(error.message, "error");
                }
            });
    };

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setLoginForm({
            ...loginForm,
            [e.target.name]: e.target.value,
        });
    };

    return (
        <LoginWrapper>
            <Form noValidate autoComplete="off">
                <div>
                    <Logo src={LogoPNG} />
                </div>
                <LoginFormWrapper>
                    <TextFields>
                        <TextField
                            label="Email"
                            name="email"
                            variant="outlined"
                            onChange={handleChange}
                        />
                        <TextField
                            label="Password"
                            name="password"
                            type="password"
                            variant="outlined"
                            onChange={handleChange}
                        />
                    </TextFields>
                    <SoecoButton color="primary" type="submit" onClick={e => handleLogin(e)}>
                        Login
                    </SoecoButton>
                </LoginFormWrapper>
            </Form>
            <ForgotPasswordLink to="/forgotPassword">Forgot password</ForgotPasswordLink>
        </LoginWrapper>
    );
};

const LoginWrapper = styled.div`
    display: flex;
    flex-direction: column;
    text-align: center;
    align-items: center;
    gap: 20px;
`;

const Form = styled.form`
    > * {
        width: 25ch;
        :not(:last-child) {
            margin-bottom: 20px;
        }
    }
`;

const ForgotPasswordLink = styled(Link)`
    text-decoration: none;
    color: inherit;
    font-size: 11px;
`;

const Logo = styled.img`
    width: 100%;
    margin-bottom: 20px;
    margin-top: 50px;
`;

const LoginFormWrapper = styled(FormControl)`
    display: flex;
    flex-direction: column;
    gap: 20px;
`;

const TextFields = styled.div`
    display: flex;
    flex-direction: column;
    gap: 20px;
`;
