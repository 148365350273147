import TextField from "@mui/material/TextField";
import React from "react";
import { useState } from "react";
import { SoecoButton } from "../../components/SoecoButton";
import { post } from "../../services/restClient";
import styled from "styled-components";
import { setEndpoint, Endpoint } from "../../services/endpoints";
import { useSnackBar } from "../../context/SnackbarContext";
import { useNavigate } from "react-router-dom";

export const ForgotPassword = () => {
    const [email, setEmail] = useState("");
    const navigate = useNavigate();
    const snackBar = useSnackBar();

    const handleResetPassword = (event: React.FormEvent) => {
        event.preventDefault();

        type RequestBody = {
            email: string;
        };
        type ResponseBody = RequestBody & {
            message: string;
        };

        post<RequestBody, ResponseBody>(
            setEndpoint(Endpoint.resetPassword),
            {
                email,
            },
            { requireAuth: false },
        )
            .then(() => {
                snackBar.showSnackBar(`Email sent to ${email}`, "success");
            })
            .catch(error => {
                if (error instanceof Error) {
                    snackBar.showSnackBar(error.message, "error");
                }
            });
    };

    return (
        <ForgotPasswordPageWrapper>
            <ForgotPasswordForm noValidate autoComplete="off">
                <TextField
                    label="E-mail"
                    variant="outlined"
                    onChange={e => {
                        setEmail(e.currentTarget.value);
                    }}
                />
                <ActionButtons>
                    <SoecoButton fullWidth color="secondary" onClick={() => navigate("/login")}>
                        Back
                    </SoecoButton>
                    <SoecoButton fullWidth color="primary" onClick={handleResetPassword}>
                        Send
                    </SoecoButton>
                </ActionButtons>
            </ForgotPasswordForm>
        </ForgotPasswordPageWrapper>
    );
};

const ForgotPasswordPageWrapper = styled.div`
    display: flex;
    flex-direction: column;
    height: 80vh;
    justify-content: center;
    align-items: center;
    gap: 10px;
`;

const ForgotPasswordForm = styled.form`
    display: flex;
    flex-direction: column;
    width 80%;
    gap: 10px;
`;

const ActionButtons = styled.div`
    display: flex;
    justify-content: center;
    gap: 10px;
`;
