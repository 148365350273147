import { TextField } from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";
import styled from "styled-components";
import { ErrorRecord, OptionsArray, OptionsObject, Product } from "../../types";
import Divider from "@mui/material/Divider";
import React from "react";

type Props = {
    changedProduct: Product;
    manufacturers: OptionsArray;
    manufacturer: OptionsObject | null;
    designers: OptionsArray;
    designer: OptionsObject | null;
    productStatuses: OptionsArray;
    productStatus: OptionsObject | null;
    handleChange: (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
    handleSelectChange: (
        e: React.SyntheticEvent<Element, Event>,
        value: OptionsObject | null,
        targetField: string,
    ) => void;
    error: boolean;
    errors: ErrorRecord<Product>;
};

export const EditDetails = (props: Props) => {
    return (
        <Form noValidate autoComplete="off">
            <TextField
                label="Name"
                name="name"
                variant="filled"
                required
                onChange={e => props.handleChange(e)}
                value={props.changedProduct.name}
                error={props.errors.name ? true : false}
                helperText={props.errors.name}
            />
            {props.manufacturers && (
                <Autocomplete
                    disablePortal
                    isOptionEqualToValue={(option, value) => option.label === value.label}
                    options={props.manufacturers}
                    onChange={(event, value) =>
                        props.handleSelectChange(event, value, "manufacturer")
                    }
                    renderInput={params => (
                        <TextField variant="filled" {...params} label="Manufacturer" />
                    )}
                    value={props.manufacturer}
                />
            )}
            {props.designers && (
                <Autocomplete
                    disablePortal
                    isOptionEqualToValue={(option, value) => option.label === value.label}
                    options={props.designers}
                    onChange={(event, value) => props.handleSelectChange(event, value, "designer")}
                    renderInput={params => (
                        <TextField variant="filled" {...params} label="Designer" />
                    )}
                    value={props.designer}
                />
            )}
            <Divider />
            <TextField
                label="Color"
                name="color"
                variant="filled"
                value={props.changedProduct.color}
                onChange={e => props.handleChange(e)}
            />
            <TextField
                label="Measurements"
                name="measurements"
                variant="filled"
                value={props.changedProduct.measurements}
                onChange={e => props.handleChange(e)}
            />
            {props.productStatuses && (
                <Autocomplete
                    disablePortal
                    isOptionEqualToValue={(option, value) => option.label === value.label}
                    options={props.productStatuses}
                    onChange={(event, value) => props.handleSelectChange(event, value, "status")}
                    renderInput={params => (
                        <TextField variant="filled" {...params} label="Status" />
                    )}
                    value={props.productStatus}
                />
            )}
            <TextField
                label="Comment"
                name="comment"
                variant="filled"
                value={props.changedProduct.comment}
                onChange={e => props.handleChange(e)}
            />
        </Form>
    );
};

const Form = styled.form`
    display: flex;
    flex-direction: column;
    align-content: center;
    width: 95%;
    margin: 0 auto;
    gap: 20px;
`;
