import {
    DataGrid,
    GridCellEditCommitParams,
    gridClasses,
    GridColDef,
    GridRowId,
    GridRowSpacingParams,
    GridRowsProp,
} from "@mui/x-data-grid";
import EditIcon from "@mui/icons-material/Edit";
import styled from "styled-components";
import { Product } from "../../types";
import { SoecoButton } from "../../components/SoecoButton";
import { createProductReport, useUpdateProduct } from "../../hooks/productHooks";
import { useNavigate } from "react-router-dom";
import { imagesUrl } from "../../services/endpoints";
import { useCallback, useState } from "react";
import { grey } from "@mui/material/colors";
import { CustomToolbar } from "../../components/CustomToolbar/CustomToolbar";
import { useSnackBar } from "../../context/SnackbarContext";
import { LoaderModal } from "../../components/Modals/LoaderModal";
import { AvatarWithFallback } from "../../components/AvatarWithFallback";
import { useModal } from "../../hooks/useModal";

type Props = {
    products: Product[];
    companyId: string;
    inventoryId: string;
    handleRowClick: (id: GridRowId) => void;
};

export const InventoryTable = (props: Props) => {
    const navigate = useNavigate();
    const mutation = useUpdateProduct();
    const snackBar = useSnackBar();
    const [isLoading, setIsLoading] = useState<boolean>(false);

    const handleCreateReport = () => {
        setIsLoading(true);
        createProductReport(props.companyId, props.inventoryId)
            .then(data => {
                const url = window.URL.createObjectURL(new Blob([data]));
                const link = document.createElement("a");
                link.href = url;
                link.setAttribute("download", `report${props.inventoryId}.xlsx`);
                // 3. Append to html page
                document.body.appendChild(link);
                // 4. Force download
                link.click();
                // 5. Clean up and remove the link
                link.remove();
            })
            .catch(error => {
                if (error instanceof Error) {
                    snackBar.showSnackBar("Failed to generate report", "error");
                }
            })
            .finally(() => {
                setIsLoading(false);
            });
    };

    const handleProductEdit = (id: GridRowId) => {
        navigate(`/companies/${props.companyId}/inventories/${props.inventoryId}/products/${id}`);
    };

    const handleProductQuantityChange = (params: GridCellEditCommitParams) => {
        const id = params.id as string;
        const quantity = params.value as number;
        mutation.mutate(
            {
                companyId: props.companyId,
                inventoryId: props.inventoryId,
                productId: id,
                product: {
                    companyId: props.companyId,
                    inventoryId: props.inventoryId,
                    id: id,
                    quantity: quantity,
                },
            },
            {
                onSuccess: () => {
                    snackBar.showSnackBar("Product quantity updated successfully", "success");
                },
                onError: error => {
                    if (error instanceof Error) {
                        snackBar.showSnackBar(error.message, "error");
                    }
                },
            },
        );
    };

    const getRowSpacing = useCallback((params: GridRowSpacingParams) => {
        return {
            top: params.isFirstVisible ? 0 : 5,
            bottom: params.isLastVisible ? 0 : 5,
        };
    }, []);

    const columns: GridColDef[] = [
        // Default columns shown in the table
        {
            field: "image",
            headerName: "Image",
            flex: 0.4,
            editable: false,
            renderCell: params => {
                return (
                    <AvatarWithFallback
                        src={imagesUrl + (params.value as string) + "small.jpg"}
                        fallbackSrc={imagesUrl + (params.value as string)}
                    />
                );
            },
        },
        {
            field: "name",
            headerName: "Name",
            flex: 0.9,
            editable: false,
            align: "left",
        },
        {
            field: "roomPlacement",
            headerName: "Room",
            flex: 0.5,
        },
        {
            field: "quantity",
            headerName: "Qty",
            flex: 0.2,
            editable: true,
            type: "number",
        },
        {
            field: "Actions",
            headerName: " ",
            flex: 0.2,
            align: "left",
            editable: false,
            renderCell: params => {
                return (
                    <TableActions>
                        <SoecoButton
                            icon={true}
                            size="small"
                            onClick={() => handleProductEdit(params.id)}
                        >
                            <EditIcon fontSize="inherit" />
                        </SoecoButton>
                    </TableActions>
                );
            },
        },
        // Default hidden columns
        {
            field: "companyId",
            headerName: "Company",
            flex: 0.2,
        },
        {
            field: "inventoryId",
            headerName: "Inventory",
            flex: 0.2,
        },
        {
            field: "id",
            headerName: "Id",
            flex: 0.2,
        },
        {
            field: "categories",
            headerName: "Categories",
            flex: 0.2,
        },
        {
            field: "manufacturer",
            headerName: "Manufacturer",
            flex: 0.2,
        },
        {
            field: "designer",
            headerName: "Designer",
            flex: 0.2,
        },
        {
            field: "color",
            headerName: "Color",
            flex: 0.2,
        },
        {
            field: "measurements",
            headerName: "Measurements",
            flex: 0.2,
        },
        {
            field: "status",
            headerName: "Status",
            flex: 0.2,
        },
        {
            field: "comment",
            headerName: "Comment",
            flex: 0.2,
        },
        {
            field: "netPrice",
            headerName: "Net Price",
            flex: 0.2,
        },
        {
            field: "purchasePrice",
            headerName: "Purchase Price",
            flex: 0.2,
        },
        {
            field: "marketPrice",
            headerName: "Market Price",
            flex: 0.2,
        },
        {
            field: "warehousePlacement",
            headerName: "Warehouse",
            flex: 0.2,
        },
    ];

    const rows: GridRowsProp = [
        ...props.products.map(product => ({
            image: product.imageUrls && product.imageUrls[0],
            companyId: product.companyId,
            inventoryId: product.inventoryId,
            id: product.id,
            name: product.name,
            categories: product.categories,
            manufacturer: product.manufacturer,
            designer: product.designer,
            color: product.color,
            measurements: product.measurements,
            status: product.status,
            comment: product.comment,
            netPrice: product.netPrice,
            purchasePrice: product.purchasePrice,
            marketPrice: product.marketPrice,
            warehousePlacement: product.warehousePlacement,
            roomPlacement: product.roomPlacement,
            quantity: product.quantity,
        })),
    ];

    return (
        <>
            {isLoading && <LoaderModal message="Downloading report" />}
            <DataTable
                disableColumnFilter
                disableDensitySelector
                components={{ Toolbar: CustomToolbar }}
                componentsProps={{
                    toolbar: {
                        showQuickFilter: true,
                        quickFilterProps: { debounceMs: 500 },
                        csvOptions: { allColumns: true, delimiter: ";" },
                        handleCreateReport: handleCreateReport,
                    },
                }}
                rows={rows}
                columns={columns}
                pageSize={100}
                rowsPerPageOptions={[100]}
                disableSelectionOnClick={false}
                onRowClick={params => {
                    props.handleRowClick(params.id);
                }}
                onCellEditCommit={params => {
                    handleProductQuantityChange(params);
                }}
                getRowHeight={() => "auto"}
                getRowSpacing={getRowSpacing}
                rowSpacingType="margin"
                sx={{
                    [`& .${gridClasses.row}`]: {
                        bgcolor: theme => (theme.palette.mode === "light" ? grey[200] : grey[900]),
                    },
                }}
                initialState={{
                    columns: {
                        columnVisibilityModel: {
                            id: false,
                            companyId: false,
                            inventoryId: false,
                            categories: false,
                            manufacturer: false,
                            designer: false,
                            color: false,
                            measurements: false,
                            status: false,
                            comment: false,
                            netPrice: false,
                            purchasePrice: false,
                            marketPrice: false,
                            warehousePlacement: false,
                        },
                    },
                }}
            />
        </>
    );
};

const DataTable = styled(DataGrid)`
    width: 100%;
`;

const TableActions = styled.div`
    display: flex;
    font-size: 6px;
    width: 10%;
`;
