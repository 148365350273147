import { Navigate } from "react-router-dom";
import styled from "styled-components";
import { isGlobalAdmin } from "../../utils/util";
import { ManageCompaniesActions } from "./ManageCompaniesActions";
import { ManageCompaniesList } from "./ManageCompaniesList";
import { useGetCompanies } from "../../hooks/companyHooks";
import { SoecoHeader } from "../../components/SoecoHeader";
import { useContext } from "react";
import { CompanyContext } from "../../context/CompanyContext";

export const ManageCompanies = () => {
    const { company } = useContext(CompanyContext);
    const { data: companies } = useGetCompanies();

    // TODO: It might be good to check if id is valid as well.
    if (!isGlobalAdmin) {
        return <Navigate to="/404" />;
    }

    return (
        <ManageCompaniesPageWrapper>
            <SoecoHeader>Manage Companies</SoecoHeader>
            <ManageCompaniesListWrapper>
                <ManageCompaniesList company={company} companies={companies ?? []} />
            </ManageCompaniesListWrapper>
            <ManageCompaniesActions />
        </ManageCompaniesPageWrapper>
    );
};

const ManageCompaniesPageWrapper = styled.div`
    display: flex;
    flex-direction: column;
    height: 80vh;
    align-items: center;
    gap: 2rem;
`;

const ManageCompaniesListWrapper = styled.div`
    display: flex;
    height: 100%;
    width: 100%;
`;
