import { useContext, useEffect, useState } from "react";
import TextField from "@mui/material/TextField";
import { SoecoButton } from "../../components/SoecoButton";
import { Loader } from "../../components/Loader";
import { ActionButtons, Form, FormWrapper, NewInventoryPageWrapper } from "./EditInventory.styled";
import { EmployeeContext } from "../../context/EmployeeContext";
import { useNavigate, useParams } from "react-router-dom";
import { useGetCompany } from "../../hooks/companyHooks";
import {
    EditInventory,
    useDeleteInventory,
    useGetInventory,
    useUpdateInventory,
} from "../../hooks/inventoryHooks";
import { SoecoHeader } from "../../components/SoecoHeader";
import { ErrorRecord } from "../../types";
import DeleteIcon from "@mui/icons-material/Delete";
import { useModal } from "../../hooks/useModal";
import { Modal } from "../../components/Modals/Modal";
import { ErrorModal } from "../../components/Modals/ErrorModal";
import { useSnackBar } from "../../context/SnackbarContext";

export const EditInventoryPage = () => {
    const { companyId, inventoryId } = useParams();
    const { data: inventoryData } = useGetInventory(companyId ?? "", inventoryId ?? "");
    const [form, setForm] = useState<EditInventory>({
        name: "",
        project: "",
        estimatedDeliveryDate: "",
        customerId: "",
        description: "",
    });
    const [error, setError] = useState<boolean>(false);
    const [errors, setErrors] = useState<ErrorRecord<EditInventory>>({});
    const [disableButton, setDisableButton] = useState<boolean>(false);

    const updateMutation = useUpdateInventory();
    const deleteMutation = useDeleteInventory();
    const { employee } = useContext(EmployeeContext);
    const companyQuery = useGetCompany(companyId ?? employee.companyId);
    const navigate = useNavigate();

    const { isShown, toggle } = useModal();

    const snackBar = useSnackBar();

    const onConfirm = () => {
        toggle();
        handleDeleteInventory();
    };
    const onCancel = () => toggle();

    useEffect(() => {
        if (inventoryData) {
            setForm({
                name: inventoryData.name ?? "",
                project: inventoryData.project ?? "",
                estimatedDeliveryDate: inventoryData.estimatedDeliveryDate?.slice(0, 10) ?? "",
                customerId: inventoryData.customerId ?? "",
                description: inventoryData.description ?? "",
            });
        }
    }, [inventoryData]);

    const handleDeleteInventory = () => {
        if (!companyId || !inventoryId) {
            return;
        }
        deleteMutation.mutate(
            { companyId, inventoryId },
            {
                onError: error => {
                    if (error instanceof Error) {
                        snackBar.showSnackBar(error.message, "error");
                    }
                },
                onSuccess: () => {
                    snackBar.showSnackBar("Inventory deleted", "success");
                    navigate(-1);
                },
            },
        );
    };

    const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        setForm({ ...form, [e.target.name]: e.target.value });
        setDisableButton(false);
        setError(false);
    };

    const isValidForm = () => {
        let valid = true;
        const newErrors: ErrorRecord<EditInventory> = {};
        if (form.name.length < 1) {
            valid = false;
            newErrors.name = "Name cannot be empty";
        }
        if (
            form.estimatedDeliveryDate.match(/^\d{4}-(0[1-9]|1[0-2])-(0[1-9]|[12][0-9]|3[01])$/) ==
            null
        ) {
            valid = false;
            newErrors.estimatedDeliveryDate = "Date must be written as YYYY-MM-DD";
        }
        if (!valid) {
            setErrors(newErrors);
            setError(true);
            setDisableButton(true);
            return false;
        } else {
            setErrors({});
            setError(false);
            return true;
        }
    };

    const handleSubmit = () => {
        if (isValidForm()) {
            if (!inventoryId || !companyId) {
                return;
            }
            updateMutation.mutate(
                {
                    companyId: companyId,
                    inventoryId: inventoryId,
                    inventory: form,
                },
                {
                    onError: error => {
                        if (error instanceof Error) {
                            snackBar.showSnackBar(error.message, "error");
                            setDisableButton(true);
                        }
                    },
                    onSuccess: () => {
                        snackBar.showSnackBar("Inventory updated", "success");
                        navigate(-1);
                    },
                },
            );
        }
    };

    return (
        <NewInventoryPageWrapper>
            <Modal
                isShown={isShown}
                hide={toggle}
                headerText="Delete the inventory and all products within?"
                modalContent={
                    <ErrorModal
                        onConfirm={onConfirm}
                        onCancel={onCancel}
                        message="You will not be able to recover it"
                    />
                }
            />
            {companyQuery.isLoading || companyQuery.data == undefined ? (
                <>
                    <Loader />
                </>
            ) : companyQuery.isError ? (
                <>
                    <p>Error</p>
                </>
            ) : (
                <FormWrapper>
                    <SoecoHeader>{companyQuery.data.name}</SoecoHeader>
                    <Form noValidate autoComplete="off">
                        <TextField
                            label="Inventory name"
                            name="name"
                            value={form.name}
                            onChange={handleChange}
                            variant="outlined"
                            required
                            error={error && errors.name != undefined}
                            helperText={error && errors.name}
                        />
                        <TextField
                            label="Project"
                            name="project"
                            value={form.project}
                            onChange={handleChange}
                            variant="outlined"
                        />
                        <TextField
                            label="Customer"
                            name="customerId"
                            value={form.customerId}
                            onChange={handleChange}
                            variant="outlined"
                        />
                        <TextField
                            label="Estimated delivery date"
                            name="estimatedDeliveryDate"
                            value={form.estimatedDeliveryDate}
                            onChange={handleChange}
                            variant="outlined"
                            required
                            error={error && errors.estimatedDeliveryDate != undefined}
                            helperText={error && errors.estimatedDeliveryDate}
                        />
                        <TextField
                            label="Description"
                            name="description"
                            value={form.description}
                            onChange={handleChange}
                            rows={4}
                            multiline
                        />
                    </Form>
                </FormWrapper>
            )}
            <ActionButtons>
                <SoecoButton color="secondary" onClick={toggle}>
                    <DeleteIcon fontSize="large" />
                </SoecoButton>
                <SoecoButton fullWidth size="large" color="secondary" onClick={() => navigate(-1)}>
                    Cancel
                </SoecoButton>
                <SoecoButton
                    fullWidth
                    size="large"
                    color="primary"
                    onClick={handleSubmit}
                    disabled={updateMutation.isPending || disableButton}
                >
                    Update
                </SoecoButton>
            </ActionButtons>
        </NewInventoryPageWrapper>
    );
};
