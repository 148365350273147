import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { SoecoButton } from "../../components/SoecoButton";

type Props = {
    currentCompanyId: string;
};

export const InventoryListActions = (props: Props) => {
    const navigate = useNavigate();

    return (
        <ActionButtons>
            <SoecoButton fullWidth size="large" color="secondary" onClick={() => navigate("/")}>
                Back
            </SoecoButton>
            <SoecoButton
                fullWidth
                size="large"
                onClick={() => {
                    navigate(`/companies/${props.currentCompanyId}/createInventory`);
                }}
                color="primary"
            >
                +
            </SoecoButton>
        </ActionButtons>
    );
};

const ActionButtons = styled.div`
    display: flex;
    width: 95%;
    gap: 2rem;
    position: fixed;
    bottom: 2rem;
`;
