import { useParams } from "react-router-dom";
import styled from "styled-components";
import { InventoryHeader } from "./InventoryHeader";
import { InventoryOptions } from "./InventoryOptions";
import { InventoryTable } from "./InventoryTable";
import { useGetProducts } from "../../hooks/productHooks";
import { Loader } from "../../components/Loader";
import { useGetInventory } from "../../hooks/inventoryHooks";
import { useState } from "react";
import { Product } from "../../types";
import { GridRowId } from "@mui/x-data-grid";
import { Modal } from "../../components/Modals/Modal";
import { PrintModal } from "../../components/Modals/PrintModal";
import { useModal } from "../../hooks/useModal";

export const InventoryPage = () => {
    const { companyId, inventoryId } = useParams();

    const inventoryQuery = useGetInventory(companyId ?? "", inventoryId ?? "");
    const productQuery = useGetProducts(companyId ?? "", inventoryId ?? "");

    const [selectedProduct, setSelectedProduct] = useState<Product | null>(null);
    const [printUrl, setPrintUrl] = useState<string | null>(null);

    const { isShown, toggle } = useModal();

    const onConfirm = () => {
        toggle();
    };
    const onCancel = () => toggle();

    const handleRowClick = (id: GridRowId) => {
        const products = productQuery.data;
        if (!products || !companyId || !inventoryId) return;

        const product = products.find((product: Product) => product.id === id);
        if (!product) return;
        setSelectedProduct(product);

        const url = `/companies/${companyId}/inventories/${inventoryId}/products/${id}`;
        setPrintUrl(url);
    };

    return (
        <>
            {selectedProduct && printUrl && (
                <Modal
                    isShown={isShown}
                    hide={toggle}
                    headerText="Print QR code for product"
                    modalContent={
                        <PrintModal
                            onConfirm={onConfirm}
                            onCancel={onCancel}
                            product={selectedProduct}
                            printUrl={printUrl}
                        />
                    }
                />
            )}
            <InventoryOverviewPageWrapper>
                {inventoryQuery.isLoading || inventoryQuery.data == undefined ? (
                    <Loader />
                ) : inventoryQuery.isError ? (
                    <p>Error</p>
                ) : (
                    <InventoryHeader
                        heading={inventoryQuery.data.name}
                        subheading={inventoryQuery.data.estimatedDeliveryDate?.slice(0, 10)}
                    />
                )}

                {productQuery.isLoading || productQuery.data == undefined ? (
                    <Loader />
                ) : productQuery.isError ? (
                    <>
                        <p>Error</p>
                    </>
                ) : (
                    <>
                        <InventoryTable
                            products={productQuery.data}
                            companyId={companyId ?? ""}
                            inventoryId={inventoryId ?? ""}
                            handleRowClick={handleRowClick}
                        />
                        <InventoryOptions
                            companyId={companyId ?? ""}
                            inventoryId={inventoryId ?? ""}
                            handlePrint={toggle}
                        />
                    </>
                )}
            </InventoryOverviewPageWrapper>
        </>
    );
};

const InventoryOverviewPageWrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: calc(65vh + 15vw);
    max-height: 85vh;
    gap: 10px;
    
`;
