import { DataGrid, GridColDef, GridRowId, GridRowsProp, GridToolbar } from "@mui/x-data-grid";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { SoecoButton } from "../../components/SoecoButton";
import { Inventory } from "../../types";
import EditIcon from "@mui/icons-material/Edit";

type Props = {
    inventories: Inventory[];
    companyId: string;
};

export const InventoryList = (props: Props) => {
    const { inventories, companyId } = props;
    const navigate = useNavigate();

    const handleOnRowClick = (id: GridRowId) => {
        const inventoryId = id as string;
        navigate(`/companies/${companyId ?? ""}/inventories/${inventoryId}`);
    };

    const handleInventoryEdit = (id: GridRowId) => {
        navigate(`/companies/${companyId}/editInventories/${id}`);
    };
    const columns: GridColDef[] = [
        {
            field: "name",
            headerName: "Name",
            flex: 0.5,
        },
        {
            field: "project",
            headerName: "Project",
            flex: 0.2,
        },
        {
            field: "date",
            headerName: "Date",
            flex: 0.3,
            type: "date",
        },
        {
            field: "actions",
            headerName: "Actions",
            align: "left",
            flex: 0.4,
            type: "actions",
            renderCell: params => {
                return (
                    <TableActions>
                        <SoecoButton
                            icon={true}
                            size="small"
                            onClick={() => handleInventoryEdit(params.id)}
                        >
                            <EditIcon fontSize="inherit" />
                        </SoecoButton>
                    </TableActions>
                );
            },
        },
    ];

    const rows: GridRowsProp = [
        ...inventories.map(inventory => ({
            id: inventory.id,
            name: inventory.name,
            project: inventory.project,
            date: inventory.estimatedDeliveryDate?.slice(0, 10),
        })),
    ];

    return (
        <InventoryListWrapper>
            <DataGrid
                disableColumnFilter
                disableColumnSelector
                disableDensitySelector
                components={{ Toolbar: GridToolbar }}
                componentsProps={{
                    toolbar: {
                        showQuickFilter: true,
                        quickFilterProps: { debounceMs: 500 },
                    },
                }}
                onRowClick={params => handleOnRowClick(params.id)}
                rows={rows}
                columns={columns}
                pageSize={100}
                rowsPerPageOptions={[100]}
            />
        </InventoryListWrapper>
    );
};

const InventoryListWrapper = styled.div`
    width: 100%;
`;

const TableActions = styled.div`
    display: flex;
    font-size: 6px;
`;
