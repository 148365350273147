import ImageList from "@mui/material/ImageList";
import ImageListItem from "@mui/material/ImageListItem";
import ImageListItemBar from "@mui/material/ImageListItemBar";
import styled from "styled-components";
import { SoecoButton } from "../../components/SoecoButton";
import DeleteIcon from "@mui/icons-material/Delete";
import { ErrorRecord, Product } from "../../types";

type Props = {
    images: File[];
    setImages: (images: File[]) => void;
    imageUrls: string[];
    setImageUrls: (imageUrls: string[]) => void;
    error: boolean;
    errors: ErrorRecord<Product>;
};

export const SoecoImageList = (props: Props) => {
    const handleImageDelete = (index: number) => {
        const revokeUrl = props.imageUrls[index];
        URL.revokeObjectURL(revokeUrl);

        const newImages = Array.from(props.images);
        newImages.splice(index, 1);
        props.setImages(newImages);
    };

    return (
        <ImageListWrapper>
            <ImageList cols={2}>
                {props.imageUrls.map((imageUrl, index) => {
                    return (
                        <ImageListItem key={imageUrl}>
                            <img src={imageUrl} alt="image" />
                            <ImageListItemBarWrapper>
                                <ImageListItemBar
                                    sx={{
                                        background:
                                            "linear-gradient(to bottom, rgba(0,0,0,0.7) 0%, " +
                                            "rgba(0,0,0,0.3) 0%, rgba(0,0,0,0) 0%)",
                                    }}
                                    position="top"
                                    actionIcon={
                                        <SoecoButton
                                            onClick={() => handleImageDelete(index)}
                                            icon={true}
                                        >
                                            {/* Prompt are you sure you want to delete product? */}
                                            <DeleteIcon color="secondary" />
                                        </SoecoButton>
                                    }
                                />
                            </ImageListItemBarWrapper>
                        </ImageListItem>
                    );
                })}
            </ImageList>
        </ImageListWrapper>
    );
};

const ImageListWrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-left: 15px;
    margin-right: 15px;
    margin-top: 15px;
`;

const ImageListItemBarWrapper = styled.div`
    background: "linear-gradient(to bottom, rgba(0,0,0,0.7) 0%, " +
        "rgba(0,0,0,0.3) 0%, rgba(0,0,0,0) 0%)";
`;
