import Chip from "@mui/material/Chip";
import Paper from "@mui/material/Paper";
import { Product, ProductCategory } from "../../types";
import styled from "styled-components";
import { useGetProductCategories } from "../../hooks/productCategoryHooks";

type Props = {
    product: Product;
    setProduct: (product: Product) => void;
    productCategories: ProductCategory[]
    handleProductCategoryChange: (data: ProductCategory) => void;
};

export const ProductCategoryForm = (props: Props) => {

    return (
        <Paper
            sx={{
                display: "flex",
                justifyContent: "center",
                flexWrap: "wrap",
                listStyle: "none",
                p: 0.5,
                m: 0,
            }}
            component="ul"
        >
            {props.productCategories &&
                props.productCategories.map(data => {
                    return (
                        <ListItem key={data.id}>
                            <Chip
                                clickable
                                onClick={() => {
                                    props.handleProductCategoryChange(data);
                                }}
                                color={
                                    props.product.categories?.includes(data.id)
                                        ? "primary"
                                        : "default"
                                }
                                label={data.name}
                            />
                        </ListItem>
                    );
                })}
        </Paper>
    );
};

const ListItem = styled.li`
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    justify-content: space-between;
    margin: 0.5rem;
`;
