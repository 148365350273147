import {
    DataGrid,
    GridColDef,
    GridRenderCellParams,
    GridRowId,
    GridRowsProp,
    GridToolbar,
} from "@mui/x-data-grid";
import styled from "styled-components";
import { Employee } from "../../types";
import { SoecoButton } from "../../components/SoecoButton";
import EditIcon from "@mui/icons-material/Edit";
import { useNavigate, useParams } from "react-router-dom";
import { getRolePriority } from "../../utils/util";

type Props = {
    employee: Employee;
    employees: Employee[];
};

export const ManageUsersList = (props: Props) => {
    const { companyId } = useParams();
    const navigate = useNavigate();

    const handleEmployeeEdit = (id: GridRowId) => {
        navigate(`/companies/${companyId ?? ""}/manageUsers/${id}`);
    };

    const columns: GridColDef[] = [
        {
            field: "email",
            headerName: "Email",
            align: "left",
            flex: 0.9,
            editable: false,
        },
        // {
        //     field: "name",
        //     headerName: "Name",
        //     align: "left",
        //     flex: 0.9,
        //     editable: false,
        // },
        {
            field: "role",
            headerName: "Role",
            align: "left",
            flex: 0.7,
            editable: false,
        },

        {
            field: "edit",
            headerName: "Edit",
            align: "left",
            flex: 0.4,
            editable: false,
            renderCell: (params: GridRenderCellParams<unknown, RowModel, RowModel>) => {
                return (
                    <>
                        {getRolePriority(props.employee.role) > getRolePriority(params.row.role) ? (
                            <TableActions>
                                <SoecoButton
                                    icon={true}
                                    size="small"
                                    onClick={() => handleEmployeeEdit(params.id)}
                                    disabled={true}
                                >
                                    <EditIcon fontSize="inherit" />
                                </SoecoButton>
                            </TableActions>
                        ) : (
                            <TableActions>
                                <SoecoButton
                                    icon={true}
                                    size="small"
                                    onClick={() => handleEmployeeEdit(params.id)}
                                >
                                    <EditIcon fontSize="inherit" />
                                </SoecoButton>
                            </TableActions>
                        )}
                    </>
                );
            },
        },
    ];

    type RowModel = {
        id: string;
        email: string | undefined;
        role: string;
        name: string | undefined;
    };
    const rows: GridRowsProp<RowModel> = [
        ...props.employees.map(employee => ({
            id: employee.id,
            email: employee.email,
            role: employee.role,
            name: employee.name,
        })),
    ];

    return (
        <EmployeeListWrapper>
            <DataGrid
                disableColumnFilter
                disableColumnSelector
                disableDensitySelector
                components={{ Toolbar: GridToolbar }}
                componentsProps={{
                    toolbar: {
                        showQuickFilter: true,
                        quickFilterProps: { debounceMs: 500 },
                    },
                }}
                rows={rows}
                columns={columns}
                pageSize={100}
                rowsPerPageOptions={[100]}
            />
        </EmployeeListWrapper>
    );
};

const EmployeeListWrapper = styled.div`
    width: 100%;
    height: 100%;
    max-height: 100%;
`;

const TableActions = styled.div`
    display: flex;
    font-size: 6px;
`;
