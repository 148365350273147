import React, { FunctionComponent, useEffect } from "react";
import FocusLock from "react-focus-lock";
import ReactDOM from "react-dom";
import styled from "styled-components";
import { DialogTitle } from "@mui/material";

export interface ModalProps {
    isShown: boolean;
    hide: () => void;
    modalContent: JSX.Element;
    headerText: string;
}

export const Modal: FunctionComponent<ModalProps> = ({
    isShown,
    hide,
    modalContent,
    headerText,
}) => {
    const onKeyDown = (event: KeyboardEvent) => {
        if (event.key === "Escape" && isShown) {
            hide();
        }
    };

    useEffect(() => {
        isShown
            ? (document.body.style.overflow = "hidden")
            : (document.body.style.overflow = "unset");
        document.addEventListener("keydown", onKeyDown, false);
        return () => {
            document.removeEventListener("keydown", onKeyDown, false);
        };
    }, [isShown]);

    const modal = (
        <React.Fragment>
            <Backdrop onClick={hide} />
            <FocusLock>
                <Wrapper aria-modal aria-labelledby={headerText} tabIndex={-1} role="dialog">
                    <StyledModal>
                        <StyledDialogTitle>{headerText}</StyledDialogTitle>
                        <Content>{modalContent}</Content>
                    </StyledModal>
                </Wrapper>
            </FocusLock>
        </React.Fragment>
    );

    return isShown ? ReactDOM.createPortal(modal, document.body) : null;
};

const StyledDialogTitle = styled(DialogTitle)`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    font-size: 2.5rem;
`;

export const Wrapper = styled.div`
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 700;
    width: 80%;
    outline: 0;
`;

export const Backdrop = styled.div`
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.3);
    z-index: 500;
`;

export const StyledModal = styled.div`
    z-index: 100;
    background: white;
    position: relative;
    margin: auto;
    border-radius: 8px;
    width: 100%;
`;

export const Header = styled.div`
    border-radius: 8px 8px 0 0;
    display: flex;
    justify-content: space-between;
    padding: 0.3rem;
`;

export const HeaderText = styled.div`
    color: #fff;
    align-self: center;
    color: lightgray;
`;

export const CloseButton = styled.button`
    font-size: 0.8rem;
    border: none;
    border-radius: 3px;
    margin-left: 0.5rem;
    background: none;
    :hover {
        cursor: pointer;
    }
`;

export const Content = styled.div`
    padding: 10px;
    max-height: 30rem;
    overflow-x: hidden;
    overflow-y: auto;
`;
