import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { SoecoButton } from "../../components/SoecoButton";
import PrintIcon from "@mui/icons-material/Print";
import { Product } from "../../types";

type Props = {
    companyId: string;
    inventoryId: string;
    handlePrint: () => void;
};

export const InventoryOptions = (props: Props) => {
    const navigate = useNavigate();
    return (
        <InventoryOptionsWrapper>
            <ActionButtons>
                <PrintButtonWrapper>
                    <SoecoButton
                        fullWidth
                        size="large"
                        color="primary"
                        onClick={() => {
                            props.handlePrint();
                        }}
                        startIcon={<PrintIcon />}
                    >
                        Print
                    </SoecoButton>
                </PrintButtonWrapper>
                <SoecoButton
                    fullWidth
                    size="large"
                    color="secondary"
                    onClick={() => navigate(`/companies/${props.companyId}/inventories`)}
                >
                    Back
                </SoecoButton>
                <SoecoButton
                    fullWidth
                    size="large"
                    onClick={() =>
                        navigate(
                            `/companies/${props.companyId}/inventories/${props.inventoryId}/products/new`,
                        )
                    }
                    color="primary"
                >
                    New product
                </SoecoButton>
            </ActionButtons>
        </InventoryOptionsWrapper>
    );
};

const InventoryOptionsWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
`;

export const ActionButtons = styled.div`
    display: grid;
    height: 10vh;
    width: 95%;
    gap: 1rem;
    position: fixed;
    bottom: 2rem;
    grid-template-columns: 1fr 1fr;
`;

export const PrintButtonWrapper = styled.div`
    grid-column: span 2;
`;
