import { Typography } from "@mui/material";
import React, { FunctionComponent } from "react";
import styled from "styled-components";
import { Loader } from "../Loader";

type Props = {
    message: string;
};

export const LoaderModal: FunctionComponent<Props> = (props: Props) => {
    return (
        <React.Fragment>
            <Backdrop>
                <Wrapper>
                    <LoaderModalWrapper>
                        <Loader />
                        <Typography color={"white"}>{props.message}</Typography>
                    </LoaderModalWrapper>
                </Wrapper>
            </Backdrop>
        </React.Fragment>
    );
};

const LoaderModalWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    gap: 1rem;
`;

export const Wrapper = styled.div`
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 700;
    width: 80%;
    outline: 0;
`;

export const Backdrop = styled.div`
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.3);
    z-index: 500;
`;
