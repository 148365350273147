import React, { useState, createContext } from "react";
import { Company } from "../types";

type Props = {
    children?: React.ReactNode;
};

export type CompanyContextState = {
    company: Company;
    setCompany: (company: Company) => void;
};

export const CompanyContext = createContext<CompanyContextState>({} as CompanyContextState);

export const CompanyProvider: React.FC<Props> = ({ children }): JSX.Element => {
    const [company, setCompany] = useState<Company>({} as Company);

    return (
        <CompanyContext.Provider value={{ company, setCompany }}>
            {children}
        </CompanyContext.Provider>
    );
};
