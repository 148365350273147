import { QueryClient } from "@tanstack/react-query";

export const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            refetchOnWindowFocus: false,
            retry: false,
            staleTime: 30000,
            refetchOnReconnect: true,
            refetchOnMount: true,
            networkMode: "online",
        },
        mutations: {
            networkMode: "offlineFirst",
            retry: 1,
        },
        
    },
});

