import { useQuery } from "@tanstack/react-query";
import { ProductCategory } from "../types";
import { get } from "../services/restClient";
import { Endpoint, setEndpoint } from "../services/endpoints";
import { queryClient } from "../hooks/queryClient";

const productCategoryKeys = {
    all: ["productCategories"] as const,
    lists: () => [...productCategoryKeys.all, "list"] as const,
    list: (filters: string) => [...productCategoryKeys.lists(), { filters }] as const,
    details: () => [...productCategoryKeys.all, "detail"] as const,
    detail: (id: string) => [...productCategoryKeys.details(), id] as const,
};

const getProductCategories = async () => {
    const data = await get<ProductCategory[]>(setEndpoint(Endpoint.categories), {
        requireAuth: true,
    });
    return data;
};

export const useGetProductCategories = () => {
    return useQuery({queryKey: ["productCategories"], queryFn: () => getProductCategories()});
};

export const prefetchProductCategories = () => {
    return queryClient.prefetchQuery({
        queryKey: ['productCategories'],
        queryFn: () => getProductCategories(),
    });
};
