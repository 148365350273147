import {
    DataGrid,
    GridColDef,
    GridRenderCellParams,
    GridRowId,
    GridRowsProp,
    GridToolbar,
} from "@mui/x-data-grid";
import styled from "styled-components";
import { Company } from "../../types";
import { EmployeeContext } from "../../context/EmployeeContext";
import { useContext } from "react";
import { SoecoButton } from "../../components/SoecoButton";
import EditIcon from "@mui/icons-material/Edit";
import { useNavigate } from "react-router-dom";
import { getRolePriority } from "../../utils/util";

type Props = {
    company: Company;
    companies: Company[];
};

export const ManageCompaniesList = (props: Props) => {
    const navigate = useNavigate();
    const { employee } = useContext(EmployeeContext);

    const handleCompanyEdit = (id: GridRowId) => {
        navigate(`/manageCompanies/${id}`);
    };

    const columns: GridColDef[] = [
        {
            field: "name",
            headerName: "Company name",
            align: "left",
            flex: 0.9,
            editable: false,
        },

        {
            field: "edit",
            headerName: "Edit",
            align: "left",
            flex: 0.4,
            editable: false,
            renderCell: (params: GridRenderCellParams<unknown, RowModel, RowModel>) => {
                return (
                    <>
                        {getRolePriority(employee.role) == 1 ? (
                            <TableActions>
                                <SoecoButton
                                    icon={true}
                                    size="small"
                                    onClick={() => handleCompanyEdit(params.id)}
                                    disabled={true}
                                >
                                    <EditIcon fontSize="inherit" />
                                </SoecoButton>
                            </TableActions>
                        ) : (
                            <TableActions>
                                <SoecoButton
                                    icon={true}
                                    size="small"
                                    onClick={() => handleCompanyEdit(params.id)}
                                >
                                    <EditIcon fontSize="inherit" />
                                </SoecoButton>
                            </TableActions>
                        )}
                    </>
                );
            },
        },
    ];

    type RowModel = {
        id: string;
        name: string | undefined;
    };
    const rows: GridRowsProp<RowModel> = [
        ...props.companies.map(company => ({
            id: company.id,
            name: company.name,
        })),
    ];

    return (
        <CompanyListWrapper>
            <DataGrid
                disableColumnFilter
                disableColumnSelector
                disableDensitySelector
                components={{ Toolbar: GridToolbar }}
                componentsProps={{
                    toolbar: {
                        showQuickFilter: true,
                        quickFilterProps: { debounceMs: 500 },
                    },
                }}
                rows={rows}
                columns={columns}
                pageSize={100}
                rowsPerPageOptions={[100]}
            />
        </CompanyListWrapper>
    );
};

const CompanyListWrapper = styled.div`
    width: 100%;
    height: 100%;
    max-height: 100%;
`;

const TableActions = styled.div`
    display: flex;
    font-size: 6px;
`;
