import { TextField } from "@mui/material";
import styled from "styled-components";
import { ErrorRecord, Product } from "../../types";

export type Props = {
    changedProduct: Product;
    handleChange: (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
    error: boolean;
    errors: ErrorRecord<Product>;
};

export const EditFactory = (props: Props) => {
    return (
        <Form autoComplete="off">
            <TextField
                label="Building"
                name="warehousePlacement"
                variant="filled"
                value={props.changedProduct.warehousePlacement}
                onChange={e => props.handleChange(e)}
            />
            <TextField
                label="Room"
                name="roomPlacement"
                variant="filled"
                value={props.changedProduct.roomPlacement}
                onChange={e => props.handleChange(e)}
            />
            <TextField
                label="Quantity"
                name="quantity"
                variant="filled"
                type="number"
                required
                inputProps={{ inputMode: "numeric", pattern: "[0-9]*" }}
                value={props.changedProduct.quantity}
                onChange={e => props.handleChange(e)}
                error={props.errors.quantity ? true : false}
                helperText={props.errors.quantity}
            />
        </Form>
    );
};

const Form = styled.form`
    display: flex;
    flex-direction: column;
    align-content: center;
    width: 95%;
    margin: 0 auto;
    gap: 20px;
`;
