import styled from "styled-components";

type Props = {
    heading?: string;
    subheading?: string;
};

export const InventoryHeader = (props: Props) => {
    return (
        <>
            <InventoryHeading>{props.heading}</InventoryHeading>
            <InventorySubHeading>{props.subheading}</InventorySubHeading>
        </>
    );
};

const InventoryHeading = styled.h3`
    display: flex;
    flex-direction: column;
    margin-bottom: 0rem;
`;

const InventorySubHeading = styled.h4`
    margin: 0.5rem;
`;
