import TextField from "@mui/material/TextField";
import React, { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { post } from "../../services/restClient";
import { SoecoButton } from "../../components/SoecoButton";
import styled from "styled-components";
import { Endpoint, setEndpoint } from "../../services/endpoints";
import { ErrorRecord, ResetPasswordForm } from "../../types";
import { useSnackBar } from "../../context/SnackbarContext";
import { isValidPassword } from "../../utils/util";
import { IconButton, InputAdornment } from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material";

export const ResetPassword = () => {
    const [form, setForm] = useState<ResetPasswordForm>({
        newPassword: "",
        confirmPassword: "",
    });
    const [error, setError] = useState<boolean>(false);
    const [errors, setErrors] = useState<ErrorRecord<ResetPasswordForm>>({});
    const [showPassword, setShowPassword] = useState<boolean>(false);

    const token = useParams<{ token: string }>().token;
    const navigate = useNavigate();

    const snackBar = useSnackBar();

    const handleResetPassword = (event: React.FormEvent) => {
        event.preventDefault();
        if (!token) {
            return;
        }
        if (!isValidForm()) {
            return;
        }

        type RequestBody = {
            token: string;
            newPassword: string;
        };

        type ResponseBody = RequestBody & {
            message: string;
        };
        post<RequestBody, ResponseBody>(setEndpoint(Endpoint.resetPasswordToken), {
            token,
            newPassword: form.newPassword,
        })
            .then(() => {
                snackBar.showSnackBar("Password reset successfully", "success");
                navigate("/login");
            })
            .catch(error => {
                if (error instanceof Error) {
                    snackBar.showSnackBar(error.message, "error");
                }
            });
    };

    const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        setForm({ ...form, [e.target.name]: e.target.value });
        setError(false);
    };

    const isValidForm = () => {
        let valid = true;
        const newErrors: ErrorRecord<ResetPasswordForm> = {};
        if (!isValidPassword(form.newPassword)) {
            valid = false;
            newErrors.newPassword =
                "Password must be at least 8 characters long, contain at least one uppercase letter, one lowercase letter, one number and one special character";
        }
        if (form.confirmPassword !== form.newPassword) {
            valid = false;
            newErrors.confirmPassword = "Passwords don't match";
        }
        if (!valid) {
            setErrors(newErrors);
            setError(true);
            return false;
        } else {
            setErrors({});
            setError(false);
            return true;
        }
    };

    const handleClickShowPassword = () => {
        setShowPassword(!showPassword);
    };

    const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
        event.preventDefault();
    };

    return (
        <ResetPasswordPageWrapper>
            <Form noValidate autoComplete="off">
                <TextField
                    label="New password"
                    name="newPassword"
                    variant="outlined"
                    type={showPassword ? "text" : "password"}
                    onChange={handleChange}
                    error={errors.newPassword !== undefined}
                    helperText={errors.newPassword}
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="end">
                                <IconButton
                                    aria-label="toggle password visibility"
                                    onClick={handleClickShowPassword}
                                    onMouseDown={handleMouseDownPassword}
                                    edge="end"
                                >
                                    {showPassword ? <VisibilityOff /> : <Visibility />}
                                </IconButton>
                            </InputAdornment>
                        ),
                    }}
                />
                <TextField
                    label="Confirm password"
                    name="confirmPassword"
                    variant="outlined"
                    type={showPassword ? "text" : "password"}
                    onChange={handleChange}
                    error={errors.confirmPassword !== undefined}
                    helperText={errors.confirmPassword}
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="end">
                                <IconButton
                                    aria-label="toggle password visibility"
                                    onClick={handleClickShowPassword}
                                    onMouseDown={handleMouseDownPassword}
                                    edge="end"
                                >
                                    {showPassword ? <VisibilityOff /> : <Visibility />}
                                </IconButton>
                            </InputAdornment>
                        ),
                    }}
                />
                <SoecoButton
                    fullWidth
                    color="primary"
                    type="submit"
                    onClick={handleResetPassword}
                    disabled={error}
                >
                    Reset password
                </SoecoButton>
            </Form>
        </ResetPasswordPageWrapper>
    );
};

const ResetPasswordPageWrapper = styled.div`
    display: flex;
    flex-direction: column;
    height: 80vh;
    justify-content: center;
    align-items: center;
    gap: 20px;
`;

const Form = styled.form`
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 20px;
    width: 75%;
`;
