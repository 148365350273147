import {
    fetchAndSetToken,
    getAccessToken,
    hasAccessToken,
    isTokenExpired,
    logout,
} from "../services/authService";

type RequestOptions = {
    requireAuth?: boolean;
    formData?: boolean;
    excelData?: boolean;
    headers?: HeadersInit;
};

async function http<T>(path: string, config: RequestInit): Promise<T> {
    const request = new Request(path, config);
    const response = await fetch(request);
    if (response.status === 401) {
        logout();
        const error = (await response.json()) as Error;
        throw new Error(error.message);
    }

    if (!response.ok) {
        const error = (await response.json()) as Error;
        throw new Error(error.message);
    }

    // Handle empty body response type
    if (response.status === 204) {
        return <T>{};
    }

    if (
        response.headers
            .get("Content-Type")
            ?.includes("application/vnd.openxmlformats-officedocument.spreadsheetml.sheet")
    ) {
        return response.arrayBuffer().catch(/*Could not parse blob*/) as Promise<T>;
    }

    return response.json().catch(/*Could not parse json*/) as Promise<T>;
}

export async function get<T>(path: string, options?: RequestOptions): Promise<T> {
    const defaultConfig = await setConfig(options);
    const init = {
        method: "GET",
        ...defaultConfig,
    };
    return await http<T>(path, init);
}

export async function post<T, U>(path: string, body: T, options?: RequestOptions): Promise<U> {
    const defaultConfig = await setConfig(options);
    const init = {
        method: "POST",
        ...defaultConfig,
        body: body instanceof FormData ? body : JSON.stringify(body),
    };
    return await http<U>(path, init);
}

export async function deleteRequest(path: string, options?: RequestOptions): Promise<void> {
    const defaultConfig = await setConfig(options);
    const init = {
        method: "DELETE",
        ...defaultConfig,
    };
    return await http<void>(path, init);
}

export async function put<T, U>(path: string, body: T, options?: RequestOptions): Promise<U> {
    const defaultConfig = await setConfig(options);
    const init = {
        method: "PUT",
        ...defaultConfig,
        body: JSON.stringify(body),
    };

    return await http<U>(path, init);
}

async function setConfig(options?: RequestOptions) {
    let authConfig: RequestInit = {};

    const requestHeaders: HeadersInit = new Headers();

    if (options?.formData) {
        requestHeaders.delete("Content-Type");
        requestHeaders.append("Accept", "multipart/form-data");
    } else if (options?.excelData) {
        requestHeaders.append("No-cache", "no-cache");
    } else {
        requestHeaders.append("Content-Type", "application/json");
        requestHeaders.append("Accept", "application/json");
    }

    if (options?.requireAuth) {

        if (!hasAccessToken() || isTokenExpired()) {
            try {
                await fetchAndSetToken();
            } catch (e) {
                logout();
                throw new Error("Could not fetch refresh-token, Please login again");
            }
        }
        const token = getAccessToken();
        if (token === undefined) {
            logout();
            throw new Error("Access token is undefined, Please login again");
        }
        requestHeaders.set("Authorization", "Bearer " + token.accessToken);
    }

    authConfig = {
        headers: requestHeaders,
        credentials: "include",
    };

    return authConfig;
}
