import {
    DataGrid,
    GridColDef,
    GridRenderCellParams,
    GridRowId,
    GridRowsProp,
    GridToolbar,
} from "@mui/x-data-grid";
import styled from "styled-components";
import { Employee, Company } from "../../types";
import { SoecoButton } from "../../components/SoecoButton";
import EditIcon from "@mui/icons-material/Edit";
import { useNavigate, useParams } from "react-router-dom";
import { getRolePriority } from "../../utils/util";

type Props = {
    companies: Company[];
};

export const ManageSubCompaniesList = (props: Props) => {
    const { companyId } = useParams();
    const navigate = useNavigate();

    const columns: GridColDef[] = [
        {
            field: "name",
            headerName: "Name",
            align: "left",
            flex: 0.9,
            editable: false,
        },
    ];

    type RowModel = {
        id: string;
        name: string;
    };
    const rows: GridRowsProp<RowModel> = [
        ...props.companies.map(company => ({
            id: company.id,
            name: company.name,
        })),
    ];

    return (
        <EmployeeListWrapper>
            <DataGrid
                disableColumnFilter
                disableColumnSelector
                disableDensitySelector
                components={{ Toolbar: GridToolbar }}
                componentsProps={{
                    toolbar: {
                        showQuickFilter: true,
                        quickFilterProps: { debounceMs: 500 },
                    },
                }}
                rows={rows}
                columns={columns}
                pageSize={100}
                rowsPerPageOptions={[100]}
            />
        </EmployeeListWrapper>
    );
};

const EmployeeListWrapper = styled.div`
    width: 100%;
    height: 100%;
    max-height: 100%;
`;

const TableActions = styled.div`
    display: flex;
    font-size: 6px;
`;
